import React, { useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ConfirmDltDialog from "./../ConfirmDltDialog";
import AmountItems from "../AmountItems/AmountItems";
import SearchInput from "../SearchInput";
import {
  getAllUsersList,
  getCurrUserDetail,
  deleteSubdivision,
  getConnectedSubdivisionList,
  setSecSubdivisionId
} from "redux/actions/SubDivision";
import { GET_TRAVEL_LIST, SEC_SUBDIV } from '../../@jumbo/constant/ActionTypes';
import AddConfirmDialog from "../AddConfirmDialog";
import ReactTable from "components/common/table/ReactTable2";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getAllTenantsListForDashboard } from '../../redux/actions/tenant';
import tenant from "redux/reducers/tenant";
import ReportIcon from '@mui/icons-material/Report';
import { getTravelList } from "redux/actions/travel";
import { TRAVEL_STATUS } from "modules/Travel/data";
import { getAlertList } from "redux/actions/alert";
import { ALERT_STATUS, ALERT_TYPE } from "utils/alertHelper";

export default function SubDivisions() {
  const { allUsers, secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
  const { users } = useSelector(({ TENANT }) => TENANT);

  const [page, setPage] = React.useState(0);
  const [selectedUser, setSelectedUser] = useState({ first_name: "" });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDltDialog, setopenConfirmDltDialog] = useState(false)
  const [searchValue, setSearchValue] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [activePerPage, setActivePerPage] = useState(10);
  const [tenantList, setTenantList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("");

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const columns = [
    {
      id: "id",
      accessor: "id",
      title: "Id",
      width: "8%",
      tag: true,
      sortable: true,
      Component: ({ row }) => {
        return (
          <>
            {row.has_alerts && <div className="default-number-sec" >
              <ReportIcon fontSize="small" />
            </div>}
            <div>{row.id}</div>
          </>
        );
      }
    },
    {
      id: "contract_id",
      accessor: "contract_id",
      title: "Contract ID",
      minWidth: 100,
      sortable: true,
      Component: ({ row }) => row?.contract_id || "-"
    },

    {
      id: "first_name",
      accessor: "first_name",
      title: "Full Name",
      Component: ({ row }) => { return `${row?.first_name || ''}   ${row?.last_name || ''}` }
    },
    {
      id: "mobile",
      accessor: "mobile",
      title: "Phone",
      Component: ({ row }) => row?.mobile || "-"


    },
    {
      id: "email",
      accessor: "email",
      title: "Email",
      minWidth: 170,
      Component: ({ row }) => row?.email || "-"
    },
    {
      id: "firm_name",
      accessor: "firm_name",
      title: "Subdivision Name",
      sortable: true,
      Component: ({ row }) => row?.firm_name || "-"
    },
    {
      id: "numberOfLicenses",
      accessor: "numberOfLicenses",
      title: `No. of licenses`,
      sortable: false,
      Component: ({ row }) => {
        return (
          !row.number_of_licenses ? "-" : `${!!row ? row.number_of_licenses ? row.number_of_licenses.used : null : null} / ${!!row ? row.number_of_licenses ? row.number_of_licenses.assigned : null : null}`
        );
      }
    },
    {
      id: "status",
      accessor: "status",
      title: "Status",
      sortable: true,
      Component: ({ row }) => {
        return (
          <>
            <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: row.status === 1 ? 'rgb(95, 170, 176)' : 'rgb(225, 108, 108)', marginRight: '5px' }} />
            {row.status === 1 ? `Active` : "Disabled"}
          </>
        )
      }
    },
    {
      id: 'id',
      accessor: 'id',
      title: 'Actions',
      Component: ({ row }) => {
        return (
          <>
            {row.status === 1 ?
              <span className="cursor-pointer" onClick={() => onHandleClickEye(row._id)} >
                <i className="icon-eye" aria-label="toggle password visibility" />
              </span> : "-"}
          </>
        );
      }
    }
  ];

  const onHandleClickEye = async (id) => {
    localStorage.setItem("sec_subdiv_id", id);
    dispatch(setSecSubdivisionId(id));
    setTimeout(() => navigate(`/app/CrisisLog`), 300);
    dispatch(getAlertList({ status: ALERT_STATUS.RAISED, alert_type: `${ALERT_TYPE.TRIGGERED_ALERT}, ${ALERT_TYPE.DELAYED_ALERT}`, page: 1, limit: 1, subdivisions: id }))
    dispatch(getAlertList({ status: ALERT_STATUS.RAISED, alert_type: ALERT_TYPE.IN_HOUSE_ALERT, page: 1, limit: 1, subdivisions: id }))
    dispatch(getTravelList({ page: 1, limit: 10, search: '', status: TRAVEL_STATUS.IN_PROGRESS, subdivisions: id }));
  }

  const [sortingOption, setSortingOption] = useState({ columnName: '', direction: '' });
  useEffect(() => {
    dispatch(
      getConnectedSubdivisionList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage, selectedTenant)
    );
  }, [dispatch, activePage, activePerPage, sortingOption, searchValue]);

  useEffect(() => {
    dispatch(getAllTenantsListForDashboard());
    localStorage.removeItem("sec_subdiv_id");
    dispatch({ type: SEC_SUBDIV, payload: "" })
  }, []);




  useEffect(() => {
    if (users && users?.userData?.length > 0) {
      const tenant = users?.userData.map((ten) => {
        return { label: `${ten?.first_name}${" "}${ten?.last_name}`, value: ten?._id }
      })
      setTenantList(tenant);
    }
  }, [users])

  const handleCancelDelete = () => {
    setopenConfirmDltDialog(false);
  };
  const handleConfirmDelete = () => {
    setopenConfirmDltDialog(false);
    dispatch(deleteSubdivision(selectedUser._id, { setAddDialog: () => setOpenConfirmDialog(true) },
      // () => dispatch(getConnectedSubdivisionList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage))
    ))
  };

  const changePerPage = (activePerPage) => {
    setActivePage(1);
    setActivePerPage(activePerPage);
    dispatch(
      getConnectedSubdivisionList(sortingOption?.columnName, sortingOption?.direction, searchValue, 1, activePerPage)
    );
  };

  const onSearch = (searchValue) => {
    setSearchValue(searchValue);
  };
  const submitSearch = (e) => {
    e.preventDefault();
    setSearchValue(searchValue);
    dispatch(
      getConnectedSubdivisionList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage, selectedTenant)
    );
  };
  const clearSearch = (e) => {
    e.preventDefault();
    setSearchValue("");
    dispatch(getConnectedSubdivisionList(sortingOption?.columnName, sortingOption?.direction, "", activePage, activePerPage, selectedTenant));
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setActivePerPage(activePerPage);
    dispatch(
      getConnectedSubdivisionList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage, selectedTenant)
    );
  };
  const handleCancelConfirm = () => {
    setOpenConfirmDialog(false)
    dispatch(getConnectedSubdivisionList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage, selectedTenant))
  }
  const handleConfirm = () => {
    setOpenConfirmDialog(false)
  }

  const onHandleChange = (e, newValue) => {
    let selectedSub = newValue.map((t) => {
      return t.value
    })
    let tenants = selectedSub.toString();
    setSelectedTenant(tenants);
    dispatch(getConnectedSubdivisionList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage, tenants))
    // setSelectedSubDiv(comaStringSub);
  }

  return (
    <>
      <div className="mainDiv1">
        <div className="heading-section">
          <div className="pageTitle">Subdivisions table <span className="totalCount">({allUsers && allUsers.total ? allUsers.total : '0'})</span></div>
          <div className="grid gap-4">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={tenantList}
              multiple={true}
              onChange={onHandleChange}
              className=""
              sx={{ width: 400, }}
              renderInput={(params) => <TextField {...params} label="Tenant" className="multiselect-tenate" />}
            />
          </div>
        </div>

        <div className='serch-section'>
          <SearchInput
            onChange={onSearch}
            searchValue={searchValue}
            onSubmit={submitSearch}
            clearSearch={clearSearch}
          />

          <AmountItems
            changePerPage={changePerPage}
            activePerPage={activePerPage}
          />
        </div>
        <ReactTable
          COLUMNS={columns}
          DATA={allUsers?.data}
          fetchData={getConnectedSubdivisionList}
          totalCount={allUsers?.total}
          pageSize={activePerPage}
          pageIndex={activePage}
          sortingOption={sortingOption}
          setSortingOption={setSortingOption}
          setSearchValue={setSearchValue}
          customText={"No Subdivisions Connected"}
        />
        <ConfirmDltDialog
          open={openConfirmDltDialog}
          content={`Are you sure you want to delete "${selectedUser.first_name} ${selectedUser.last_name}"?`}
          content1={`All information about this user will be permanently deleted`}
          btnLabels={{
            no: "No",
            Yes: "Yes",
          }}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
        <AddConfirmDialog
          open={openConfirmDialog}
          content={`Sub division was deleted successfully! `}
          btnLabels={{
            ok: "Ok",
          }}
          onClose={handleCancelConfirm}
          onConfirm={handleConfirm}
        />
      </div>
    </>
  );
}
