import styled from 'styled-components';
import COLORS from 'utils/colors'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width || '100%'};
  i {
    font-size: 20px;
  }
`;

export const WrapperInput = styled.div`
  width: 100%;
`;

export const SearchButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: 0;
  right: -40px;
  cursor: pointer;
  outline: none;
  i {
    color: ${COLORS.greytext};
  }
`;

export const Delete = styled.span`
  width: 16px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  i {
    font-size: 30px;
    color: ${COLORS.greylighttext};
  }
`;

export const InputField = styled.input`
  font-family: SFTextLight;
  font-size: 15px;
  width: 100%;
  height: 36px;
  border: 1px solid ${props => (props.error ? COLORS.red : COLORS.greyborder)};
  &::placeholder {
    color: ${COLORS.lightgrey};
  }
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  padding: 0 10px;
  &:focus {
    border: 1px solid ${COLORS.black};
  }
`;

export const Form = styled.form`
  width: 50%;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  right: -23px;
  bottom: -15px;
  font-size: 14px;
  color: ${COLORS.red};
`;
