import React from "react"
import { CheckedIconContainer, CheckedIconSelected } from './style'
import { CheckOutlined, Done } from '@mui/icons-material'

export const CustomCheckedIcon = ({ checked }) => {

    return (
        <CheckedIconContainer>
            {checked == 1 && <Done style={CheckedIconSelected} />}
        </CheckedIconContainer>
    )
}