import React, { useState, useEffect } from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material'
import AmountItems from '../AmountItems/AmountItems'
import SearchInput from '../SearchInput'
import { tableColumns, TRAVEL_STATUS } from './data'
import { useDispatch, useSelector } from 'react-redux'
import { SearchContainer, NoData } from './style'
import COLORS from 'utils/colors'
import Pagination from "../Pagination/Pagintions";
import { getTravelList } from 'redux/actions/travel'
import Loader from '../Loader'
import { USER_TYPE } from 'utils/commonHelper'
import ReactTable from 'components/common/table/ReactTable2'
import { Link } from 'react-router-dom'
import { GET_TRAVEL_LOG_DETAIL } from '@jumbo/constant/ActionTypes'

export default function TravelUser({ navigate, loader, setLoader, activePage, setActivePage, activePerPage, setActivePerPage, tempSearchValue, setTempSearchValue, filterList }) {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState(tempSearchValue)
    const status = TRAVEL_STATUS.IN_PROGRESS
    const currentUser = JSON.parse(localStorage.getItem('profile'))
    const subdiv_user = currentUser.user_type === USER_TYPE.SUBDIVISION_USER
    const tenant_user = currentUser.user_type === USER_TYPE.TENANT_USER

    const { travelList: list } = useSelector(({ TRAVEL }) => TRAVEL)
    const { users } = useSelector(({ CRISISLOGALERT }) => CRISISLOGALERT); // get alert List

    useEffect(() => {
        //setTimeout(() => setLoader(false), 3000)
    }, [])

    const changePerPage = (activePerPage) => {
        setActivePerPage(activePerPage);
        setLoader(true)
        const subdivisions = filterList.length === 0 ? '' : filterList.length === 1 ? filterList[0] : filterList.join(',')
        const params = { page: 1, limit: activePerPage, search: searchValue, subdivisions, status }
        dispatch(getTravelList(params))
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoader(true)
        const subdivisions = filterList.length === 0 ? '' : filterList.length === 1 ? filterList[0] : filterList.join(',')
        const params = { page: pageNumber, limit: activePerPage, search: searchValue, subdivisions, status }
        dispatch(getTravelList(params))
    }

    const onSubmitSearch = (e) => {
        e.preventDefault();
        setLoader(true)
        setTempSearchValue(searchValue)
        const subdivisions = filterList.length === 0 ? '' : filterList.length === 1 ? filterList[0] : filterList.join(',')
        const params = { page: activePage, limit: activePerPage, search: searchValue, subdivisions, status }
        dispatch(getTravelList(params))
    }

    const clearSearch = (e) => {
        e.preventDefault();
        setTempSearchValue("");
        setLoader(true)
        const subdivisions = filterList.length === 0 ? '' : filterList.length === 1 ? filterList[0] : filterList.join(',')
        let params = { page: activePage, limit: activePerPage, search: '', status }
        if (currentUser?.user_type == 2) params = { page: activePage, limit: activePerPage, search: '', status, subdivisions }
        dispatch(getTravelList(params));
    }

    const renderLoader = () => {
        return (
            <Loader />
        )
    }
    const testMode = () => {
        return (
            <div className="default-number" style={{ marginTop: "4px" }}>
                Test Mode
            </div>
        );
    };
    const columns = [
        {
            id: 'id',
            accessor: 'id',
            title: 'ID'
        },
        {
            id: 'userFirstname',
            accessor: 'userFirstname',
            title: 'Full Name',
            Component: ({ row }) => {
                return (
                    <>
                        {row?.test_mode && <div className="default-number" style={{ marginTop: "4px" }}>
                            Test Mode
                        </div>}
                        <div>{`${row?.userFirstname || ""} ${row?.userLastname || "-"} `}</div>
                    </>
                );
            }
        },
        {
            id: 'lastCheckinCountry',
            accessor: 'lastCheckinCountry',
            title: 'Last Country'
        },
        {
            id: 'lastCheckinCity',
            accessor: 'lastCheckinCity',
            title: 'Last City'
        },
        {
            id: 'country',
            accessor: 'country',
            title: 'End Country'
        },
        {
            id: 'city',
            accessor: 'city',
            title: 'End City'
        },
        {
            id: 'userMobile',
            accessor: 'userMobile',
            title: 'Phone number'
        },
        {
            id: 'userEmail',
            accessor: 'userEmail',
            title: 'Email'
        },
        {
            id: 'actions',
            accessor: 'actions',
            title: 'Actions',
            width: '6%',
            Component: ({ row }) => {
                return (
                    <Link to={`/app/Travel/travel-detail/${row.travelID}`}>
                        <i className="icon-eye actioneye" />
                    </Link>
                )
            }
        },
    ];

    useEffect(() => {
        dispatch({ type: GET_TRAVEL_LOG_DETAIL, payload: [] });
    }, [])

    return (
        <div>
            <SearchContainer>
                <SearchInput searchValue={searchValue} onChange={(searchValue) => setSearchValue(searchValue)} onSubmit={onSubmitSearch} clearSearch={clearSearch} />
                <AmountItems changePerPage={(item) => changePerPage(item)} activePerPage={activePerPage} />
            </SearchContainer>
            <ReactTable
                COLUMNS={columns}
                DATA={list?.UserTravelList || []}
                fetchData={handlePageChange}
                totalCount={list?.total_no_of_records}
                pageSize={activePerPage}
                pageIndex={activePage}
                hiddenColumns={![USER_TYPE.SUBDIVISION_USER, USER_TYPE.EXTERNAL_CONTROLLER, USER_TYPE.END_USER, USER_TYPE.SEC_CONTROLLER].includes(currentUser.user_type) ? ['actions'] : []}
            />
        </div>
    )
} 