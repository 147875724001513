import { Alert } from "@mui/material";
import { ADD_USER, GET_INHOUSELIST, GET_USER_DETAILS, ADD_TENANT, SET_USER, UPDATE_ALERT_LIST, UPDATE_TRAVEL_LIST } from "../../@jumbo/constant/ActionTypes";
import { Adduser, inHouseListCrisisLog, getuserDetails, editSubdivision, EditUser } from "../../@jumbo/constant/ApiConstant";
import axios from '../../helper/customAxios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS, } from '../../@jumbo/constant/ActionTypes';
import { refreshToken } from "./Auth";


export const getInHouseList = (alert_type, filterList, activePerPage, activePage) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios.get(inHouseListCrisisLog, {
      params: {
        alert_type: alert_type,
        subdivisions: filterList.toString(),
        limit: activePerPage,
        page: activePage,
      },
    })
      .then(data => {
        if (data.status === 200) {
          if (!data.data.data.alertLogs.length) {
            dispatch({ type: GET_INHOUSELIST, payload: [] });
          } else {
            dispatch({ type: GET_INHOUSELIST, payload: data.data.data });
          }
          if (data?.data?.data) {
            dispatch({ type: UPDATE_ALERT_LIST, payload: { total_alerts: data?.data?.data?.total_alerts } });
            dispatch({ type: UPDATE_TRAVEL_LIST, payload: { total_travel: data.data.data?.total_travel } });
          }
        } else {
          dispatch({ type: GET_INHOUSELIST, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });

        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getInHouseList(alert_type, filterList, activePerPage, activePage))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: GET_INHOUSELIST, payload: [] });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: GET_INHOUSELIST, payload: [] });
          // dispatch(fetchError(error.response.data.message));
        }
      });

  };
};


export const addNewUser = (user, callbackFun) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .post(Adduser, user)
      .then(data => {

        if (data.status == 200) {
          if (callbackFun) callbackFun.setAddDialog();
          dispatch({ type: FETCH_SUCCESS, loading: false });
          setTimeout(() => {
            dispatch(fetchSuccess('User added successfully.'));

          }, 4000);


          //   if (callbackFun) callbackFun(data.data);
          // } else {

          // dispatch({ type: ADD_TENANT, payload: data.data });
          // dispatch({ type: ADD_TENANT, payload:data.data});
        }
        else {
          dispatch({ type: ADD_TENANT, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));

        }
      })
      .catch(error => {
        // dispatch({ type: ADD_TENANT, payload:[]});
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addNewUser(user, callbackFun))
          }))
          return;
        }
        if (error.response.status === 401 || error.response.status === 400) {
          //   dispatch(setAuthUser(null));
          //   history.push('/signin');
          // } else {
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
      });
  };
};

// set current user for view tab
// export const setTenantList = user => {
//   return dispatch => {
//     dispatch({ type: SET_USER, payload: user });
//   };
// };

export const getCurrUserDetail = (id) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    axios.get(getuserDetails, {
      params: {
        user_id: id,

      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch({ type: SET_USER, payload: data.data.data });
        } else {
          dispatch({ type: SET_USER, payload: [] });
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getCurrUserDetail(id))
          }))
          return;
        }
        // if(error.response.status===401 ){
        // dispatch(setAuthUser(null));
        // history.push('/signin')
        // } 
        // else {
        //   // dispatch(fetchError(error?.response?.data?.message));
        // }
      });

  };
};
export const updateUser = (data, callbackFun, list) => {
  return dispatch => {

    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .put(EditUser, data)
      .then(response => {
        if (data) {
          // alert("update")
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // // dispatch({ type: EDIT_USER, payload: response.data });
          if (callbackFun) callbackFun.setAddDialog();

          setTimeout(() => {
            dispatch(fetchSuccess('User updated successfully.'));
          }, 4000);
          // if(list) list()
        }
        else {
          dispatch(fetchError('There was something issue in responding server.'));
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(updateUser(data, callbackFun, list))
          }))
          return;
        }
        if (error.response.status === 401 || error.response.status === 400) {
          //   dispatch(setAuthUser(null));
          //   history.push('/signin');
          // } else {
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};


export const updateSubdivision = (user) => {
  return dispatch => {

    // dispatch(fetchStart());
    axios
      .put(editSubdivision, user)
      .then(data => {
        // setTimeout(() => {
        //   dispatch(fetchSuccess('Service activity updated successfully .'));
        // }, 2000);
        //   if (callbackFun) callbackFun();
        // dispatch({ type: EDIT_SUBDIVISION_USER, payload: data.data.data });

      })
    // .catch(error => {

    //   if(error?.response?.status===401 ){
    //     dispatch(setAuthUser(null));
    //     history.push('/signin')
    //   } 
    //   else {
    //     dispatch(fetchError(error?.response?.data?.message));
    //   }
    // });
  };
};

// export const deleteSubdivision = (userId) => {
//   return dispatch => {
//     dispatch(fetchStart());
//     axios
//       .delete(deleteClassCategorie + userId)
//       .then(data => {
//         if (data.status === 200) {
//           setTimeout(() => {
//             dispatch(fetchSuccess('Subdivision deleted successfully.'));
//           }, 2000);
//           // if (callbackFun) callbackFun();
//         } else {
//           dispatch(fetchError());
//         }
//       })
//       .catch(error => {
//         dispatch(fetchError(error?.response?.data?.message));
//       });
//   };
// };