import React, { useEffect, useState } from "react";
import { gridColumns } from "./data";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import COLORS from "utils/colors";
import Pagination from "../Pagination/Pagintions";
import MapDialogBox from "./mapDialogBox";
import { useDispatch, useSelector } from "react-redux";
import { VisibilityOutlined as VisibilityOutlinedIcon } from "@mui/icons-material";
import { EmptyBox } from "components/EmptyBox";
import {
  ALERT_STATUS,
  ALERT_TYPE,
  COLOR_LIST,
  REVERSED_ALERT_STATUS,
  REVERSED_ALERT_TYPE,
  timeHelper,
} from "utils/alertHelper";
import { StatusContainer, Bullet } from "./style";
import { useNavigate } from "react-router";
import * as moment from "moment";
import { getAlertList } from "redux/actions/alert";
import { USER_TYPE } from "utils/commonHelper";
import { Link } from "react-router-dom";
import ReactTable from "components/common/table/ReactTable2";

export default function InHouseGrid({
  tabIndex,
  activePage,
  setActivePage,
  activePerPage,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const { inHouseList } = useSelector(({ ALERT }) => ALERT);
  const alert_type = `${ALERT_TYPE.IN_HOUSE_ALERT}`;
  const currentUser = JSON.parse(localStorage.getItem("profile"));
  const tanent_user = currentUser.user_type === USER_TYPE.TENANT_USER;
  const { policenumber } = useSelector(({ POLICENUMBER }) => POLICENUMBER)
  console.log("inHouseList", inHouseList?.userAlerts?.map((data) => data?.status === 1))
  const onClose = () => setOpenDialogBox(false);

  useEffect(() => {
    setCurrentData(() => {
      const currentTab = tabIndex === 0 ? 1 : 2
      return inHouseList?.userAlerts?.filter((data) => data?.status === currentTab)
    })

  }, [inHouseList?.userAlerts])

  useEffect(() => {
    setTimeout(() => localStorage.removeItem("alertTab"), 800);
  }, []);

  const onHandleOpen = () => setOpenDialogBox(true);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const params = { alert_type, page: pageNumber, limit: activePerPage };
    if (tabIndex === 0) {
      dispatch(getAlertList({ status: ALERT_STATUS.RAISED, ...params }));
    } else if (tabIndex === 1) {
      dispatch(getAlertList({ status: ALERT_STATUS.IN_PROGRESS, ...params }));
    }
  };

  const findUserCountry = (row) => {
    let userCountry = row?.current_location?.country;
    let userMatchPoliceNumber = row?.user_id?.local_police_number?.filter((data) => data?.country === userCountry)
    return userMatchPoliceNumber?.map((data) => data?.police_phone)
  }

  const renderStatus = (value) => {
    return (
      <StatusContainer>
        <Bullet style={{ backgroundColor: COLOR_LIST[value.toLowerCase()] }} />{" "}
        {value}
      </StatusContainer>
    );
  };

  const testMode = () => {
    return (
      <div className="default-number" style={{ marginTop: "4px" }}>
        Test Mode
      </div>
    );
  };
  const renderValue = (col, row) => {
    if (col.id === "response_time") {
      return timeHelper(row[col.id]) || "-";
    } else if (col.id === "local_police_number" && row.user_id) {
      // return policenumber && policenumber.local_police_number &&policenumber.local_police_number[0].police_phone ||'-'
      return findUserCountry(row)
      // policenumber && policenumber.police_phone || '-' //  as per shown in local police API data
    } else if (col.id === "status") {
      return renderStatus(REVERSED_ALERT_STATUS[row[col.id]]) || "-";
    } else if (col.id === "alert_type") {
      const val = REVERSED_ALERT_TYPE[row[col.id]];
      return (
        <span style={{ color: COLOR_LIST[val.toLowerCase()] }}>
          {val ? val : "-"}
        </span>
      );
    } else if (col.sub_id && row[col.id]) {
      return row[col.id][col.sub_id] || "-";
    } else if (col.id === "user_id") {
      // return `${row.user_id.first_name || ''} ${row.user_id.last_name || '-'}`
      return (
        <>
          {row.test_mode ? testMode() : null}
          <div>{`${row.user_id.first_name || ""} ${row.user_id.last_name ||
            ""} `}</div>
        </>
      );
    } else if (col.id === "created_at") {
      return moment(row[col.id]).format("DD-MM-YYYY HH:MM") || "-";
    }
    return row[col.id] || "-";
  };
  const columns = [
    {
      id: 'id',
      accessor: 'id',
      title: 'ID'
    },
    {
      id: 'user_id',
      accessor: 'user_id',
      title: 'User',
      Component: ({ row }) => {
        return (
          <>
            {row.test_mode && <div className="default-number" style={{ marginTop: "4px" }}>
              Test Mode
            </div>}
            <div>{`${row.user_id.first_name || ""} ${row.user_id.last_name ||
              "-"} `}</div>
          </>
        );
      }
    },
    {
      id: 'response_time',
      accessor: 'response_time',
      title: 'Response time',
      Component: ({ row }) => {
        return row?.response_time ? timeHelper(row?.response_time) : '-'
      }
    },
    {
      id: 'alert_type',
      accessor: 'alert_type',
      title: 'Alert type',
      Component: ({ row }) => {
        const val = REVERSED_ALERT_TYPE[row['alert_type']];
        return (
          <span style={{ color: COLOR_LIST[val.toLowerCase()] }}>{val || '-'}</span>
        );
      }
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      title: 'Alert created',
      Component: ({ row }) => {
        return moment(row['created_at']).format("DD-MM-YYYY hh:mm") || '-'
      }
    },
    {
      id: 'user_id',
      accessor: 'user_id',
      sub_id: 'mobile',
      title: 'Phone number',
      Component: ({ row }) => {
        return row?.user_id?.mobile || '-'
      }
    },
    {
      id: 'current_location',
      accessor: 'current_location',
      sub_id: 'country',
      title: 'Country',
      Component: ({ row }) => {
        return row?.current_location?.country || '-';
      }
    },
    {
      id: 'current_location',
      accessor: 'current_location',
      sub_id: 'city',
      title: 'City',
      Component: ({ row }) => {
        return row?.current_location?.city || '-';
      }
    },
    {
      id: 'local_police_number',
      accessor: 'local_police_number',
      title: 'Local police number',
      width: '10%',
      Component: ({ row }) => {
        return row && row?.policeNumber || '-'
      }
    },
    {
      id: 'status',
      accessor: 'status',
      title: 'Status',
      Component: ({ row }) => {
        return <StatusContainer>
          <Bullet style={{ backgroundColor: COLOR_LIST[REVERSED_ALERT_STATUS[row['status']].toLowerCase()] }} />{" "}
          {REVERSED_ALERT_STATUS[row['status']]}
        </StatusContainer>
      }
    },
    {
      id: 'actions',
      accessor: 'actions',
      title: 'Actions',
      Component: ({ row }) => {
        return (
          <Link to={`/app/inhouse/alert-detail/${row._id}?page=inhouse`}>
            <i className="icon-eye actioneye" />
          </Link>
        );
      }
    },
  ]
  return (
    <>
      <ReactTable
        COLUMNS={columns}
        DATA={currentData}
        fetchData={handlePageChange}
        totalCount={inHouseList?.total_no_of_records}
        pageSize={activePerPage}
        pageIndex={activePage}
        hiddenColumns={[USER_TYPE.TENANT_USER].includes(currentUser.user_type) ? ['actions'] : []}
      />

      <MapDialogBox open={openDialogBox} onClose={onClose} />
    </>
  );
}
