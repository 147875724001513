
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
// import { text } from 'utils/text.config';
import {
  Wrapper,
  WrapperInput,
  InputField,
  Delete,
  SearchButton,
  Form,
} from './style';

// const { common } = text;

export default function SearchInput({ background, onChange, searchValue, clearSearch, onSubmit, placeholder }) {

  return (
    <Form onSubmit={onSubmit}>
      <Wrapper>
        <WrapperInput>
          <InputField
            name='input-text'
            background={background}
            value={searchValue}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder || 'Search'}
          />
          {
            searchValue &&
            <Delete >
              {/* <i > <CloseIcon onClick={clearSearch}/></i> */}
              <i className="ion-ios-close-empty" onClick={clearSearch}></i>
            </Delete>
          }
        </WrapperInput>
        <SearchButton onClick={onSubmit}>
          <i className="ion-ios-search"></i>
          {/* <i> <SearchIcon/></i> */}
        </SearchButton>
      </Wrapper>
    </Form>
  )
}
