import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getTravelLogDetail } from "redux/actions/travel";
import Loader from "../../../Loader";
import {
  CrisisContainer,
  DetailHeader,
  Bullet,
  UserNameContainer,
  TravelIdContainer,
  NameTravelIDBox,
} from "../../style";
import CrisisLog from "./CrisisLog";
import { addAlertComment, getAlertDetail } from "redux/actions/CrisisLogAlert";
import { useParams } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LogActivity from "../../logActivity";
import { ALERT_STATUS, CHECKLIST_TYPE } from "utils/alertHelper";
export default function InHouseDetails() {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const printHeadRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { alertDetails } = useSelector(({ CRISISLOGALERT }) => CRISISLOGALERT);

  useEffect(() => {
    dispatch(getAlertDetail(id, setLoader));

  }, []);

  return loader ? (
    <Loader />
  ) : (
    <CrisisContainer>
      {!!alertDetails && alertDetails.alert && alertDetails.alert.test_mode ? <div className="test-mode">Test Mode</div> : null}
      <div ref={printHeadRef}>
        <DetailHeader>Crisis log, Ongoing events</DetailHeader>
        <NameTravelIDBox>
          <UserNameContainer>
            <FiberManualRecordIcon style={{ fontSize: "10px", color: 'rgb(95, 170, 176)', marginRight: '5px' }} />
            User:{" "}
            {alertDetails && alertDetails.alert && alertDetails.alert.userName ? alertDetails.alert.userName : "-"}
          </UserNameContainer>
          <UserNameContainer>
            <FiberManualRecordIcon style={{ fontSize: "10px", color: 'rgb(225, 108, 108)', marginRight: '5px' }} />
            In house
          </UserNameContainer>
          <TravelIdContainer>
            Alert ID: <b>{alertDetails && alertDetails.alert && alertDetails.alert.id ? alertDetails.alert.id : '-'}</b>
          </TravelIdContainer>
        </NameTravelIDBox>
        <DetailHeader style={{ display: 'none' }} className="capitalize">{CHECKLIST_TYPE?.IN_HOUSE.title} Logs</DetailHeader>
      </div>
      <LogActivity addLogAPI={addAlertComment} alertType={CHECKLIST_TYPE.IN_HOUSE} isCompleted={alertDetails?.alert?.alertStatus == ALERT_STATUS?.COMPLETED} setActiveTab={setActiveTab} activeTab={activeTab} />
      {activeTab === 0 && <CrisisLog id={id} navigate={navigate} printHeadRef={printHeadRef} />}
    </CrisisContainer>
  );
}
