import React, { forwardRef } from "react"
import { InputLabel } from '@mui/material'
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { CustomPickerSpan, CustomerPickerIconContainer, PlaceholderStyle } from '../style'

export const CustomPickerInput = forwardRef(({ value, onClick }, ref) => {
    const newStyle = { marginBottom: '3px', cursor: 'pointer' }
    if (value) {
        Object.assign(newStyle, PlaceholderStyle, {color: 'black'})
    } else {
        Object.assign(newStyle, PlaceholderStyle)
    }

    return (
        <div onClick={onClick} style={{ marginLeft: '18%', marginTop: '17px', height: '36px', borderBottom: '1px solid rgb(229, 230, 233)', cursor: 'pointer', minWidth: '50%' }}>
            <InputLabel ref={ref} style={newStyle}>{value ? value : 'Estimated time of arrival'}</InputLabel>
            {!value && <CustomPickerSpan>{'00:00'}</CustomPickerSpan>}
            <CustomerPickerIconContainer><KeyboardArrowDownOutlined /></CustomerPickerIconContainer>
        </div>
    )
})