import React, { useState, useRef, useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Loader from "modules/Loader";
import './style.css'
import { fetchError } from "../../redux/actions";
import { useDispatch } from "react-redux";

export const MapContainer = (props) => {
    const dispatch = useDispatch();
    const [defaultPosition, setDefaultPosition] = useState([{
        lat_long: ['23.033863', '72.585022'], name: 'Ahmedabad, India'
    }])
    const [markerList, setMarkerList] = useState([])
    const markerRef = useRef(null);
    const infoWindowRef = useRef(null);
    const mapRef = useRef(null);
    const [activeMarker, setActiveMarker] = useState(null)
    const [showMarker, setShowMarker] = useState(false)
    const [markerText, setMarkerText] = useState(props.address)
    const [position, setPosition] = useState({ lat: defaultPosition[0].lat_long[0], lng: defaultPosition[0].lat_long[1] })
    const zoom = props.zoom ? props.zoom : 14

    useEffect(() => {
        const loc = window.navigator && window.navigator.geolocation    // user current location get
        if (loc) {
            loc.getCurrentPosition((position) => {
                setDefaultPosition([{ lat_long: [position.coords.latitude, position.coords.longitude] }])
                if (!(props.markerList && props.markerList.length && props.markerList[0] && props.markerList[0].lat_long && props.markerList[0].lat_long[0])) {
                    setPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
                }
            })
        } else {
            dispatch(fetchError('Current location not found'))
        }
        document.getElementById("autocomplete-input") && renderAutoComplete()
        document.documentElement.style.setProperty('--width', props.width ? props.width : '100%') // to set width variable
        document.documentElement.style.setProperty('--height', props.height ? props.height : '350px') // to set height variable
    }, [])

    useEffect(() => {

        if (props.markerList && props.markerList.length && JSON.stringify(props.markerList) !== JSON.stringify(markerList)) {

            const mlist = props.markerList
            const lat = mlist && mlist[0] && mlist[0].lat_long[0] && mlist[0].lat_long[0]
            const lng = mlist && mlist[0] && mlist[0].lat_long[1] && mlist[0].lat_long[1]
            const center = lat ? { lat, lng } : { lat: defaultPosition[0].lat_long[0], lng: defaultPosition[0].lat_long[1] }
            setMarkerList(props.markerList)
            setPosition(center)
        }
    }, [props.markerList])
    let infoShow = props && props.infoBox
    const onMarkerClick = (props, marker, e) => {
        if (infoShow === "none") {
            setShowMarker(false);
        } else {
            setActiveMarker(marker)
            setMarkerText(marker.name);
            setShowMarker(true);
        }
    }

    const onMapReady = (mapProps, map) => {
        if (markerRef.current && props.infoBox === "visible") {
            setActiveMarker(markerRef.current.marker)
            setMarkerText(markerRef.current.marker.name);
            // setShowMarker(props.checkInDestination)
            setTimeout(() => setShowMarker(true), 200)
        }
    }

    useEffect(() => {
        if (position && props.infoBox && props.infoBox === "visible") {
            if (props.markerList && props.markerList[0] && props.markerList[0].lat_long
                && !!props.markerList[0].lat_long[0]) {
                setTimeout(() => onMapReady(), 800)
            }
        }
    }, [position, props.markerList])

    const renderAutoComplete = () => {
        const { google } = props;

        if (!google) return;

        const center = new google.maps.LatLng(position.lat, position.lng)

        const defaultBounds = {
            north: center.lat() + 0.1,
            south: center.lat() - 0.1,
            east: center.lng() + 0.1,
            west: center.lng() - 0.1,
        };

        const options = {
            bounds: defaultBounds,
            //            componentRestrictions: { country: ["in", "us"] },
            fields: ["address_components", "geometry", "icon", "name", "formatted_address", "adr_address", "place_id"],
            strictBounds: false,
            types: ["geocode", "establishment"],
        };
        var map = null
        try {
            map = new google.maps.Map(mapRef, { center, zoom: 3 });
        } catch (err) {
            console.error(`Error creating Map instance: ${err}`);
        }
        const input = document.getElementById("autocomplete-input")
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener('place_changed', () => {
            let name = ''
            const place = autocomplete.getPlace();
            if (!place.geometry) return;
            if (map) {
                map.setCenter(place.geometry.location);
                map.setZoom(10);
            }

            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()
            setMarkerList([{ lat_long: [lat, lng], name: place.name }])
            setMarkerText(place.name)
            setPosition(place.geometry.location);
            // to get country
            place.address_components && place.address_components.map((a) => {
                if (a.types.includes('country')) {
                    name = a.long_name
                }
            })

            let placeObj = {
                placeId: place.place_id,
                country: name
            }

            props.onPlaceChange && props.onPlaceChange(place.name, { lat, lng }, placeObj)
        });
    }

    return (
        <Map
            ref={mapRef}
            center={position}
            google={props.google}
            initialCenter={position}
            zoom={zoom}
            className={`map ${props.className}`}
            containerStyle={{ width: '66%', height: '50%', ...props.containerStyle }}
            onClick={() => {
                if (props && !props.infoBox) {
                    setShowMarker(false)
                }
            }}
            onReady={onMapReady}>
            {markerList && markerList.map((gps, index) => {
                const pos = gps && gps.lat_long && gps
                const latlng = Array.isArray(pos.lat_long) ? { lat: pos.lat_long[0], lng: pos.lat_long[1] } : gps.lat_long
                return (<Marker key={`gps-${index}`} ref={markerRef} position={latlng} onClick={!props.addTravel && props.infoBox !== "visible" && onMarkerClick} name={`${gps.name} | ${pos.lat_long ? pos.lat_long.join(', ') : ''}`} />)
            })}
            <InfoWindow marker={activeMarker} visible={showMarker} ref={infoWindowRef} id="mapwindow" >
                <div id="custom-window" style={{ height: '100%', width: '100%' }}>
                    <div><b>{markerList && markerList[0] ? markerList[0].name : markerText && markerText.split('| ')[0]}</b></div>
                    {!!props.showCoord && <div style={{ marginTop: '10px' }}>{'GPS Coordinate: '}<br />{markerText && markerText.split('| ')[1]}</div>}
                </div>
            </InfoWindow>
        </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    LoadingContainer: Loader
})(MapContainer)
