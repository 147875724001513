import React, { useEffect } from 'react'
import { locationGridColumns } from './data'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import COLORS from 'utils/colors'
import { useDispatch, useSelector } from 'react-redux'
import { LocationActionContainer } from './style'
import { useState } from 'react'
import ConfirmDltDialog from '../ConfirmDltDialog'
import { deleteInhouseLoc, isInHouseEdit, resetDelInHouseLoc } from 'redux/actions/alert'
import { useNavigate } from 'react-router'
import { USER_TYPE } from 'utils/commonHelper'
import MapDialogBox from './mapDialogBox'
import { EmptyBox } from 'components/EmptyBox'
import ReactTable from 'components/common/table/ReactTable2'

export default function InHouseLocationGrid() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [openDelConfirm, setOpenDelConfirm] = useState(false)
    const [openDelSuccess, setOpenDelSuccess] = useState(false)
    const [confirmContent, setConfirmContent] = useState('')
    const [locId, setLocId] = useState('')
    const [showMapDialog, setShowMapDialog] = useState(false);
    const [markerList, setMarkerList] = useState([])

    const { inHouseLocList, removedInHouseLoc } = useSelector(({ ALERT }) => ALERT)

    const currentUser = JSON.parse(localStorage.getItem('profile'))
    const tanent_user = currentUser.user_type === USER_TYPE.TENANT_USER
    const control_user = currentUser.user_type === USER_TYPE.CONTROL_USER
    const excontrol_user = currentUser.user_type === USER_TYPE.EXTERNAL_CONTROLLER

    const permission = (tanent_user || control_user || excontrol_user)

    useEffect(() => {
        localStorage.removeItem("inHousetab")
    }, [])

    useEffect(() => {
        if (removedInHouseLoc) {
            setConfirmContent('In-house location removed successfully.')
            setOpenDelSuccess(true)
            dispatch(resetDelInHouseLoc())
        }
    }, [removedInHouseLoc])

    const onDeleteClick = (id) => {
        setLocId(id)
        setConfirmContent('Are you sure you want to delete this office?')
        setOpenDelConfirm(true)
    }

    const onDelConfirm = () => {
        setOpenDelConfirm(false)
        dispatch(deleteInhouseLoc({ loc_id: locId }))
    }

    const onEditClick = (row) => {
        dispatch(isInHouseEdit({ value: true, editData: row }))
        navigate(`/app/inhouse/edit-location/${row._id}`)
    }

    const onLocationClick = (markerList, row) => {
        // let latLong = row && row.address && row.address.street_address ? markerList : []
        setShowMapDialog(true);
        setMarkerList(markerList);
    }

    const renderActions = (row) => {
        const lat_long = row.address && row.address.lat_long && row.address.lat_long.length ? [row.address.lat_long[0], row.address.lat_long[1]] : []
        return (
            <LocationActionContainer>
                <i className="icon-location-pin mapicon" onClick={() => onLocationClick([{ lat_long }], row)} />
                <div style={{ color: COLORS.green, cursor: 'pointer' }} onClick={() => onEditClick(row)}>edit</div>
                <div style={{ color: COLORS.red, cursor: 'pointer' }} onClick={() => onDeleteClick(row._id)}>delete</div>
            </LocationActionContainer>
        )
    }

    const renderValue = (row, col) => {
        if (col.id === 'room') {
            return row.room ? row.room.length : '0'
        } else if (col.sub_id) {
            return row[col.id][col.sub_id] || '-'
        } else {
            return row[col.id] || '-'
        }
    }
    const columns = [
        {
            id: 'office',
            accessor: 'office',
            title: 'Office'
        },
        {
            id: 'address',
            accessor: 'address',
            sub_id: 'country',
            title: 'Country',
            Component: ({ row }) => {
                return row?.address?.country || '-';
            }
        },
        {
            id: 'address',
            accessor: 'address',
            sub_id: 'city',
            title: 'City',
            Component: ({ row }) => {
                return row?.address?.city || '-';
            }
        },
        {
            id: 'address',
            accessor: 'address',
            sub_id: 'street_address',
            title: 'Street',
            Component: ({ row }) => {
                return row?.address?.street_address || '-';
            }
        },
        {
            id: 'room',
            accessor: 'room',
            title: 'Rooms',
            width: '10%',
            Component: ({ row }) => {
                return row?.room ? row?.room?.length : '0';
            }
        },
        {
            id: 'actions',
            accessor: 'actions',
            title: 'Actions',
            width: '15%',
            Component: ({ row }) => {
                const lat_long = row?.address?.lat_long && row.address.lat_long.length >= 2 ? [row.address.lat_long[0], row.address.lat_long[1]] : []
                return (
                    <div className={"flex "}>
                        <i className="icon-location-pin mapicon" onClick={() => onLocationClick([{ lat_long }], row)} />
                        <div className={`pl-2`} style={{ color: COLORS.green, cursor: 'pointer' }} onClick={() => onEditClick(row)}>edit</div>
                        <div className={`pl-2`} style={{ color: COLORS.red, cursor: 'pointer' }} onClick={() => onDeleteClick(row._id)}>delete</div>
                    </div>
                );
            }
        },
    ];
    return (
        <>
            <ReactTable
                COLUMNS={columns}
                DATA={inHouseLocList || []}
                totalCount={inHouseLocList ? inHouseLocList?.length : 0}
                pagination={false}
                hiddenColumns={[USER_TYPE.TENANT_USER, USER_TYPE.CONTROL_USER, USER_TYPE.EXTERNAL_CONTROLLER].includes(currentUser.user_type) ? ['actions'] : []}
            />
            {/* <Table className={"custome-tbl"}>
                <TableHead>
                    <TableRow>
                        {locationGridColumns.map((data, index) => {
                            if (data.id === 'actions' && permission) return
                            return <TableCell key={`${data.id}-${data.sub_id}-${index}`} style={{ color: COLORS.greylighttext, width: data.width && data.width }}>{data.label}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inHouseLocList && inHouseLocList.length ? inHouseLocList.map((row, index) => {
                        return (
                            <TableRow key={`row-${index}`}>
                                {locationGridColumns.map((col, index) => {
                                    if (col.id === 'actions' && permission) return
                                    return (
                                        col.id === 'actions' ? <TableCell key={`colData-${index}`}>{renderActions(row)}</TableCell>
                                            : <TableCell key={`colData-${index}`}>{renderValue(row, col)}</TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    }) : <TableRow><TableCell colSpan={10}><EmptyBox>{'No Record found'}</EmptyBox></TableCell></TableRow>}
                </TableBody>
            </Table> */}
            <ConfirmDltDialog
                open={openDelConfirm}
                content={confirmContent}
                btnLabels={{
                    no: "No",
                    Yes: "Yes",
                }}
                onClose={() => setOpenDelConfirm(false)}
                onConfirm={onDelConfirm}
            />
            <ConfirmDltDialog
                open={openDelSuccess}
                content={confirmContent}
                btnLabels={{
                    Yes: "OK",
                }}
                onConfirm={() => setOpenDelSuccess(false)}
            />
            <MapDialogBox
                open={showMapDialog}
                onClose={() => setShowMapDialog(false)}
                markerList={markerList}
            />
        </>
    )
}