import React, { useRef } from 'react'
import COLORS from '../../utils/colors'
import { ErrorBox } from '../ErrorBox.js'
import { GoogleInputWrapper, GoogleInputContainer, GoogleInputBox, Delete, SearchButton } from './style'

export const GoogleAutocomplete = ({ onChange, error, onRemove, value, errorStyle, inputContainerStyle, inputWrapperStyle }) => {
    const inputContainerRef = useRef(null);

    const onInputFoucs = () => {
        inputContainerRef.current.style['border'] = '1px solid #000'
    }

    const onInputBlur = () => {
        inputContainerRef.current.style['border'] = '1px solid rgb(229,230,233)'
    }

    return (
        <>
            <GoogleInputWrapper style={inputWrapperStyle}>
                <GoogleInputContainer ref={inputContainerRef}
                    style={{ border: error ? `1px solid ${COLORS.redtext}` : '1px solid rgb(229,230,233)', ...inputContainerStyle }}>
                    <GoogleInputBox id="autocomplete-input"
                        type="text" placeholder="Search place*"
                        onChange={onChange}
                        style={{ borderColor: error && COLORS.redtext }}
                        className="search-input" onFocus={onInputFoucs} onBlur={onInputBlur}
                    />

                    {value &&
                        // <Delete>
                        <div className="mr-3 text-2xl">
                            <i className="ion-ios-close-empty " onClick={onRemove}></i>
                        </div>
                        // </Delete>
                    }
                </GoogleInputContainer>
                <SearchButton onClick={null}>
                    <i className="ion-ios-search font20"></i>
                </SearchButton>
            </GoogleInputWrapper>
            {error && <ErrorBox msg={error} style={{ paddingRight: '37px', marginTop: '3px', lineHeight: '1.66', ...errorStyle }} />}
        </>
    )
}