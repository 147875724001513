import React, { useEffect, useState } from "react";
import { getImportantContactList } from "../../redux/actions/importantContact";
import { getConnectedSubdivisionListSidebar } from "../../redux/actions/SubDivision";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CustomNotification } from "../../components/CustomNotification";
import SidebarLoader from "modules/SidebarLoader";
import { fetchStart } from "../../redux/actions/Common";
import { USER_TYPE } from "../../utils/commonHelper";
import "./sidebar.css";
import { getAlertList } from "../../redux/actions/alert";
import { io } from 'socket.io-client'
import { color } from "@mui/system";
import ReportIcon from '@mui/icons-material/Report';

function SideBar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { contactList } = useSelector(({ IMPORTANTCONTACT }) => IMPORTANTCONTACT);
    const { secSubdivisionUser, ConnectedSub, allUsers } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
    const { newListRec, inHouseNewListRec, alertList } = useSelector(({ ALERT }) => ALERT)
    const { sideloadig } = useSelector(({ common }) => common);
    const { currentuser: currentUser, loader, socektObj } = useSelector(({ USERPROFILE }) => USERPROFILE);
    const { travelList } = useSelector(({ TRAVEL }) => TRAVEL);
    const [alertTravel, setAlertTravel] = useState()
    const [inHouse, setInHouse] = useState()
    const [crisisLog, setCrisisLog] = useState()
    const [checkUser, setCheckUser] = useState()
    const [control_user, setControlUser] = useState()
    const [secSubDivId, setSecSubDivId] = useState(false);
    const [secSubAlertStatus, setSecSubAlertSTatus] = useState(false);
    const Profile = JSON.parse(localStorage.getItem("profile"));
    // const sec_sub = JSON.parse(localStorage.getItem("sec_subdivision"));
    useEffect(() => {
        setAlertTravel(!!currentUser?.detail?.has_access_to?.alert_travel)
        setInHouse(!!currentUser?.detail?.has_access_to?.in_house)
        setCrisisLog(!!currentUser?.detail?.has_access_to?.alert_travel || currentUser?.detail?.has_access_to?.in_house)
        setCheckUser(currentUser?.detail?.user_type)
        // setControlUser(!!currentUser?.detail?.control_user)
    }, [currentUser, socektObj]);

    useEffect(() => {
        if (secSubdivisionUser) {
            setSecSubDivId(!!secSubdivisionUser);
        } else {
            setSecSubDivId(false);
        }
    }, [secSubdivisionUser]);

    useEffect(() => {
        if (ConnectedSub && ConnectedSub?.data?.length > 0 && Profile?.user_type == 2) {
            let count = ConnectedSub.data.filter((a) => a.has_alerts === true)
            setSecSubAlertSTatus(count.length > 0 ? true : false);
        }
    }, [ConnectedSub])

    useEffect(() => {
        if (Profile.user_type !== 3 && Profile.user_type !== 1 && Profile.user_type !== 2) {
            dispatch(getImportantContactList());
        }

        if (Profile.user_type == 2) {
            dispatch(getConnectedSubdivisionListSidebar("id", "desc", "", 1, 30))
        }
    }, []);
    const ex_control = [
        alertTravel && { title: 'Alert', to: '/app/Alert', icon: 'icon-bell', notificationCount: newListRec },
        inHouse && { title: 'In house', to: '/app/Inhouse', icon: 'icon-home', notificationCount: inHouseNewListRec },
        crisisLog && { title: 'crisis log', to: '/app/CrisisLog', icon: 'icon-layers' },
        alertTravel && { title: 'Travel', to: '/app/Travel', icon: 'icon-plane', notificationCount: travelList?.raisedTravelCount },
    ].filter(Boolean);


    const permissionWiseMenu = {
        [1]: [//super admin
            { title: 'Dashboard', to: '/app/dashboard', icon: 'icon-user' },
            { title: 'Tenants', to: '/app/tenants', icon: 'icon-user' },
        ],
        [2]: [// sub-external-controller
            { title: 'Tenants', to: '/app/Tenants', icon: 'icon-user' },
            { title: 'Connected Subdivisions', to: '/app/ConnectedSubDivisions', icon: 'icon-user', alertIcon: true },
            secSubDivId && { title: 'Alert', to: '/app/Alert', icon: 'icon-bell', notificationCount: newListRec },
            secSubDivId && { title: 'In house', to: '/app/Inhouse', icon: 'icon-home', notificationCount: inHouseNewListRec },
            secSubDivId && { title: 'crisis log', to: '/app/CrisisLog', icon: 'icon-layers' },
            secSubDivId && { title: 'Travel', to: '/app/Travel', icon: 'icon-plane', notificationCount: travelList?.raisedTravelCount },
        ],
        [3]: [//TENANT_USER
            { title: 'Subdivisions', to: '/app/SubDivisions', icon: 'icon-user' },
            { title: 'ext controllers', to: '/app/ExternalUsers', icon: 'icon-user' },
            { title: 'Alert', to: '/app/Alert', icon: 'icon-bell', notificationCount: newListRec },
            { title: 'In house', to: '/app/Inhouse', icon: 'icon-home', notificationCount: inHouseNewListRec },
            { title: 'crisis log', to: '/app/CrisisLog', icon: 'icon-layers' },
            { title: 'Travel', to: '/app/Travel', icon: 'icon-plane', notificationCount: travelList?.raisedTravelCount },
        ].filter(Boolean),
        [4]: [//SUBDIVISION_USER
            alertTravel && { title: 'Alert', to: '/app/Alert', icon: 'icon-bell', notificationCount: newListRec },
            inHouse && { title: 'In house', to: '/app/Inhouse', icon: 'icon-home', notificationCount: inHouseNewListRec },
            crisisLog && { title: 'crisis log', to: '/app/CrisisLog', icon: 'icon-layers' },
            alertTravel && { title: 'Travel', to: '/app/Travel', icon: 'icon-plane', notificationCount: travelList?.raisedTravelCount },
            { title: 'Users', to: '/app/users', icon: 'icon-user' },
            // { title: 'Tenantsnew', to: '/app/tenants', icon: 'icon-user' },
        ].filter(Boolean),
        [5]: ex_control,//EXTERNAL_CONTROLLER //CONTROL_USER
        [6]: ex_control,
        [7]: ex_control,
        common: [
            { title: 'Settings', to: '/app/Settings', icon: 'icon-settings' },
        ]
    }

    const renderImportantContacts = () => {
        return (
            <>
                <p className="info-tlt">Important contact :</p>
                {!!sideloadig ? <SidebarLoader /> :
                    (contactList?.important_contact && contactList?.important_contact.length > 0) ? contactList.important_contact
                        .map((row, index) => (
                            <li key={index} style={{ listStyleType: 'none', color: 'rgb(144, 153, 170)', fontFamily: 'SF Pro Text' }}>
                                <div className="info-items">{row.name}: {row.phone_number}</div>
                            </li>
                        ))
                        : <li
                            // style={{
                            //     marginLeft: '66px', fontFamily: 'SF Pro Text',
                            //     marginBottom: '10px',
                            //     color: 'rgb(144,153,170)',
                            //     fontSize: "20px"}}
                            className="info-noContacts" >{'No Contacts'}</li>}
                {checkUser === USER_TYPE.EXTERNAL_CONTROLLER || checkUser === USER_TYPE.CONTROL_USER || (checkUser === USER_TYPE.END_USER && control_user) ? null : <li><Link style={{ color: '#4892c3', backgroundColor: 'white' }} to={`/app/Settings?tab=0`} className="info-edit">Edit list</Link></li>}
            </>
        )
    }
    return (
        <>
            <nav className="root">
                <ul style={{ listStyle: "none", height: "100%", padding: 0, marginTop: '30px' }}>
                    {currentUser?.detail?.user_type && [...permissionWiseMenu[currentUser?.detail?.user_type], ...permissionWiseMenu.common].map((menu, index) => {
                        if (menu) {
                            return (
                                <li className="li1" key={`menu-${index}`}>
                                    <NavLink activeclassname="activeNavLink" to={menu.to} >
                                        <i className={`${menu.icon} li2`} style={{ color: menu.notificationCount > 0 ? 'rgb(225, 108, 108)' : '' }} />
                                        <span
                                            style={{
                                                position: "relative",
                                                font: "inherit",
                                                verticalAlign: "baseline",
                                            }}
                                        >
                                            {menu.title}
                                        </span>
                                        {(menu.alertIcon && secSubAlertStatus) &&
                                            <div className="default-number-sidebar" ><ReportIcon fontSize="medium" /></div>}
                                        {menu.notificationCount > 0 && <CustomNotification count={menu.notificationCount} />}
                                    </NavLink>
                                </li>
                            )
                        } else {
                            return
                        }
                    }
                    )}
                    {/* {checkUser == USER_TYPE.SUBDIVISION_USER || checkUser == USER_TYPE.EXTERNAL_CONTROLLER || checkUser === USER_TYPE.CONTROL_USER || checkUser === USER_TYPE.END_USER ? renderImportantContacts() : ''} */}
                    {[USER_TYPE.SUBDIVISION_USER].includes(checkUser) && renderImportantContacts()}
                    {/* USER_TYPE.EXTERNAL_CONTROLLER, USER_TYPE.CONTROL_USER, USER_TYPE.END_USER*/}
                </ul>
            </nav>
        </>
    );
}

export default React.memo(SideBar);
