import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmDltDialog from '../../ConfirmDltDialog'
import {
    BasicInfoTitle,
    UserDetailContainer,
    BasicInfoValue,
    DetailHeader,
    BasicCardValue,
    UserBasicInfoContainer,
    AlertDetailContainer,
    AlertDetailContainer1,
    UserDetailTitle,
    DetailContainer,
    AlertInfoValue,
    AddTravelButtonContainer,
    AddTravelButtonContainer1,
    AddTravelButtonContainer2,
    AddButton,
    updatePositionBtn,
    AlertInfoContainer,
    ButtonStyle,
    PresaveMsgContainer,
    PresavedMsgHeader,
    PresavedMsg,
    AlertDetailContainer2,
    BasicInfoTitleForInhouseComment
} from '../style'
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router'
import { getAlertDetail, resolveAlert, resetResolveAlert, addAlertComment, resetUpdateGPS, updateGPSLocation } from 'redux/actions/alert'
import * as moment from 'moment'
import { REVERSED_ALERT_TYPE, timeHelper } from 'utils/alertHelper'
import { emptySpace } from '@jumbo/constant/ValidationRegex'
import { requiredMessage } from '@jumbo/constant/ErrorMessages'
import { CustomTextarea } from 'components/CustomTextarea';
import GooglMap from 'components/GooglMap';
import { Buttons } from '../../Button';
import { fetchError } from 'redux/actions';
import { ALERT_TYPE } from 'utils/alertHelper';
import Loader from '../../Loader';
import { getDefaultPoliceNumberList } from 'redux/actions/DefaultPoliceNumber'
import { RESOLVE_ALERT } from '@jumbo/constant/ActionTypes';
export default function AlertDetail() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { alertDetail, alertResolve, updateGPS, comment: alertComment } = useSelector(({ ALERT }) => ALERT)
    const alertDtl = alertDetail && alertDetail.alert ? alertDetail.alert : {}
    const [latlng, setLatLng] = useState({})
    const [loader, setLoader] = useState(false);
    const [openConformRSDialog, setOpenConformRSDialog] = useState(false)
    const [comment, setComment] = useState('')
    const [alertSuccess, setAlertSuccess] = useState();
    const [errors, setErrors] = useState({})
    const [openResolveAlertConfirm, setOpenResolveAlertConfirm] = useState(false)

    console.log("alertDtl", alertDtl)
    const { policenumber } = useSelector(({ POLICENUMBER }) => POLICENUMBER)

    const alert_id = location && location.pathname && location.pathname.split('/').pop() //'63be832f2e65b3d1f61f25bf'
    const search = location && location.search
    const page = new URLSearchParams(search).get('page');
    let tabNum = localStorage.getItem("tab");
    let gpsUpdate = true


    useEffect(() => {
        if (alert_id) {
            dispatch(getAlertDetail({ alert_id }, setLoader))
        }
        // dispatch(getDefaultPoliceNumberList())
    }, [])


    useEffect(() => {
        if (alertDtl.lastRegisteredLocation && alertDtl.lastRegisteredLocation.lat_long.length && alertDtl.lastRegisteredLocation.lat_long[0]) {
            const lat_long = alertDtl.lastRegisteredLocation.lat_long

            setLatLng({ lat: lat_long[0], lng: lat_long[1] })
        }
        if (alertDtl && alertDtl.alertStatus === 2 && Number(tabNum) === 1) {
            localStorage.setItem("alertTab", 1)
        }
    }, [alertDtl])

    useEffect(() => {
        if (alertResolve === 200) {
            setOpenResolveAlertConfirm(true)
        }

        if (updateGPS === 200) {
            dispatch(getAlertDetail({ alert_id }, setLoader, gpsUpdate))
            dispatch(resetUpdateGPS())
        }

        if (alertComment && Object.keys(alertComment).length) {
            setComment('')
        }
    }, [alertResolve, updateGPS, alertComment])

    const onUpdateGPS = () => {
        const lat_long = alertDtl.lastRegisteredLocation.lat_long

        // const loc = window.navigator && window.navigator.geolocation

        // if (loc) {
        //     loc.getCurrentPosition((position) => {

        // dispatch(updateGPSLocation({ alert_id, lat: lat_long[0], long: lat_long[1] }))
        dispatch(updateGPSLocation(alert_id))
        //     })
        // } else {
        //     dispatch(fetchError('Current location not found'))
        // }
    }

    const onResolveAlertConfirmation = () => {
        if (alertSuccess) {
            setOpenResolveAlertConfirm(false)
            setTimeout(() => setAlertSuccess(), 500);
        } else {
            setOpenResolveAlertConfirm(false)
            navigate(page === 'inhouse' ? '/app/inhouse' : '/app/alert')
            dispatch(resetResolveAlert())
        }
    }

    const handleConfirmResolved = () => {
        dispatch(resolveAlert({
            alert_id,
            delayed_alert_time: moment().valueOf()
        }))
        setOpenConformRSDialog(false)
    }

    const handleCancelResolved = () => {
        setOpenConformRSDialog(false)
    }

    const handleCancelConfirmDialogue = () => {
        dispatch({ type: RESOLVE_ALERT, payload: false });
        setOpenResolveAlertConfirm(false);
    }

    const onAddComment = () => {
        if (comment.match(emptySpace)) {
            setErrors({ ...errors, comment: requiredMessage })
        } else {
            dispatch(addAlertComment({ alert_id, comment }, () => setOpenResolveAlertConfirm(true)))
        }
    }

    const onCommentChange = (e) => {
        errors.comment && delete errors.comment
        setErrors(errors)
        setComment(e.target.value)
        setAlertSuccess(e.target.value);
    }

    const renderAlertlInfo = (title, value) => {
        return (
            <AlertInfoContainer style={{ paddingBottom: '8px', marginBottom: '20px' }}>
                {title === 'Local police number' ?
                    <BasicInfoTitle style={{ marginBottom: !value && '26px', color: 'rgb(221, 97, 97)' }}>{value ? title : ''}</BasicInfoTitle> :
                    <BasicInfoTitle style={{ marginBottom: !value && '26px' }}>{value ? title : ''}</BasicInfoTitle>
                }
                {title === 'GPS tracking:' ?
                    <AlertInfoValue style={{ color: !value ? 'rgb(133, 142, 160)' : 'rgb(95, 170, 176)' }}>{value ? value : title}</AlertInfoValue> :
                    <AlertInfoValue style={{ color: !value && 'rgb(133, 142, 160)' }}>{value ? value : title}</AlertInfoValue>
                }
            </AlertInfoContainer>
        )
    }
    const renderAlertInfoCard = () => {
        return (
            <div style={{ marginTop: '35px', width: '78%' }}>
                <UserBasicInfoContainer style={{ marginTop: '20px', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ width: '50%' }}>
                        {renderAlertlInfo('Time since alert:', alertDtl.response_time ? timeHelper(alertDtl.response_time) : '-')}
                        {renderAlertlInfo('Alert type:', `${alertDtl.alertType ? REVERSED_ALERT_TYPE[alertDtl.alertType] : '-'}, ${moment(alertDtl.alertCreatedAt).format('DD-MM-YYYY HH:mm')}`)}
                        {renderAlertlInfo('GPS tracking:',
                            // alertDtl.gpsTracking=='Disabled'? alertDtl.gpsTracking=='Movement'?'Movement':'Disabled':
                            // alertDtl.gpsTracking !=='Movement' && alertDtl.gpsTracking !=='Disabled'?`${alertDtl.gpsTracking} mins`:''|| '-'
                            alertDtl.gpsTracking !== 'Movement' && alertDtl.gpsTracking !== 'Disabled' ? `${alertDtl.gpsTracking} mins` : alertDtl.gpsTracking || '-'
                        )}
                        {/* {renderAlertlInfo('GPS tracking:', timeHelper(alertDtl.gpsTime) || '-')} */}
                        {renderAlertlInfo('GPS address:', (alertDtl.lastRegisteredLocation && alertDtl.lastRegisteredLocation.address) ? `${alertDtl.lastRegisteredLocation.address}` : '-')}
                        {renderAlertlInfo('Local police number', alertDtl && alertDtl?.policeNumber || '-')}
                        {/* <UserDetailTitle style={{ color: 'rgb(72, 146, 195)' }}>In-house Comment :</UserDetailTitle>
                        <div style={{ marginTop: '15px' }}>
                            <AlertDetailContainer2>
                                <BasicInfoTitleForInhouseComment>{alertDtl?.preservemsg || 'We have not gotten any comments on the new design. I would appreciate your comments on this issue. We have received positive comments from many of our readers. She could not be reached for comment. We have not gotten any comments on the new design. I would appreciate your comments on this issue. We have received positive comments from many of our readers. She could not be reached for comment.'}</BasicInfoTitleForInhouseComment>
                            </AlertDetailContainer2>
                        </div> */}
                    </div>
                    <div style={{ width: '50%', marginLeft: '50px' }}>
                        {renderAlertlInfo('GPS time:', alertDtl.gpsTime ? moment(alertDtl.gpsTime).format('DD-MM-YYYY HH:mm:ss') : '-')}
                        {renderAlertlInfo('GPS coordinates:', alertDtl.gpsLatLong && alertDtl.gpsLatLong.length && alertDtl.gpsLatLong[0] ? alertDtl.gpsLatLong.join(', ') : '-')}
                        {renderAlertlInfo('Country/city:', (alertDtl.lastRegisteredLocation && alertDtl.lastRegisteredLocation.country) ? `${alertDtl.lastRegisteredLocation.country}, ${alertDtl.lastRegisteredLocation.city}` : '-')}
                        {renderAlertlInfo('User email', alertDtl.userEmail || '-')}
                        {page === 'inhouse' && renderAlertlInfo('Office', alertDtl.office || '-')}
                    </div>
                </UserBasicInfoContainer>
            </div>
        )
    }

    const renderAlertCard = () => {
        return (
            <div style={{ marginTop: '0px' }}>
                <UserBasicInfoContainer style={{ marginTop: '0px', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ width: '38%' }}>
                        <AlertDetailContainer>
                            <UserDetailTitle style={{ color: 'rgb(221, 97, 97)' }}> Local police number: {alertDtl && alertDtl?.lastRegisteredLocation?.country || '-'}</UserDetailTitle>
                            <BasicCardValue style={{ color: 'rgb(221, 97, 97)' }}>{alertDtl && alertDtl?.policeNumber || '-'}</BasicCardValue>
                        </AlertDetailContainer>
                    </div>
                    <div style={{ width: '38%', marginRight: '20.5%' }}>
                        <AlertDetailContainer1>
                            <UserDetailTitle style={{ color: 'rgb(72, 146, 195)' }}>User phone number:</UserDetailTitle>
                            <BasicCardValue style={{ color: 'rgb(72, 146, 195)' }}>{alertDtl.userMobile || '-'}</BasicCardValue>
                        </AlertDetailContainer1>
                    </div>
                </UserBasicInfoContainer>
            </div>
        )
    }

    const renderPresavedMsg = () => {
        return (
            <PresaveMsgContainer>
                <PresavedMsgHeader>User presaved message :</PresavedMsgHeader>
                <PresavedMsg>{alertDtl.preservemsg}</PresavedMsg>
            </PresaveMsgContainer>
        )
    }
    const testMode = () => {
        return <div className="test-mode">Test Mode</div>
    }

    const visibleTodos = useMemo(
        () => {

            if (latlng.lat && latlng.lng) {
                let cityCountry = alertDtl && alertDtl.lastRegisteredLocation && alertDtl.lastRegisteredLocation.city && alertDtl.lastRegisteredLocation.country ?
                    `${alertDtl.lastRegisteredLocation.city}, ${alertDtl.lastRegisteredLocation.country}` : ""
                return (
                    <GooglMap
                        markerList={[{ lat_long: [latlng.lat, latlng.lng], name: alertDtl.lastRegisteredLocation && alertDtl.lastRegisteredLocation.address ? alertDtl.lastRegisteredLocation.address : cityCountry }]}
                        showCoord={false} infoBox={"visible"} width={'80%'} zoom={8} />
                )
            }
        }, [latlng.lat, latlng.lng]

    );

    return (
        loader ? <Loader /> :
            <DetailContainer>
                {alertDtl.test_mode ? testMode() : null}
                <DetailHeader style={{ marginBottom: '30px' }}>Alert</DetailHeader>
                <DetailHeader style={{ fontSize: '18px', color: 'rgb(78, 145, 192)', marginBottom: '30px' }}>Alert ID: {alertDtl.id}</DetailHeader>
                {renderAlertCard()}
                {renderAlertInfoCard()}
                {(alertDtl.preservemsg && alertDtl.alertType == ALERT_TYPE.DELAYED_ALERT) ||
                    alertDtl.preservemsg && alertDtl.alertType == ALERT_TYPE.IN_HOUSE_ALERT ? renderPresavedMsg() : null}
                <UserBasicInfoContainer style={{ marginBottom: '15px', marginTop: '35px' }}>
                    <DetailHeader>Last registered:</DetailHeader>
                    <Buttons
                        variant="outlined"
                        style={{ ...AddButton, ...updatePositionBtn }}
                        // style={{width:'190px'}}
                        onClick={onUpdateGPS}
                    >Update GPS position</Buttons>

                </UserBasicInfoContainer>
                {visibleTodos}
                {/* <GooglMap
                markerList={[{ lat_long: [latlng.lat, latlng.lng], name: alertDtl.lastRegisteredLocation && alertDtl.lastRegisteredLocation.address ? alertDtl.lastRegisteredLocation.address : '' }]} 
                showCoord={false} infoBox={"visible"} width={'80%'} zoom={8}/> */}
                <UserBasicInfoContainer style={{ width: '80%', marginTop: '1%' }}>
                    <BasicCardValue style={{ fontSize: '15px' }}>GPS coordinates: {Object.values(latlng).length ? Object.values(latlng).join(', ') : '-'}</BasicCardValue>
                    <BasicCardValue style={{ fontSize: '12px' }}>location {timeHelper(alertDtl.lastRegisteredTime)}</BasicCardValue>
                </UserBasicInfoContainer>
                <div style={{ width: '80%' }}>
                    <DetailHeader style={{ margin: '30px 0' }}>Add comment:</DetailHeader>
                    <CustomTextarea
                        minRows={4}
                        placeholder="Enter your comment"
                        style={{ width: '98%' }}
                        error={errors.comment && errors.comment}
                        value={comment}
                        onChange={(e) => onCommentChange(e)}
                    />
                </div>
                <div style={{ ...AddTravelButtonContainer1, marginTop: '5px' }}>
                    <Buttons
                        buttoncheck={true}
                        buttonProps={{ marginLeft: '0px' }}
                        variant="contained"
                        style={{ ...AddButton, ...ButtonStyle, height: '49px', width: '18%' }}
                        onClick={() => setOpenConformRSDialog(true)}
                    >Alert resolved</Buttons>
                    <Buttons
                        buttoncheck={true}
                        buttonProps={{ marginLeft: '0px' }}
                        variant="contained"
                        style={{ ...AddButton, ...ButtonStyle, width: 'fit-content' }}
                        onClick={onAddComment}
                    >Add comment</Buttons>
                </div>
                <div style={{ ...AddTravelButtonContainer1, marginTop: '30px' }}>
                    <Buttons
                        // buttoncheck={true}
                        buttonProps={{ marginLeft: '0px' }}
                        variant="contained"
                        style={{ ...AddButton, ...ButtonStyle, height: '49px', width: '1px' }}
                        onClick={() => navigate(`/app/CrisisLog/${page === 'inhouse' ? 'crisis-log-inHouse-detail' : 'crisis-log-alert-detail'}/${alert_id}`)}
                    >Open in log</Buttons>
                </div>
                <ConfirmDltDialog
                    open={openConformRSDialog}
                    title='Alert Resolve'
                    content={'Are you sure you want to resolve this alert?'}
                    btnLabels={{
                        no: "No",
                        Yes: "Yes",
                    }}
                    onClose={handleCancelResolved}
                    onConfirm={handleConfirmResolved}
                />
                <ConfirmDltDialog
                    open={openResolveAlertConfirm}
                    content={alertSuccess ? `Comment was added!` : `Alert from ${alertDtl.userName} was completed successfully!`}
                    btnLabels={{
                        Yes: "OK",
                    }}
                    onClose={handleCancelConfirmDialogue}
                    onConfirm={onResolveAlertConfirmation}
                />
            </DetailContainer>
    )
}