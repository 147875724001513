export const travelCoulmns = [
    { id: 'id', label: 'Travel ID', width: '20%' },
    { id: 'lastLogEntry', label: 'Last Log entry', width: '10%' },
    { id: 'comment', label: 'Message' },
    { id: 'userFirstname', label: 'Name' },
    { id: 'travelCreatedDate', label: 'Travel Created', width: '10%' },
    { id: 'status', label: 'Travel Status' },
    { id: 'lastCheckinCountry', label: 'Last Country' },
    { id: 'lastCheckinCity', label: 'Last City' },
    { id: 'actions', label: 'Actions', width: '6%' },
]

export const checkInStatus = {
    1: 'Active',
    2: 'Comment',
    3: 'Manual check',
    4: 'Autocheck',
    5: 'Call',
    6: 'Completed'
}

export const crisisLogCols = [
    { id: 'time', label: 'Time', width: '20%' },
    { id: 'detail', label: 'Details' },
]

export const TRAVEL_STATUS = {
    'IN_ACTIVE': 0,
    'IN_PROGRESS': 1,
    'COMPLETED': 2
}

export const TRAVEL_STATUS_LIST = {
    0: 'In active',
    1: 'Active', // In progress
    2: 'Completed'
}