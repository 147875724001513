import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import { MapHeader, UserInputContainer, AddTravelContainer, AddButton, CustomTextarea, AddTravelButtonContainer, CustomError, CheckboxLabel, GoogleInputWrapper, GoogleInputBox, GoogleInputContainer, CursorStyle, CustomErrorWrapper, UserAddButton, CustomChip, CustomTextareaContainer } from '../style'
import { Autocomplete, Checkbox, TextField, Button, Alert, Snackbar, Stack, Box } from '@mui/material';
import GooglMap from 'components/GooglMap';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined as SearchOutlinedIcon, Close } from '@mui/icons-material';
import { createTravel, resetStatus, getUserList } from 'redux/actions/travel';
import "react-datepicker/dist/react-datepicker.css";
import { emptySpace } from '@jumbo/constant/ValidationRegex';
import { requiredMessage } from '@jumbo/constant/ErrorMessages';
import { useNavigate } from 'react-router';
import COLORS from 'utils/colors';
import { CustomPickerInput } from './CustomPickerInput'
import { CustomCheckedIcon } from './CustomCheckedIcon';
import AddConfirmDialog from '../../AddConfirmDialog';
import { Buttons } from '../../Button';
import { ButtonCancel } from '../../cancelButton';
import { USER_TYPE } from 'utils/commonHelper';

export default function AddTravel() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { addStatus, userList: allUsers } = useSelector(({ TRAVEL }) => TRAVEL)
    const [userOptions, setUserOptions] = useState([])
    const [arrivalTime, setArrivalTime] = useState('')
    const [allUser, setAllUser] = useState(false)
    const [user, setUser] = useState()
    const [userList, setUserList] = useState([])
    const [reason, setReason] = useState('')
    const [comment, setComment] = useState('')
    const [errors, setErrors] = useState({})
    const [showAlert, setShowAlert] = useState(false)
    const [placeID, setPlaceID] = useState('')
    const [destination, setDestination] = useState('')
    const [latlng, setLatLng] = useState({})
    const selectCheckboxRef = useRef(null)

    useEffect(() => {
        dispatch(getUserList({ user_type: USER_TYPE.END_USER, is_pagination: false }))
    }, [])

    useEffect(() => {
        if (allUsers && allUsers.userData && allUsers.userData.length > 0) {
            const opt = allUsers.userData.map((option) => ({ label: `${option.first_name} ${option.last_name}`, id: option._id }))
            setUserOptions(opt)
        }
    }, [allUsers])

    useEffect(() => {
        if (addStatus >= 200) {
            setShowAlert(true)
        }
    }, [addStatus])

    const onCloseAlert = () => {
        navigate('/app/travel')
        dispatch(resetStatus())
    }

    const onRegister = () => {
        let errorList = {}
        if (reason.match(emptySpace)) {
            errorList = { ...errorList, reason: requiredMessage }
        }

        if (!(allUsers.userData && allUsers.userData.length)) {
            errorList = { ...errorList, userList: 'User list is empty' }
        } else if (!allUser && userList.length === 0) {
            errorList = { ...errorList, userList: requiredMessage }
        }

        if (destination.match(emptySpace) || Object.keys(latlng).length === 0) {
            errorList = { ...errorList, destination: requiredMessage }
        }

        if (reason && destination && Object.keys(latlng).length > 0 && (allUser || userList.length > 0)) {
            setErrors({})
            const for_users = []
            userList.map((x) => for_users.push(x.id))
            { console.log("latlng", latlng) }
            const obj = {
                placeID,
                lat: latlng.lat || '',
                long: latlng.lng || '',
                for_users: allUser ? [] : for_users,
                reason,
                comment,
                arrival_time: Date.parse(arrivalTime)
            }
            dispatch(createTravel(obj))
        } else {
            setErrors(errorList)
        }
    }

    const onGoogleBoxChange = (e) => {
        console.log("e.target.value", e.target.value)
        removeError('destination')
        setDestination(e.target.value)
        if (e.target.value === "") {
            setLatLng({})
        }
    }

    const onRemoveAddress = () => {
        setDestination('')
        setLatLng({})
        document.getElementById("autocomplete-input").value = ''
    }

    const removeError = (field) => {
        const err = { ...errors }
        delete err[field]
        setErrors(err)
    }

    const onUserKeyDown = (e) => {
        removeError('userList')
        // 8 - backspace
        // 46 - delete
        if (e.keyCode === 8 || e.keyCode === 46) {
            setUser()
        }
    }

    const onUserChange = (e, newValue) => {
        // Enter and numerpadEnter = 13
        const idx = userOptions.findIndex(x => x.id === newValue.id)

        if (idx > -1) {
            removeError('userList')
            setUser(newValue)
        }
    }

    const onAddClick = () => {
        const list = [...userList]
        const idx = list.findIndex(x => x.id === user.id)
        if (idx === -1) {
            list.push(user)
            setUserList(list)
        }
        setUser()
    }

    const onCheckedChange = (e) => {
        removeError('userList')
        setUserList([])
        setUser()
        setAllUser(e.target.checked)
    }

    const onRemoveUserList = (index) => {
        const list = [...userList]
        list.splice(index, 1)
        setUserList(list)
    }

    const onPlaceChanged = (place, latlng, placeObj) => {
        console.log("placedata", placeObj, placeObj?.placeId)
        setPlaceID(placeObj?.placeId)
        setDestination(place)
        setLatLng(latlng)
    }

    const onReasonChange = (e) => {
        removeError('reason')
        setReason(e.target.value)
    }

    const renderError = (msg, style) => {
        return (
            <CustomErrorWrapper style={style}>
                <CustomError>{msg}</CustomError>
            </CustomErrorWrapper>
        )
    }

    const renderAlert = (msg) => {
        return (
            <Snackbar open={showAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
                <Alert onClose={() => setShowAlert(false)} severity="success" sx={{ width: '100%' }}>
                    Added Sucessfully
                </Alert>
            </Snackbar>
        )
    }

    const renderAutoCompleteInput = (params) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', padding: '5px 0 !important', borderBottom: errors.userList ? `1px solid ${COLORS.redtext}` : '1px solid rgb(229, 230, 233)' }}>
                <TextField {...params} style={{ padding: '10px 0px' }} placeholder={"For users*"} variant="standard" InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                }} />
                {user ? <Button variant="contained" style={{ ...AddButton, ...UserAddButton }} onClick={onAddClick}>
                    ADD</Button> : <SearchOutlinedIcon style={{ color: COLORS.grey }} />}
            </div>
        )
    }

    const renderTagList = () => {
        return (
            <Stack direction="row" style={{ display: 'flex', flexWrap: 'wrap', width: '60%' }} spacing={1}>
                {userList.map((data, index) => {
                    return (<CustomChip
                        variant="solid"
                        color="primary"
                        onDelete={() => onRemoveUserList(index)}
                        deleteIcon={<Close fontSize="sm" />}
                        label={data.label}
                        style={{ marginTop: '12px' }}
                        key={`userList-${index}`} />)
                })}
            </Stack>
        )
    }

    const renderCheckBox = () => {
        return (
            <div style={{ margin: '15px 0 3px -10px' }}>
                <Checkbox
                    ref={selectCheckboxRef}
                    checked={allUser}
                    onChange={onCheckedChange}
                    icon={<CustomCheckedIcon checked={allUser} />}
                    checkedIcon={<CustomCheckedIcon checked={allUser} />}
                />
                <CheckboxLabel onClick={() => onCheckedChange({ target: { checked: !allUser } })}>Select all users</CheckboxLabel>
            </div>
        )
    }

    const renderGoogleAutocomplete = () => {
        return (
            <GoogleInputWrapper>
                <GoogleInputContainer style={{ border: errors.destination ? `1px solid ${COLORS.redtext}` : '1px solid rgb(229,230,233)' }}>
                    <GoogleInputBox id="autocomplete-input"
                        type="text" placeholder="Search place*"
                        onChange={onGoogleBoxChange}
                        style={{ borderColor: errors.destination && COLORS.redtext }} />
                    {destination && <Close onClick={onRemoveAddress} style={CursorStyle} />}
                </GoogleInputContainer>
                <SearchOutlinedIcon style={{ marginLeft: '10px', color: COLORS.grey, ...CursorStyle }} />
            </GoogleInputWrapper>
        )
    }

    return (
        <AddTravelContainer>
            <MapHeader style={{ margin: '0 0 20px 0' }}>Create travel</MapHeader>
            {renderGoogleAutocomplete()}
            {errors.destination && renderError(errors.destination, { width: '50.5%' })}

            <MapHeader>Travel Users: </MapHeader>
            <GooglMap markerList={[{ lat_long: [latlng.lat, latlng.lng] }]} onPlaceChange={onPlaceChanged} addTravel={true} />

            <div>
                <UserInputContainer>
                    <div style={{ width: '90%', minWidth: '50%' }}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={userOptions}
                            renderInput={renderAutoCompleteInput}
                            disabled={allUser}
                            disableClearable
                            style={{ width: '100%', backgroundColor: allUser && '#EFEFEF4D' }}
                            value={user ? user.label : ''}
                            onChange={onUserChange}
                            onKeyDown={onUserKeyDown}
                        />
                        {errors.userList && renderError(errors.userList)}
                    </div>

                    <DatePicker
                        selected={arrivalTime}
                        onChange={(date) => { setArrivalTime(date) }}
                        customInput={<CustomPickerInput />}
                        showTimeSelect={true}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="dd MMMM yyyy HH:mm"
                    />
                </UserInputContainer>
                {renderCheckBox()}
                {renderTagList()}
            </div>

            <div>
                <MapHeader style={{ margin: '30px 0' }}>Travel Reason*</MapHeader>
                <CustomTextareaContainer style={{ border: errors.reason ? `1px solid ${COLORS.redtext}` : 'unset' }}>
                    <CustomTextarea
                        InputProps={{
                            disableUnderline: true,
                        }}
                        variant={"standard"}
                        multiline
                        minRows={3}
                        placeholder="enter text"
                        style={{ width: '100%' }}
                        value={reason}
                        error={errors.reason}
                        onChange={onReasonChange}
                    />
                </CustomTextareaContainer>
                {errors.reason && renderError(errors.reason, { width: '100%' })}
            </div>

            <div>
                <MapHeader style={{ margin: '30px 0' }}>Travel Comment</MapHeader>
                <CustomTextareaContainer>
                    <CustomTextarea
                        InputProps={{
                            disableUnderline: true,
                        }}
                        multiline
                        variant={"standard"}
                        minRows={3}
                        placeholder="enter text"
                        style={{ width: '100%' }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </CustomTextareaContainer>
            </div>
            {Object.keys(errors).length > 0 && <CustomError style={{ fontSize: '18px', marginTop: '20px' }}>{'*Please fill all required fields'}</CustomError>}
            <div style={AddTravelButtonContainer}>
                <ButtonCancel onClick={() => navigate(-1)}>Cancel</ButtonCancel>
                <Buttons onClick={onRegister} buttoncheck={'addButton'}>Register</Buttons>
            </div>
            <AddConfirmDialog
                open={showAlert}
                content={`Destination created successfully!`}
                btnLabels={{
                    ok: "ok",
                }}
                onClose={onCloseAlert}
            />
        </AddTravelContainer>
    )
}