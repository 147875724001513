import { ADD_USER, GET_ALL_USERS, SEC_SUBDIV, GET_USER_DETAILS, EDIT_SUBDIVISION_USER, CONNECTED_SUB_SIDEBAR, CONNECTED_SUBDIVISIONS, GET_SUBDIVISION_LIST } from "../../@jumbo/constant/ActionTypes";
import { addUsers, allUserList, getUserDetails, editSubdivision, DeleteTenant, subdivisionList, connectDisconnectSubDiv, ConnectedSubDivisions } from "../../@jumbo/constant/ApiConstant";
import axios from '../../helper/customAxios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS } from '../../@jumbo/constant/ActionTypes';
import { refreshToken } from "./Auth";


// orderBy, order, searchValue, activePage, activePerPage
export const getAllUsersList = (sortBy, sort, searchValue, activePage, activePerPage) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    // axios.defaults.headers.common['Authorization'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2E0NDM5ZDlmYTQ1MzI5NDhjOTI4YzMiLCJpYXQiOjE2NzIyMjYzMzQsImV4cCI6MTcwMzc4MzkzNH0._o7BHMOAhlRbyLL52ofCdy0ArJ8rDUwYsgofq2VCZI8";
    dispatch(fetchStart());
    axios.get(allUserList, {
      params: {
        user_type: 4, page: activePage, limit: activePerPage, sortBy: `${sortBy}:${sort}`, q: searchValue, is_pagination: true,
      },
    })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status === 200) {
          if (!data.data.data.userData.length) {
            dispatch({ type: GET_ALL_USERS, payload: [] });
          } else {
            dispatch({ type: GET_ALL_USERS, payload: data.data.data });
            dispatch(fetchSuccess(data.message));
          }

          // dispatch({ type: GET_ALL_USERS, payload: data.data.data });
        } else {

          dispatch({ type: GET_ALL_USERS, payload: [] });
          dispatch(fetchError(data.message));
          dispatch({ type: FETCH_SUCCESS, loading: false });

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getAllUsersList(sortBy, sort, searchValue, activePage, activePerPage))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          // alert('null');
          // dispatch(setAuthUser(null));
          // history.push('/signin')
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });

  };
};

export const getCurrUserDetail = (id) => {
  return dispatch => {
    dispatch(fetchStart());
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    axios.get(getUserDetails, {
      params: {
        user_id: id,

      },
    })
      .then(data => {
        if (data.status == 200) {
          dispatch({ type: GET_USER_DETAILS, payload: data.data.data });
        } else {
          dispatch({ type: GET_USER_DETAILS, payload: [] });
        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
      })
    // .catch(error => {
    //   if(error.response.status===401 ){
    //     // dispatch(setAuthUser(null));
    //     // history.push('/signin')
    //   } 
    //   else {
    //     // dispatch(fetchError(error?.response?.data?.message));
    //   }
    // });

  };
};

export const addSubdivision = (adduser, callbackFun) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .post('v1/users/add-user', adduser)
      .then(data => {

        if (data) {
          if (callbackFun) {
            callbackFun.setAddDialog();
            dispatch({ type: FETCH_SUCCESS, loading: false });
          }
          setTimeout(() => {
            dispatch(fetchSuccess('Sub division added successfully.'));
          }, 4000);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
          dispatch({ type: ADD_USER, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addSubdivision(adduser, callbackFun))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
      });
  };
};


export const updateSubdivision = (user, callbackFun) => {
  return dispatch => {

    dispatch(fetchStart());
    axios
      .put(editSubdivision, user)
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data) {

          if (callbackFun) callbackFun.setAddDialog();
          // dispatch(fetchSuccess('ghdfhjg'));
          dispatch({ type: FETCH_SUCCESS, loading: false });

          setTimeout(() => {
            dispatch(fetchSuccess('Subdivision updated successfully .'));
          }, 4000);
        }

        else {
          dispatch(fetchError('There was something issue in responding server.'));
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }

        //   if (callbackFun) callbackFun();
        // dispatch({ type: EDIT_SUBDIVISION_USER, payload: data.data.data });

      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(updateSubdivision(user, callbackFun))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const deleteSubdivision = (userId, callbackFun, list) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .get(DeleteTenant + userId)
      .then((data) => {
        if (data.status === 200) {
          setTimeout(() => {
            if (callbackFun) callbackFun.setAddDialog();
          }, 1000);
          dispatch({ type: FETCH_SUCCESS, loading: false });

          dispatch(list());
          setTimeout(() => {
            dispatch(fetchSuccess("Sub division deleted Sucessfully."));
          }, 4000);
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(deleteSubdivision(userId, callbackFun, list))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const getSubdivisionList = (tenants) => {
  let URL
  if (tenants) {
    URL = `${subdivisionList}?tenants=${tenants}`
  } else {
    URL = subdivisionList
  }
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .get(URL)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: GET_SUBDIVISION_LIST, payload: data?.data?.data })
          dispatch({ type: FETCH_SUCCESS, loading: false });
        } else {
          dispatch({ type: GET_SUBDIVISION_LIST, payload: [] })
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getSubdivisionList(tenants))
          }))
          return;
        }
        dispatch({ type: GET_SUBDIVISION_LIST, payload: [] })
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const connectDisconnectSub = (subDivUser, onClose, redirect) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .put(connectDisconnectSubDiv, subDivUser)
      .then((data) => {
        if (data.status === 200) {
          onClose();
          setTimeout(() => redirect(), 500);
          dispatch({ type: FETCH_SUCCESS, loading: false });
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(connectDisconnectSub(subDivUser, onClose, redirect))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        }
      });
  };
};


export const getConnectedSubdivisionList = (sortBy, sort, searchValue, activePage, activePerPage, tenants) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    // axios.defaults.headers.common['Authorization'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2E0NDM5ZDlmYTQ1MzI5NDhjOTI4YzMiLCJpYXQiOjE2NzIyMjYzMzQsImV4cCI6MTcwMzc4MzkzNH0._o7BHMOAhlRbyLL52ofCdy0ArJ8rDUwYsgofq2VCZI8";
    dispatch(fetchStart());
    axios.get(ConnectedSubDivisions, {
      params: {
        page: activePage, limit: activePerPage, sortBy: (sortBy && sort) ? `${sortBy}:${sort}` : "", tenants: tenants, search: searchValue
      },
    })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status === 200) {
          if (!data.data.data.data.length) {
            dispatch({ type: CONNECTED_SUBDIVISIONS, payload: [] });
          } else {
            dispatch({ type: CONNECTED_SUBDIVISIONS, payload: data.data.data });
            dispatch(fetchSuccess(data.message));
          }
        } else {
          dispatch({ type: CONNECTED_SUBDIVISIONS, payload: [] });
          dispatch(fetchError(data.message));
          dispatch({ type: FETCH_SUCCESS, loading: false });

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getConnectedSubdivisionList(sortBy, sort, searchValue, activePage, activePerPage, tenants))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          // alert('null');
          // dispatch(setAuthUser(null));
          // history.push('/signin')
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });

  };
};

export const getConnectedSubdivisionListSidebar = (sortBy, sort, searchValue, activePage, activePerPage, tenants) => {
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    // axios.defaults.headers.common['Authorization'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2E0NDM5ZDlmYTQ1MzI5NDhjOTI4YzMiLCJpYXQiOjE2NzIyMjYzMzQsImV4cCI6MTcwMzc4MzkzNH0._o7BHMOAhlRbyLL52ofCdy0ArJ8rDUwYsgofq2VCZI8";
    dispatch(fetchStart());
    axios.get(ConnectedSubDivisions, {
      params: {
        page: activePage, limit: activePerPage, sortBy: `${sortBy}:${sort}`, tenants: tenants, q: searchValue, is_pagination: true,
      },
    })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status === 200) {
          if (!data.data.data.data.length) {
            dispatch({ type: CONNECTED_SUB_SIDEBAR, payload: [] });
          } else {
            dispatch({ type: CONNECTED_SUB_SIDEBAR, payload: data.data.data });
            dispatch(fetchSuccess(data.message));
          }
        } else {
          dispatch({ type: CONNECTED_SUB_SIDEBAR, payload: [] });
          dispatch(fetchError(data.message));
          dispatch({ type: FETCH_SUCCESS, loading: false });

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getConnectedSubdivisionListSidebar(sortBy, sort, searchValue, activePage, activePerPage, tenants))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          // alert('null');
          // dispatch(setAuthUser(null));
          // history.push('/signin')
          // window.location='/';
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));

        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });

  };
};

export const setSecSubdivisionId = (id) => {
  return dispatch => {
    dispatch({
      type: SEC_SUBDIV, payload: id
    });
  };
};