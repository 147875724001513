// import { lighten, makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     // width: '100%',
//   },
//   paper: {
//     width: '100%',
//     marginBottom: theme.spacing(4),
//     backgroundColor: lighten(theme.palette.background.paper, 0.1),
//   },
//   container: {
//     maxHeight: 415,
//   },
//   table: {
//     minWidth: 750,
//     '& .MuiTableCell-head': {
//       fontWeight: 600,
//     },
//   },
//   visuallyHidden: {
//     border: 0,
//     clip: 'rect(0 0 0 0)',
//     height: 1,
//     margin: -1,
//     overflow: 'hidden',
//     padding: 0,
//     position: 'absolute',
//     top: 20,
//     width: 1,
//   },
// }));

// export default useStyles;

import styled from "styled-components";
import COLORS from "utils/colors";

export const Button = styled.div`
background-color: rgb(72, 146, 195);
margin: 0px;
color: white;
 box-shadow:  rgb(98 132 152 / 33%) 0px 11px 26px -10px;
border: 0px;
cursor: pointer;
outline: none;
right: 0px;
font-family: SFTextLight;
display: flex;
-webkit-box-pack: center;
justify-content: center;
-webkit-box-align: center;
align-items: center;
border-radius: 5px;
height: 40px;
font-size: 15px;
margin-top: 20px;
width: 300px;
`;

export const Container0 = styled.div`
float: left;
width: 100%;
margin: opx 0px;
max-width: sm;

`;

export const Container1 = styled.div`
display: flex;
   -webkit-box-pack: justify;
   justify-content:  space-between;
   align-items:  flex-start;
   flex-direction: row;
   padding: 0px;
`;
export const Container2 = styled.div`
display: flex;
-webkitB-box-pack: justify;
justify-content: space-between;
margin-right: 10px;
`;
export const Container3 = styled.span`
font-family: SFTextSemibold;
text-align: left;
margin-left:13px;
margin-top: 20px;
margin-bottom: 35px;
font-size: 22px;
width: 100%;
font-weight: 600;
color: rgb(92, 99, 115);
`;


export const Button1 = styled.div`
margin-right: 40px;
background-color: transparent;
cursor: pointer;
border: 0px;
outline: 0px;
box-shadow: none;
color:  rgb(95, 170, 176);
font-size: 12px;
padding: 0px;
`;

export const Button2 = styled.div`
margin-right: 40px;
background-color: transparent;
cursor: pointer;
border: 0px;
outline: 0px;
box-shadow: none;
color:  rgb(225, 108, 108);
font-size: 12px;
padding: 0px;
`;

// export const InputField = styled.input`
//   font-family: SFTextLight;
//   font-size: 15px;
//   width: 100%;
//   height: 36px;
//   border: 1px solid ${(props) => (props.error ? COLORS.red : COLORS.greyborder)};
//   &::placeholder {
//     color: ${COLORS.lightgrey};
//   }
//   &::MuiFormHelperText-root {
//     color: 'red'
//   },
//   border-radius: 5px;
//   outline: none;
//   box-shadow: none;
//   padding: 0 10px;
//   &:focus {
//     border: 1px solid ${COLORS.greytext};
//   }
// `;

export const helperText = styled.p`

  color: 'red'

`;

export const Form = styled.form`
  width: 50%;
  margin-left: -25px;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  right: -23px;
  bottom: -15px;
  font-size: 14px;
  color: ${COLORS.red};
`;

