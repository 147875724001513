import * as React from "react";
import Slider, { SliderProps } from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";
import NotificationsNone from "@mui/icons-material/NotificationsNone";
// import styled from "styled-components";


// export const TextFields = styled(TextField)(({ theme }) => ({
//     "& .MuiOutlinedInput-root": {
//         "& fieldset": {
//             borderColor: "rgba(0, 0, 0, 0.23)" // default
//         },
//         "&.Mui-focused fieldset": {
//             border: "2px solid red" // customized
//         }
//     },
  
//   background: "none",
//   // borderBottom: "1px solid rgba(174, 180, 192, 0.24)",
//   display: "flex",
//   width:'100%',
  
//   font: "inherit",
// //   color: "rgb(144, 153, 170)",
//   textTransform: "capitalize",
//   fontSize: '15px',
//     fontWeight:300,
//     marginTop: '0',
//     '& .MuiFormLabel-root': {
//       color: 'rgb(144, 153, 170)',
      
//       fontWeight: 300,
//       letterSpacing: '0.01071em',

//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//       color: "rgb(144, 153, 170)",
//     },
    
    
//     "& .MuiFormHelperText-root": {
//       color: 'red'
      
//     },
//     "& .MuiInputBase-input":{
//       borderBottom: "1px solid rgba(174, 180, 192, 0.24)", 
//     },
//     '& .MuiFilledInput-underline':{
//         color:'green'
//     },

    

// }));

// export const TextFields = styled(TextField)(({ theme }) => ({
//     background: "none",
//     display: "flex",
//     width: '100%',
//     font: "inherit",
//     textTransform: "capitalize",
//     fontSize: '15px',
//     fontWeight: 300,
//     marginTop: '0',
//     // "& .MuiOutlinedInput-root": {
//     //     "& fieldset": {
//     //         borderColor: "rgba(0, 0, 0, 0.23)" // default
//     //     },
//     //     "&.Mui-focused fieldset": {
//     //         border: "2px solid red" // customized
//     //     }
//     // },
//     '& .MuiFormLabel-root': {
//         color: 'rgb(144, 153, 170)',

//         fontWeight: 300,
//         letterSpacing: '0.01071em',

//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//         color: "rgb(144, 153, 170)",
//     },
//     "& .MuiFormHelperText-root": {
//         color: 'red'
//     },
//     "& .MuiInputBase-input": {
//         // borderBottom: "1px solid rgba(174, 180, 192, 0.24)",
//         borderBottom: "1px solid red",
//     },
//     // '& .MuiFilledInput-underline': {
//     //     color: 'green'
//     // },
//     // '& .MuiInput-underline:after': {
//     //     borderBottomColor: 'rgb(144, 153, 170)',
//     // },
// }));

export const TextAreas = styled((props) => (
    // <div style={{ padding: '5px 0 !important',  width: '80%' ,position:'relative'}}>
        <TextField
        variant={"outlined"}
        InputProps={{ disableUnderline: false }} 
        style={{width: '100%', marginTop: '0',}} {...props} />
    // </div>
))(({ theme }) => ({
    "& .MuiInputBase-input": {
         border: "1px solid transparent",
        //  borderRadius: "6px",
         borderColor: 'none',
         fontSize: '12px'
        //  height: "100px"
        // borderBottom: "1px solid rgba(174, 180, 192, 0.24)",
        // borderBottom: "1px solid red",
    },
    // borderBottom: '1px solid rgb(229, 230, 233)',
    '& .MuiFormLabel-root': {
        color: 'rgb(144, 153, 170)',
        fontWeight: 200,
        letterSpacing: '0.01071em',
        fontSize:'16px'
     },
    //  '& .MuiFormLabel-root.Mui-focused': {
    //     color: 'none',
    //     // fontWeight: 300,
    //     letterSpacing: '0.01071em',
    //  },
     "& .MuiFormHelperText-root": {
              color: 'red'
              
            },
}));