import * as moment from "moment"
import COLORS from "./colors"
const user = JSON.parse(localStorage.getItem('profile'));
export const ALERT_TYPE = {
    'TRIGGERED_ALERT': 1,
    'DELAYED_ALERT': 2,
    'IN_HOUSE_ALERT': 3,
    'TRAVEL_ALERT': 4
}
export const CHECKLIST_TYPE = {
    ALERT: {
        title: 'alert',
        value: 1,
        tab: 0
    },
    TRAVEL: {
        title: 'travel',
        value: 3,
        tab: 1
    },
    IN_HOUSE: {
        title: 'in house',
        value: 2,
        tab: user?.has_access_to?.alert_travel ? 2 : 0,
    },
}

export const REVERSED_ALERT_TYPE = {
    1: 'Triggered alert',
    2: 'Delayed alert',
    3: 'In house'
}

export const ALERT_STATUS = {
    'RAISED': 1,
    'IN_PROGRESS': 2,
    'COMPLETED': 3,
    'CANCEL': 4
}

export const REVERSED_ALERT_STATUS = {
    1: 'New',
    2: 'In progress',
    3: 'Completed',
    4: 'Cancel'
}

export const COLOR_LIST = {
    'new': COLORS.red,
    'in progress': COLORS.green,
    'completed': COLORS.grey,
    'triggered alert': COLORS.red,
    'delayed alert': COLORS.green,
    'in house': COLORS.green
}

export const timeHelper = (value) => {
    const tempTime = moment.duration(value);
    return tempTime.hours() > 0 ? `${tempTime.hours()} hours, ${tempTime.minutes()} min` : `${tempTime.minutes()} min`
}