import { SET_CHECKLIST } from "@jumbo/constant/ActionTypes";

const INITIAL_STATE = {
    CheckList: [],
    inCompleteCOunt: 0
}
const Checklist = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CHECKLIST:
            return action.payload;
        default:
            return state;
    }
}

export default Checklist;