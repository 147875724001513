import { Buttons } from 'modules/Button';
import { TextFields } from 'modules/Textfeild';
import React from 'react'
import Modal from "components/common/modal";
import { TextAreas } from 'modules/TextAreas';
import ConfirmDltDialog from 'modules/ConfirmDltDialog';
import { useDispatch, useSelector } from "react-redux";
import { deleteActionOfFocusBoard } from 'redux/actions/focusBoard';
import { useParams } from 'react-router';

const Delete = ({ openDeleteDialog, setOpenDeleteDialog, particulerRowId, type }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    console.log("type", type)

    const handleConfirmDelete = () => {
        const datas = {
            action_id: particulerRowId,
            isForTravel: type === 3 ? true : false
        }
        dispatch(deleteActionOfFocusBoard(id, datas))
        setOpenDeleteDialog(false)
    }
    return (
        <ConfirmDltDialog
            open={openDeleteDialog}
            title='Confirm Delete'
            content={`Are you sure you want to delete this action?`}
            btnLabels={{
                no: "No",
                Yes: "Yes",
            }}
            onClose={() => setOpenDeleteDialog(prev => !prev)}
            onConfirm={handleConfirmDelete}
        />
    )
}

export default Delete;