import ReactTable from 'components/common/table/ReactTable';
import React, { useEffect, useState } from 'react'
import Add from './Add';
import Completed from './Completed';
import Delete from './Delete';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckList } from 'redux/actions/checklist';
import { useParams } from 'react-router';
import { USER_TYPE } from 'utils/commonHelper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Buttons } from 'modules/Button';

const CheckList = ({ isCommonChecklist = false, alertType, openAddChecklistBox, isCompleted }) => {
    const data = useSelector(store => store?.CHECKLIST);
    const userProfile = useSelector(store => store?.USERPROFILE?.currentuser?.detail);
    const { secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
    const { currentuser: currentUser } = useSelector(({ USERPROFILE }) => USERPROFILE);
    console.log("currentuser 11111", currentUser?.detail?.user_type);
    const [actionId, setActionId] = useState(null);
    const [editMode, setEditMode] = useState(false)
    const [updateChecklistItem, setUpdateChecklistItem] = useState();
    const [showTooltip, setShowTooltip] = useState(false);
    const columns = [
        {
            id: 'action', title: 'Action', accessor: 'action', align: 'left',
            Component: ({ row }) => {
                return (
                    <>
                        <span className={'font-bold'}>{row?.action}</span>
                    </>
                );
            }
        },
        {
            id: 'actions',
            title: 'Actions',
            accessor: 'isCompleted',
            Component: ({ row }) => {
                return (
                    <>

                        <span className={`underline mr-5 text-green-600 ${(!isCommonChecklist && !row?.isCompleted) ? 'visible' : 'invisible'} ${isCompleted ? 'cursor-not-allowed opacity-100' : 'cursor-pointer '}`} onClick={() => {
                            if (!isCompleted && (!isCommonChecklist && !row?.isCompleted)) {
                                setOpenCompleteDialog(prev => !prev);
                                setActionId(row?.action_id);
                            }
                        }}>
                            Complete
                        </span>
                        {!row?.isCompleted && ([USER_TYPE.SEC_CONTROLLER].includes(userProfile?.user_type) || [USER_TYPE.EXTERNAL_CONTROLLER].includes(userProfile?.user_type) || [USER_TYPE.SUBDIVISION_USER].includes(userProfile?.user_type))
                            && <span className={`underline mr-5 text-gray-600 ${isCompleted ? 'cursor-not-allowed opacity-100' : 'cursor-pointer '}`} onClick={() => {
                                if (!isCompleted) {
                                    setUpdateChecklistItem(row);
                                    setOpenDialog(true);
                                    // setEditMode(true)
                                }
                            }}>
                                Edit
                            </span>}
                        {([USER_TYPE.SUBDIVISION_USER].includes(userProfile?.user_type) || [USER_TYPE.EXTERNAL_CONTROLLER].includes(userProfile?.user_type) || [USER_TYPE.SEC_CONTROLLER].includes(userProfile?.user_type))
                            && <span className={`underline text-red-600 ${isCompleted ? 'cursor-not-allowed opacity-100' : 'cursor-pointer '}`} onClick={() => {
                                if (!isCompleted) {
                                    setOpenDeleteDialog(prev => !prev);
                                    setActionId(row?.action_id);
                                }
                            }}>
                                Delete
                            </span>}
                    </>
                );
            }
        },
        // {
        //     id: 'user',
        //     title: 'User',
        //     accessor: 'user',
        //     Component: ({ row }) => {
        //         return (
        //             <>
        //                 {row?.addedByUserType ?
        //                     <Tooltip title={row.addedByUserType === 2 ? "Super External Controller" : "Subdivision"} arrow placement="top">
        //                         <Button type='button' variant="contained"
        //                             sx={{
        //                                 color: '#FFFFFF', bgcolor: 'rgb(72, 146, 195);',
        //                                 borderRadius: "20px",
        //                                 height: "20px",
        //                                 '&:hover': {
        //                                     bgcolor: 'rgb(72, 146, 195)', // Custom primary hover color
        //                                 },
        //                             }}>{row.addedByUserType === 2 ? "SEC" : "SB"}</Button>
        //                     </Tooltip> : "-"}
        //             </>
        //         );
        //     }
        // },
    ];
    const setOpenAddDialogHandler = (value) => {
        setOpenDialog(value);
    }
    useEffect(() => {
        if (openAddChecklistBox) {
            openAddChecklistBox.current = setOpenAddDialogHandler;
        }
    }, []);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCompleteDialog, setOpenCompleteDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    return (
        <>
            {(data?.checklist && data?.checklist?.length > 0) ? <ReactTable
                COLUMNS={columns}
                DATA={data?.checklist}
            /> :
                <div className='border-b-2 border-t-2 py-4 text-[#5c6373]'>There are no action to display</div>
            }
            {(!isCommonChecklist && [USER_TYPE.SUBDIVISION_USER].includes(userProfile?.user_type) || !isCommonChecklist && [USER_TYPE.CONTROL_USER].includes(userProfile?.user_type) || !isCommonChecklist && [USER_TYPE.EXTERNAL_CONTROLLER].includes(userProfile?.user_type) || !isCommonChecklist && [USER_TYPE.SEC_CONTROLLER].includes(userProfile?.user_type)) && <div className={`underline text-[#4892c3] mt-3 ${isCompleted ? 'cursor-not-allowed opacity-50' : 'cursor-pointer '}`} onClick={() => { if (!isCompleted) { setOpenDialog(prev => !prev) } }}>Add checklist item</div>}
            <Add openDialog={openDialog} setOpenDialog={setOpenDialog} type={alertType?.value} isCommonChecklist={isCommonChecklist} subdivisionId={secSubdivisionUser} updateChecklistItem={updateChecklistItem} setUpdateChecklistItem={setUpdateChecklistItem} currentUser={currentUser} setEditMode={setEditMode} editMode={editMode} />
            <Completed openCompleteDialog={openCompleteDialog} setOpenCompleteDialog={setOpenCompleteDialog} type={alertType?.value} actionId={actionId} subdivisionId={secSubdivisionUser} />
            <Delete openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} type={alertType?.value} actionId={actionId} subdivisionId={secSubdivisionUser} />
        </>
    )
}

export default CheckList;