export const requiredMessage = 'This field is required!';
export const emailNotValid = 'Email is not valid!';
export const passwordMisMatch = 'New Password and Confirm Password did not match!';
export const passwordRulesMisMatch = 'Password does not follow under written rules!';
export const passwordMessage = 'Password must contain at least eight characters ,at least one number and both upper and lowercase letters and special character!'
export const phoneOnlyNumericals = 'Phone number should be +XXXXXXXXXXXX formate!';
export const noSpaceMessage = 'No spaces allowed!';
export const lettersOnlyMessage = 'Only letters allowed!'
export const alphaNumaricMessage = 'Only letters and number allowed!'
export const onlyNumberNotAllow = 'Only numbers not allowed!'
export const onlyNumberAllow = 'Allow only number!';
export const onlyUrl = 'Wrong input! Only url';

export const validationMsg = {
    focusBoard: {
        action: 'Action is required.',
        responsibleUser: "User name is required.",
        estTime: "Estimate time is required.",
        comment: "Comment is required.",
    }
}
