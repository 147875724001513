import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Loader";
import {
  CrisisContainer,
  DetailHeader,
  UserNameContainer,
  TravelIdContainer,
  NameTravelIDBox,
} from "../../style";
import CrisisLog from "./CrisisLog";
import { getAlertDetail } from "redux/actions/CrisisLogAlert";
import { useParams } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ALERT_STATUS, ALERT_TYPE, CHECKLIST_TYPE } from "utils/alertHelper";
import { addAlertComment } from "redux/actions/CrisisLogAlert";
import LogActivity from "../../logActivity";
import AddLogMessage from "modules/CrisisLog/logActivity/logMessage";

export default function Detail() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const printHeadRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const { alertDetails } = useSelector(({ CRISISLOGALERT }) => CRISISLOGALERT);

  useEffect(() => {
    dispatch(getAlertDetail(id, setLoader));
  }, []);

  return (<>

    {!!alertDetails && alertDetails.alert && alertDetails.alert.test_mode ? <div className="test-mode">Test Mode</div> : null}
    <CrisisContainer>


      <div ref={printHeadRef} id="hello" className="mb-4">

        <DetailHeader>

          Crisis log, Ongoing events</DetailHeader>
        <NameTravelIDBox>
          <UserNameContainer>
            {/* <Bullet style={{ backgroundColor: "#5FAAB0" }} /> */}
            <FiberManualRecordIcon style={{ fontSize: "10px", color: 'rgb(95, 170, 176)', marginRight: '5px' }} />
            User:{" "}
            {/* {!!alertDetails ? alertDetails.alert.userName:''} */}
            {!!alertDetails && alertDetails.alert ? alertDetails.alert.userName : null}

          </UserNameContainer>

          <UserNameContainer>
            {/* <Bullet style={{ backgroundColor: "rgb(225, 108, 108)" }} />{" "} */}
            <FiberManualRecordIcon style={{ fontSize: "10px", color: 'rgb(225, 108, 108)', marginRight: '5px' }} />
            {/* {alertDetails &&  ALERT_TYPE.TRIGGERED_ALERT === alertDetails.alert.alertType ?' TRIGGERED_ALERT'
            :ALERT_TYPE.DELAYED_ALERT === alertDetails.alert.alertType?'DELAYED_ALERT':''} */}




            {alertDetails && alertDetails.alert ? alertDetails && alertDetails.alert.alertType == 1 ? ALERT_TYPE.TRIGGERED_ALERT === alertDetails.alert.alertType ? ' Triggered alert' : '' : '' : ''}
            {alertDetails && alertDetails.alert ? alertDetails && alertDetails.alert.alertType == 2 ? ALERT_TYPE.DELAYED_ALERT === alertDetails.alert.alertType ? 'Delayed alert' : ''
              : '' : ''}
          </UserNameContainer>

          <TravelIdContainer>
            Alert ID:
            <b style={{ marginLeft: '4px' }}>{alertDetails && alertDetails.alert ? alertDetails && alertDetails.alert.id : ''}</b>

          </TravelIdContainer>
        </NameTravelIDBox>
        <DetailHeader style={{ display: 'none' }} className="capitalize">{CHECKLIST_TYPE?.ALERT.title}Logs</DetailHeader>
      </div>
      <LogActivity addLogAPI={addAlertComment} alertType={CHECKLIST_TYPE.ALERT} isCompleted={alertDetails?.alert?.alertStatus == ALERT_STATUS?.COMPLETED} setActiveTab={setActiveTab} activeTab={activeTab} />
      {activeTab === 0 && <CrisisLog id={id} navigate={navigate} printHeadRef={printHeadRef} />}
    </CrisisContainer>
  </>
  );
}
