import styled, { css } from 'styled-components';
// import COLORS from '../../../configs/colors.config';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${props => props.absolute && css`
    position: absolute;
    right: 0;
    top: -50px;
  `}
  ${props => props.top && css`
    top: ${props.top};
  `}
`;

export const List = styled.ul`
  display: flex;
  padding:0;
  margin-bottom: 0;
  li:last-child {
    margin: 0;
  }
`;

export const Item = styled.li`

  cursor: pointer;
  font-size: 13px;
  margin-right: 40px;
  
  color: #9099AA;
  ${props => props.activeItem === props.idx && css`
    color: #4E91C0;
    border-bottom: 1px solid #4E91C0;
  `}
`;

export const Text = styled.span`
  font-size: 13px;
  color: #5C6373;
  margin-right: 20px;
`;
