import { GET_ALL_USERS ,ADD_USER,GET_USER_DETAILS,EDIT_SUBDIVISION_USER,GET_SUBDIVISION} from "../../@jumbo/constant/ActionTypes";
  
  const INIT_STATE = {
    allExternalUsers: [], 
    subdivison:[],
    currentuser:null 
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ALL_USERS: {
        return {
          ...state,
          allExternalUsers: action.payload,
        };
    }
    case GET_SUBDIVISION: {
      return {
        ...state,
        subdivison: action.payload,
      };
  }
    case ADD_USER: {
      return {
        ...state,
        allExternalUsers: [action.payload, ...state.allExternalUsers],
    };
   }
   case  GET_USER_DETAILS: {
    return {
      ...state,
      currentuser: action.payload,
  };
}
  case EDIT_SUBDIVISION_USER: {
    return {
      ...state,
      allExternalUsers: state.allExternalUsers.map(allExternalUsers => (allExternalUsers.id === action.payload._id ? action.payload : allExternalUsers)),
    };
  
 }
      default:
        return state;
    }
  };
  