import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';

import { styled } from "@mui/material/styles";
const useStyles = styled((theme) => ({
  dialogRoot: {
    // position: 'relative',
    // width:'600px',
    // minHeight:'1000px'

  },
  dialogActionsRoot: {
    padding: '16px',
    marginLeft:'20px',
    align:'center'
  },
}));

const ConfirmDialog = ({ open, onClose, onConfirm, title, content, btnLabels }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} className={classes.dialogRoot}>  
      <DialogContent   style={{width:'320px',height:'150px', margin:'10px 10px 10px 10px'}}>
        <DialogContentText style={{margin:'10px 10px 10px 40px',fontSize:'18px'}}>{content}
        </DialogContentText>
     
        <Button onClick={onClose} color="primary" variant="contained" style={{margin:'45px 0px 10px 25%',width:'150px'}}>
          {btnLabels.ok}
        </Button>
        
      </DialogContent>
     
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  open: false,
  title: 'Confirm Delete',
  btnLabels: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
};

export default React.memo(ConfirmDialog);
