import React, { useState, useEffect } from "react";
import { CustomTab, CustomTabs } from 'components/CustomTab/index'
import { AlertContainer, HeaderText, HeaderSubText, EventCountText, HeaderContainer, AmountItemsContainer, AddLocationBtnContainer } from './style'
import AmountItems from "../AmountItems/AmountItems";
import { useDispatch, useSelector } from "react-redux";
import { getAlertList, getInhouseLocList, isInHouseEdit } from "redux/actions/alert";
import { ALERT_STATUS, ALERT_TYPE } from "utils/alertHelper";
import InHouseGrid from "./InHouseGrid";
import InHouseLocationGrid from "./InHouseLocationGrid";
import { Buttons } from "../Button";
import { useNavigate } from "react-router";
import { Add as AddIcon } from '@mui/icons-material'
import { USER_TYPE } from "utils/commonHelper";
import { getUserList } from "redux/actions/travel";
import { FilterButton } from "components/FilterButton";
import { getDefaultPoliceNumberList } from 'redux/actions/DefaultPoliceNumber'

const TabPanel = ({ index, children, tabIndex }) => {
    return (
        <div
            role="tabpanel"
            hidden={tabIndex !== index}
            id={`tabpanel-${index}`} style={{ paddingTop: 0 }}>
            {tabIndex === index && children}
        </div>
    )
}

export default function InHouse() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let inHouse = localStorage.getItem("inHousetab")
    let alertTab = localStorage.getItem("alertTab")
    const [tabIndex, setTabIndex] = useState(inHouse ? inHouse : alertTab ? alertTab : 0)
    const [activePerPage, setActivePerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [subdivUser, setSubdivUser] = useState([])
    const [filterList, setFilterList] = useState([])

    const alert_type = `${ALERT_TYPE.IN_HOUSE_ALERT}`
    const currentUser = JSON.parse(localStorage.getItem('profile'))
    const tanent_user = currentUser.user_type === USER_TYPE.TENANT_USER
    const subdiv_user = currentUser.user_type === USER_TYPE.SUBDIVISION_USER

    const { userList: allUsers } = useSelector(({ TRAVEL }) => TRAVEL)
    const { inHouseNewListRec, inHouseProgressListRec, inHouseList } = useSelector(({ ALERT }) => ALERT)
    const { secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);

    // useEffect(() => {
    //     dispatch(getDefaultPoliceNumberList())
    // }, [])


    useEffect(() => {
        if (inHouse) {
            setTabIndex(Number(inHouse))
        }
        if (alertTab) {
            setTabIndex(Number(alertTab))
        }
        dispatch(getInhouseLocList({ subdivisions: secSubdivisionUser }));
    }, [inHouse, alertTab])

    useEffect(() => {
        if (tabIndex === 0) {
            localStorage.setItem("tab", tabIndex)
            dispatch(getAlertList({ status: ALERT_STATUS.RAISED, alert_type, page: 1, limit: 10, subdivisions: secSubdivisionUser }))
            tanent_user && dispatch(getUserList({ user_type: USER_TYPE.SUBDIVISION_USER, is_pagination: false }))
        } else {
            localStorage.setItem("tab", tabIndex)
            dispatch(getAlertList({ status: ALERT_STATUS.IN_PROGRESS, alert_type, subdivisions: secSubdivisionUser }))
            tanent_user && dispatch(getUserList({ user_type: USER_TYPE.SUBDIVISION_USER, is_pagination: false }))
        }
    }, [])

    useEffect(() => {
        if (allUsers && allUsers.userData && allUsers.userData.length > 0) {
            const opt = allUsers.userData.map((option) => ({ label: `${option.first_name} ${option.last_name}`, id: option._id }))
            setSubdivUser(opt)
        }
    }, [allUsers])

    const renderGrid = () => {
        return <InHouseGrid tabIndex={tabIndex} activePage={activePage} setActivePage={setActivePage} activePerPage={activePerPage} />
    }

    const renderGridLocation = () => {
        return <InHouseLocationGrid />
    }

    const tabList = [
        { title: `In house new (${inHouseList && inHouseList.totalNewAlerts ? inHouseList.totalNewAlerts : '0'})`, element: renderGrid() },
        { title: `In house in progress (${inHouseList && inHouseList.totalInProgressAlerts ? inHouseList.totalInProgressAlerts : '0'})`, element: renderGrid() },
        { title: `In house location`, element: renderGridLocation() },
    ]
    // inHouseList?.userAlerts?.map((data) => data?.status === 2 !== true)

    const onAddLocation = () => {
        dispatch(isInHouseEdit(false))
        navigate('/app/Inhouse/add-location')
    }

    const onTabChange = (e, newValue) => {
        setTabIndex(newValue)
        localStorage.setItem("tab", newValue)
        setActivePage(1)
        setActivePerPage(10)
        setFilterList([])
        tanent_user && newValue !== 2 && dispatch(getUserList({ user_type: USER_TYPE.SUBDIVISION_USER, is_pagination: false }))
        let params = { alert_type, page: 1, limit: 10 }
        if (secSubdivisionUser) params = { alert_type, page: 1, limit: 10, subdivisions: secSubdivisionUser }
        if (newValue === 0) {
            dispatch(getAlertList({ status: ALERT_STATUS.RAISED, ...params }))
        } else if (newValue === 1) {
            dispatch(getAlertList({ status: ALERT_STATUS.IN_PROGRESS, ...params }))
        } else if (newValue === 2) {
            dispatch(getInhouseLocList({ subdivisions: secSubdivisionUser }));
        }
    }

    const changePerPage = (activePerPage) => {
        setActivePerPage(activePerPage);
        let params = { alert_type, page: 1, limit: activePerPage }
        if (secSubdivisionUser) params = { alert_type, page: 1, limit: 10, subdivisions: secSubdivisionUser }
        if (tabIndex === 0) {
            dispatch(getAlertList({ status: ALERT_STATUS.RAISED, ...params }))
        } else if (tabIndex === 1) {
            dispatch(getAlertList({ status: ALERT_STATUS.IN_PROGRESS, ...params }))
        }
    };

    const onFilter = (label) => () => {
        const list = [...filterList]
        if (label == 'all') {
            setFilterList([])
        } else {
            const idx = list.indexOf(label)
            if (idx > -1) {
                list.splice(idx, 1)
            } else {
                list.push(label)
            }
            setFilterList(list)
        }

        const subdivisions = label === 'all' ? '' : list.length === 1 ? list[0] : list.join(',')

        const params = { alert_type, page: activePage, limit: activePerPage, subdivisions: subdivisions }
        if (tabIndex === 0) {
            dispatch(getAlertList({ status: ALERT_STATUS.RAISED, ...params }))
        } else if (tabIndex === 1) {
            dispatch(getAlertList({ status: ALERT_STATUS.IN_PROGRESS, ...params }))
        }
    }

    return (
        <AlertContainer>
            <HeaderContainer style={{ display: 'block' }}>
                <HeaderText> Alert </HeaderText>
                <HeaderSubText>{`Latest alert always on top`}</HeaderSubText>
                {tabIndex !== 2 && <EventCountText>{tabIndex === 0 ? 'New' : 'Ongoing'} events - {tabIndex === 0 ? (inHouseList?.totalNewAlerts || 0) : (inHouseList?.totalInProgressAlerts || 0)} </EventCountText>}
            </HeaderContainer>
            {tabIndex === 2 && subdiv_user && <AddLocationBtnContainer>
                <Buttons
                    // variant="contained"
                    startIcon={<AddIcon />}
                    onClick={onAddLocation}
                    addButtonnProps={true} style={{ minWidth: "170px" }}
                >

                    Add location</Buttons>
            </AddLocationBtnContainer>}
            {tanent_user && tabIndex !== 2 && <div style={{ marginBottom: '10px' }}>
                <FilterButton variant={filterList.length ? "outlined" : "contained"} key={`subdiv-all`}
                    onClick={onFilter('all')}
                    style={{
                        backgroundColor: filterList.length ? '' : 'rgb(72, 146, 195)',
                        color: filterList.length ? 'rgb(72, 146, 195)' : 'white'
                    }}
                >
                    {'All'}</FilterButton>
                {subdivUser && subdivUser.map((user, index) => <FilterButton
                    style={{
                        backgroundColor: filterList.indexOf(user.id) > -1 ?
                            'rgb(72, 146, 195)' : '', color: filterList.indexOf(user.id) > -1 ? 'white' : 'rgb(72, 146, 195)'
                    }}
                    variant={filterList.indexOf(user.id) > -1 ? "contained" : "outlined"} key={`subdiv-${index}`} onClick={onFilter(user.id)}>
                    {user.label}</FilterButton>)}
            </div>}
            <CustomTabs className="custom-tabs" value={tabIndex} onChange={onTabChange} aria-label="" twoColors={true} isBlueFirst={true} tabIndex={tabIndex}>
                {tabList.map((tab, index) => <CustomTab key={`tab-${index}`} label={tab.title} id={`tab-${index}`} twoColors={true} isBlueFirst={true} />)}
                {tabIndex !== 2 && <AmountItemsContainer>
                    <AmountItems changePerPage={changePerPage} activePerPage={activePerPage} />
                </AmountItemsContainer>}
            </CustomTabs>
            {tabList.map((tab, index) => <TabPanel key={`tabpanel-${index}`} index={index} tabIndex={tabIndex}>{tab.element}</TabPanel>)}
        </AlertContainer>
    );
}
