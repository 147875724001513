import styled, { css } from 'styled-components';

import COLORS from 'utils/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  .pagination {
    display: flex;
    margin:0;
    li {
      list-style-type: none;
      font-size: 13px;
      margin: 0px 10px;
    
      border: 1px solid transparent;
      a {
        display: block;
        text-align: center;
        width: 26px;
        height:26px;
        text-decoration:none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${COLORS.greylighttext};
      }
    }
    li.active {
      border: 1px solid ${COLORS.blue};
      border-radius: 50%;
      a {
        color: ${COLORS.blue};
      }
    }
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  cursor: pointer;
  ${props => (props.after ? css`&::after` : '&::before')}
  {
    content: '';
    display: inline-block;
    width: 1px;
    height: 15px;
    background-color: ${COLORS.greylighttext};
  }
  i {
    font-size: 13px;
    display: inline-block;
  }
`;
