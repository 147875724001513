import React, { useEffect } from 'react';
import { Buttons } from 'modules/Button';
import { TextFields } from 'modules/Textfeild';
import Modal from "components/common/modal";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useParams } from 'react-router';
import { validationMsg } from '@jumbo/constant/ErrorMessages';
import Input from 'components/formComponents/Input';
import { useDispatch, useSelector } from 'react-redux';
import { addCheckList, getCheckList, updateCheckList } from 'redux/actions/checklist';
const Add = ({ openDialog, setOpenDialog, type, isCommonChecklist, subdivisionId, updateChecklistItem, setUpdateChecklistItem, currentUser, setEditMode, editMode }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const schema = Yup.object().shape({
        action: Yup.string().trim(validationMsg.focusBoard.action).required(validationMsg.focusBoard.action),
    });

    const onSubmitHandler = (values, { resetForm }) => {
        if (updateChecklistItem) {
            console.log("updateChecklistItem", updateChecklistItem);
            dispatch(updateCheckList(values, id, { resetForm }, subdivisionId, updateChecklistItem.action_id));
            setOpenDialog(false);
            setEditMode(false)
        } else {
            if (subdivisionId) {
                dispatch(addCheckList(values, id, { resetForm }, subdivisionId));
            } else {
                dispatch(addCheckList(values, id, { resetForm }));
            }
            setOpenDialog(false);
        }
    }

    useEffect(() => {
        if (!openDialog) setTimeout(() => setUpdateChecklistItem(), 300);
    }, [openDialog])

    return (
        <>
            <Modal fullWidth={true} openDialog={openDialog} setOpenDialog={setOpenDialog} title={updateChecklistItem ? "Edit checklist item" : "Add new checklist item"}>
                <div className='text-center text-sm mb-5'>
                    {/* <p>Be aware: Adding item to the checklist will add the item to all alerts.</p> */}
                    {updateChecklistItem ? <>
                        <p>Please be aware that modifying an item in the checklist will affect all alerts created for this feature.</p>
                        {currentUser?.detail?.user_type !== 2 ? <p> If you want to add an action that is specific to the current alert, we recommend using the Focus board</p> : null}</>
                        : <> <p>Be aware: Adding item to the checklist will add the item to {!isCommonChecklist ? 'the current alert and ' : ''}all the alerts that create in the feature.</p>
                            {currentUser?.detail?.user_type !== 2 ? <p>If you want to add an action specific to the current alert, use Focus board.</p> : null}</>}
                </div>

                {/* Edit checklist item
Be aware: Adding item to the checklist will add the item to all the alerts that create in the feature.

If you want to add an action specific to the current alert, use Focus board.

can you please modify this content for edit checklist current content for add checklist  */}
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        action: updateChecklistItem?.action || '',
                        type: type
                    }}
                    onSubmit={onSubmitHandler}
                    validationSchema={schema}
                >
                    {({ errors, setFieldValue, values }) => {
                        console.log(errors);
                        return (
                            <FormikForm>
                                <div className="w-full last:mb-0 text-right">
                                    {console.log('values', values)}
                                    <Input
                                        label="Describe the action *"
                                        fullWidth
                                        name="action"
                                        defaultValue={values.action}
                                    />
                                </div>
                                <div className='flex justify-center mt-4'>
                                    <Buttons type='submit'> {updateChecklistItem ? 'Edit item' : 'Register item'}</Buttons>
                                </div>
                            </FormikForm>
                        )
                    }}
                </Formik>
            </Modal >
        </>
    )
}

export default Add;