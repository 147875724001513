import{ LOCAL_POLICE_NUMBER_LIST, 
  ADD_LOCAL_POLICE_NUMBER,
  UPDATE_LOCAL_POLICE_NUMBER,
  REMOVE_LOCAL_POLICE_NUMBER,
  SET_LOCAL_POLICE_NUMBER} from "../../@jumbo/constant/ActionTypes";  
  const INIT_STATE = {
    numberList:[],
    // addUser:null,
    currentuser:null,
    currentDetails:null
   
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case LOCAL_POLICE_NUMBER_LIST: {
        return {
          ...state,
          numberList: action.payload,
        };
      }
      case ADD_LOCAL_POLICE_NUMBER: {
          return {
            ...state,
            numberList: [action.payload, ...state.numberList],
        };
       }
       case  SET_LOCAL_POLICE_NUMBER: {
        return {
          ...state,
          currentDetails:action.payload
      };
    }
      case UPDATE_LOCAL_POLICE_NUMBER: {
        return {
          ...state,
          numberList: state.numberList.map(numberList => (numberList.id === action.payload._id ? action.payload : numberList)),
        };
      
     }
     case REMOVE_LOCAL_POLICE_NUMBER: {
      return {
        ...state,
        numberList: state.numberList.filter(numberList => numberList._id !== action.payload),
      };
    }
      default:
        return state;
    }
  };
  