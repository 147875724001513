import styled from "styled-components";
import { Tabs, Tab } from "@mui/material"
import COLORS from "../../utils/colors";

export const CustomTabs = styled(Tabs)(({ singleColor, twoColors, isBlueFirst, isRedFirst, value,tabIndex }) => ({
    borderBottom: `1px solid rgb(229 230 233) !important`,
    '& .MuiTabs-indicator': {
        backgroundColor: tabIndex === 2?null:`${singleColor ? 'rgb(78, 145, 192) !important' :
            (twoColors && isBlueFirst) ? (value % 2 === 0) ? 'rgb(78, 145, 192) !important' : COLORS.redTabColor :
                (twoColors && isRedFirst) ? (value % 2 === 0) ? COLORS.redTabColor : 'rgb(78, 145, 192) !important' : ''} `
                ,height: tabIndex === 2?'auto !important':'1px !important',
                borderBottom:tabIndex === 2?'solid 1px rgb(78, 145, 192) !important':null
                
            },
            '& .MuiTab-root':{
                color:'rgb(144, 153, 170) !important',
                fontFamily: 'SF Pro Text !important',
                
            },
            
}));

export const CustomTab = styled(Tab)(({ singleColor, twoColors, isBlueFirst, isRedFirst, value }) => {
    return ({
        textTransform: 'none !important',
        minWidth: '40px !important',
        display: '-webkit-inline-box !important',
        padding: '15px 0px !important',
        marginRight: '100px !important',
        textAlign: 'left !important',
        fontFamily: 'SF Pro Text !important',
        fontSize: '15px !important',
        fontWeight: '500',
            // '&.Mui-selected': {
            //     // color: 'rgb(78, 145, 192) !important',
            //     fontFamily: 'SF Pro Text !important',
        
            // },
            
        '&.Mui-selected': {
            color: `${singleColor ? 'rgb(78, 145, 192) !important' :
                (twoColors && isBlueFirst) ? (value % 2 === 0) ? 'rgb(78, 145, 192) !important' : `${COLORS.redTabColor} !important` :
                    (twoColors && isRedFirst) ? (value % 2 === 0) ? `${COLORS.redTabColor} !important` : 'rgb(78, 145, 192) !important' : ''} `
                    ,fontFamily: 'SF Pro Text !important'},
                
                }
    
    )
});
