import { Buttons } from 'modules/Button';
import { TextFields } from 'modules/Textfeild';
import React, { useState } from 'react'
import Modal from "components/common/modal";
import { TextAreas } from 'modules/TextAreas';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { completeCheckList } from 'redux/actions/checklist';
import { Formik, Form as FormikForm } from "formik";
import Input from 'components/formComponents/Input';
import { validationMsg } from '@jumbo/constant/ErrorMessages';
import * as Yup from "yup";
const Completed = ({ openCompleteDialog, setOpenCompleteDialog, type, actionId, subdivisionId }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const schema = Yup.object().shape({
        comment: Yup.string().trim(validationMsg.focusBoard.comment).required(validationMsg.focusBoard.comment),
    });
    const onSubmitHandler = (values, { resetForm }) => {
        dispatch(completeCheckList(actionId, { id, ...values }, { resetForm }, subdivisionId));
        setOpenCompleteDialog(false);
    }
    return (
        <Modal fullWidth={true} style={{ maxWidth: '550px', margin: '0 auto' }} openDialog={openCompleteDialog} setOpenDialog={setOpenCompleteDialog} title="Complete item">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    comment: '',
                    type: type
                }}
                onSubmit={onSubmitHandler}
                validationSchema={schema}
            >
                {({ errors, setFieldValue, values }) => {
                    return (
                        <FormikForm>
                            <Input
                                // label="Do you want to add a comment to the completion?"
                                label="Add a comment.*"
                                type="text"
                                fullWidth
                                name="comment"
                                defaultValue={values.comment}
                            />
                            <div className='flex justify-center mt-4'>
                                <Buttons type={'submit'} className='p-5'> {'Complete action'}</Buttons>
                            </div>
                        </FormikForm>)
                }}
            </Formik>
        </Modal>
    )
}

export default Completed