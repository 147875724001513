import React from 'react'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Container, Input, listItemIconClasses } from "@mui/material";
import { Form, useNavigate } from "react-router-dom";
import { maxHeight } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
// import { getAllUsersList,getCurrUserDetail } from "redux/actions/SubDivision";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
// import ConfirmDialog from "./ConfirmDialog";
// import TableHeader from "./TableHeader";
import TableSortLabel from "@mui/material/TableSortLabel";
import Search from "@mui/icons-material/Search";
// import { useNavigate } from "react-router-dom";
// import AppTextInput from 'applayout/formElements/AppTextInput';
// import AppTextInput from './applayout/formElements/AppTextInput'
import CloseIcon from '@mui/icons-material/Close';



const columns1 = [
  // {
  //   id: "_id",
  //   label: "Tenant Id",

  //   tag: true,
  //   sortable: true,
  //   key: "id",
  //   align: "left",
  // },


  { id: "country", label: "Country", align: "left" },
  {
    id: "policPhone",
    label: "Police phone",

    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "Description",

    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },

  // {
  //   id: "status",
  //   label: "Status",

  //   align: "left",

  // },

];


export default function UserTableHead({ orderBy, order, handleRequestSort }) {

  const onSortOrderChange = property => event => {
    event.preventDefault();
    handleRequestSort(property);
  };
  return (

    <TableHead>
      <TableRow>
        {columns1.map((column) => (
          <TableCell
            // key={column.id}
            // sortDirection={orderBy === column.id ? order : false}
            // align={column.align}
            // style={{
            //   minWidth: column.minWidth,
            //   color: "rgb(144, 153, 170)",
            // }}
            key={column.id}
            sortDirection={orderBy === column.id ? order : false}
            align={column.align}
            style={{ color: "rgb(144, 153, 170)", height: "10px", minWidth: "125px" }}

          >
            {column.label}
            <TableSortLabel

              // direction={orderBy === column.id ? order : "asc"}
              // onClick={onSortOrderChange(column.id)}
              IconComponent='none'
              className="custom-table-header"
            >

            </TableSortLabel>
            {/* {column.label} */}
          </TableCell>
        ))}
        <TableCell
          style={{ width: '184px' }}
        >
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
