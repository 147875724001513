import PageNotFound from 'components/PageNotFound';
import Dashboard from "../modules/Dashboard/index";
import SubDivisions from "modules/SubDivisions";
import ConnectedSubDivisions from 'modules/ConnectedSubDivisions';
import AddSubdivision from 'modules/SubDivisions/AddSubdivision';
import Login from 'modules/auth/Login';
import ExternalUsers from 'modules/extenalUsers'
import EditExtController from 'modules/extenalUsers/EditExtController';
import Travel from 'modules/Travel'
import TravelDetail from 'modules/Travel/TravelDetail/index'
import AddTravel from 'modules/Travel/AddTravel'
import Detail from 'modules/CrisisLog/Travel/Detail';
import UserViewList from 'modules/CrisisLog/Alert/UserViewList/index'
import UserViewListInHouse from 'modules/CrisisLog/InHouse/UserViewListInHouse/index'
import AlertDetail from 'modules/Alert/AlertDetail';
import { Routes, Route, Navigate } from "react-router-dom";
import AddExtController from 'modules/extenalUsers/AddExtController';
import EditSubdivision from 'modules/SubDivisions/EditSubdivision';
import Tenants from 'modules/Tenants';
import AddEditUser from 'modules/Tenants/AddEditUser/index'
import Users from 'modules/Users'
import AddEditUsers from 'modules/Users/AddEditUser';
import AddEditNumber from 'modules/Settings/LocalPoliceNumber/AddEditNumber'
import Settings from 'modules/Settings';
import Alert from 'modules/Alert';
import Inhouse from 'modules/Inhouse';
import CrisisLog from 'modules/CrisisLog';
import ForgotPassword from 'modules/forgotPassword';
import ImportantContact from 'modules/Settings/importantContact';
import ProtectedRoute from 'routes/ProtectedRoute';
import AddEditLocation from 'modules/Inhouse/AddEditLocation';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AppLayout from "applayout";
import UnauthorizedPage from '../components/PageNotFound/Unauthorized'
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Checklist from 'modules/checklist';
import { ALERT_TYPE, CHECKLIST_TYPE } from 'utils/alertHelper';


const Index = () => {
  const [routes, setRoutes] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  const { currentuser: currentUser, socektObj: socketObj } = useSelector(({ USERPROFILE }) => USERPROFILE);
  const { secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);

  const { loading } = useSelector(({ common }) => common);

  const routeArray1 = [
    // currentUser?.detail?.user_type === 3 ? 
    { path: 'Unauthorized', comp: <UnauthorizedPage />, label: 'Unauthorized' },
    { path: 'SubDivisions', comp: <SubDivisions />, label: 'SubDivisions' },
    { path: 'ConnectedSubDivisions', comp: <ConnectedSubDivisions />, label: 'ConnectedSubDivisions' },
    //  : { path: 'Unauthorized', comp: <Unauthorized />, label: 'Unauthorized' },
    { path: 'ExternalUsers', comp: <ExternalUsers />, label: 'ExternalUsers' },
    { path: 'SubDivisions/AddSubdivision/', comp: <AddSubdivision />, label: 'ExternalUsers' },
    { path: 'SubDivisions/EditSubdivision/:id', comp: <EditSubdivision />, label: 'ExternalUsers' },
    { path: 'ExternalUsers/AddExtController/', comp: <AddExtController />, label: 'ExternalUsers' },
    { path: 'Settings', comp: <Settings />, label: 'ExternalUsers' },
    { path: 'Settings/ImportantContact', comp: <ImportantContact />, label: 'ExternalUsers' },
    { path: 'ExternalUsers/EditExtController/:id', comp: <EditExtController />, label: 'ExternalUsers' },
    { path: 'Tenants/AddTenant/', comp: <AddEditUser />, label: 'ExternalUsers' },
    { path: 'Tenants/EditTenant/:id', comp: <AddEditUser />, label: 'ExternalUsers' },
    { path: 'Tenants', comp: <Tenants />, label: 'ExternalUsers' },
    { path: 'Dashboard', comp: <Dashboard />, label: 'Dashboard' },
    { path: 'Users', comp: <Users />, label: 'ExternalUsers' },
    { path: 'users/Adduser/', comp: <AddEditUsers />, label: 'ExternalUsers' },
    { path: 'users/EditUSer/:id', comp: <AddEditUsers />, label: 'ExternalUsers' },

    { path: 'Alert', comp: <Alert />, label: 'alert_travel' },
    { path: 'alert/alert-detail/:id', comp: <AlertDetail />, label: 'alert_travel' },

    { path: 'Inhouse', comp: <Inhouse />, label: 'in_house' },
    { path: 'Inhouse/alert-detail/:id', comp: <AlertDetail />, label: 'in_house' },

    //crisis tab
    { path: 'CrisisLog', comp: <CrisisLog />, label: 'CrisisLog' },

    { path: 'CrisisLog/crisis-log-alert-detail/:id', comp: <UserViewList />, label: 'alert_travel' },
    { path: 'CrisisLog/alert/checklist', comp: <Checklist alertType={CHECKLIST_TYPE.ALERT} />, label: 'alert_travel' },

    { path: 'CrisisLog/crisis-log-InHouse-detail/:id', comp: <UserViewListInHouse />, label: 'in_house' },
    { path: 'CrisisLog/inHouse/checklist', comp: <Checklist alertType={CHECKLIST_TYPE.IN_HOUSE} />, label: 'in_house' },

    { path: 'crisislog/travel-detail/:id', comp: <Detail />, label: 'alert_travel' },
    { path: 'CrisisLog/travel/checklist', comp: <Checklist alertType={CHECKLIST_TYPE.TRAVEL} />, label: 'alert_travel' },

    { path: 'Travel', comp: <Travel />, label: 'alert_travel' },
    { path: 'Travel/travel-detail/:id', comp: <TravelDetail />, label: 'alert_travel' },
    { path: 'Travel/add-travel', comp: <AddTravel />, label: 'alert_travel' },
    { path: 'Settings/AddNumber', comp: <AddEditNumber />, label: 'ExternalUsers' },
    { path: 'Settings/EditNumber/:id', comp: <AddEditNumber />, label: 'ExternalUsers' },
    { path: 'Inhouse/add-location', comp: <AddEditLocation />, label: 'in_house' },
    { path: 'Inhouse/edit-location/:id', comp: <AddEditLocation />, label: 'in_house' },

    { path: 'SubDivisions/checklist', comp: <Checklist alertType={CHECKLIST_TYPE.IN_HOUSE} />, label: 'in_house' },
  ];

  useEffect(() => {
    if (currentUser?.detail && (currentUser?.detail?.user_type !== 3 && location?.pathname === '/app/SubDivisions')) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (currentUser?.detail?.user_type !== 2 && location?.pathname === '/app/ConnectedSubDivisions')) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (currentUser?.detail?.user_type !== 3 && location?.pathname === '/app/SubDivisions/AddSubdivision')) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (currentUser?.detail?.user_type !== 4 && location?.pathname === '/app/users')) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (currentUser?.detail?.user_type !== 3 && location?.pathname === '/app/ExternalUsers')) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (currentUser?.detail?.user_type !== 3 && location?.pathname === '/app/ExternalUsers/AddExtController/')) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (currentUser?.detail?.user_type !== 4 && location?.pathname === '/app/Settings/AddNumber')) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (location?.pathname === '/app/Alert' && !currentUser?.detail?.has_access_to?.alert_travel)) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (location?.pathname === '/app/Travel' && !currentUser?.detail?.has_access_to?.alert_travel)) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (location?.pathname === '/app/Inhouse' && !currentUser?.detail?.has_access_to?.in_house)) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && (location?.pathname === '/app/Inhouse/add-location' && !currentUser?.detail?.has_access_to?.in_house)) {
      navigate('/app/Unauthorized')
    }
    else if (currentUser?.detail && ([1, 2].includes(isNaN(currentUser?.detail?.user_type)) && location?.pathname == '/app/Tenants')) {
      navigate('/app/Unauthorized')
    } else if (currentUser?.detail && (currentUser?.detail?.user_type == 2 && !secSubdivisionUser && location?.pathname == '/app/CrisisLog')) {
      navigate('/app/Unauthorized')
    } else if (currentUser?.detail && (currentUser?.detail?.user_type == 2 && !secSubdivisionUser && location?.pathname == '/app/Alert')) {
      navigate('/app/Unauthorized')
    } else if (currentUser?.detail && (currentUser?.detail?.user_type == 2 && !secSubdivisionUser && location?.pathname == '/app/inhouse')) {
      navigate('/app/Unauthorized')
    } else if (currentUser?.detail && (currentUser?.detail?.user_type == 2 && !secSubdivisionUser && location?.pathname == '/app/Travel')) {
      navigate('/app/Unauthorized')
    } else if (currentUser?.detail && (currentUser?.detail?.user_type == 2 && !secSubdivisionUser &&
      (location?.pathname == '/app/CrisisLog/Alert/checklist' || location?.pathname == '/app/CrisisLog/inHouse/checklist' || location?.pathname == '/app/CrisisLog/travel/checklist'))) {
      navigate('/app/Unauthorized')
    }
    // else if (currentUser?.detail && (currentUser?.detail?.has_access_to?.alert_travel === false && location?.pathname === '/app/Alert'))
    // {
    //   <UnauthorizedPage />
    // }
    // else if (currentUser?.detail && (currentUser?.detail?.has_access_to?.in_house === false && location?.pathname === '/app/Inhouse'))
    // {
    //   navigate('/app/Unauthorized')
    // }
  })

  useEffect(() => {
    setRoutes(() => {
      console.log("routeArray1", routeArray1)
      return routeArray1.filter((value) => {
        if (value.label.includes('alert_travel') || value.label.includes('in_house') || value.label.includes('CrisisLog')) {
          if ((value.label === 'alert_travel' || value.label.includes('CrisisLog')) && currentUser?.detail?.has_access_to?.alert_travel) {
            return true;
          } else if ((value.label === 'in_house' || value.label.includes('CrisisLog')) && currentUser?.detail?.has_access_to?.in_house) {
            return true;
          } else {
            return false;
          }
        }
        else {
          return true;
        }
      })
    });
  }, [currentUser, socketObj]);

  //revoke permission socket related
  useEffect(() => {
    if (currentUser?.detail) {
      if (!currentUser?.detail?.has_access_to?.in_house && !currentUser?.detail?.has_access_to?.alert_travel && currentUser?.detail?.user_type !== 1) {
        navigate('/app/Settings')
      }
      else if (!currentUser?.detail?.has_access_to?.alert_travel && location?.pathname.includes('/Alert')) {
        navigate('/app/Inhouse')
      }
      else if (!currentUser?.detail?.has_access_to?.in_house && location?.pathname.includes('/Inhouse')) {
        navigate('/app/Alert')
      }
      else if (!currentUser?.detail?.has_access_to?.in_house && location?.pathname.includes('/Inhouse/add-location')) {
        navigate('/app/Settings')
      }
    }
  }, [currentUser?.detail?.has_access_to]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname])

  return (
    <Routes>
      <Route path='/' element={<Navigate to="/login" replace />} />
      <Route path='/login' element={<Login />} />
      <Route path='/ForgotPassword' element={<ForgotPassword />} />
      <Route path='/app' element={<ProtectedRoute ><AppLayout /></ProtectedRoute>}>
        {
          routeArray1.map((value, key) =>
            <Route key={key} path={value.path} element={value.comp} />)
        }
        {(loading && loading <= 0) && <Route path="*" element={<PageNotFound />} />}
      </Route>
    </Routes>
  )
}

export default Index;