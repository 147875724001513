import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { DialogStyle } from '../style';

export function DeleteDialog({ open, onClose, onConfirm, endLoader}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={DialogStyle}
            maxWidth="xs">
            <DialogTitle id="alert-dialog-title">
                End Travel
            </DialogTitle>
            <DialogContent>
                Are you sure?
            </DialogContent>
            <DialogActions style={{ paddingBottom: '10px' }}>
                <Button onClick={onClose} variant={"outlined"}>Cancel</Button>
                <Button onClick={onConfirm} variant={"contained"} style={{ backgroundColor: 'rgb(211, 47, 47)', color: '#FFF' }}>
                    End Travel
                </Button>
            </DialogActions>
        </Dialog>
    )
}