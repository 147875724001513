import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import "./addEditContact.css";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  getCurrTenantDetail,
} from "redux/actions/tenant";
import {
  requiredMessage,
  lettersOnlyMessage,
  phoneOnlyNumericals,
  emailNotValid,
} from "@jumbo/constant/ErrorMessages";
import {
  emptySpace,
  phoneNumberValidation,
  stringCheck
} from "@jumbo/constant/ValidationRegex";
import {
  UpdateImportantContacts,
  getImportantContactList,
  addImportantContacts,
} from "redux/actions/importantContact";
import { useParams } from "react-router-dom";
import AddConfirmDialog from "../../../AddConfirmDialog";
import { Buttons } from "../../../Button";
import { TextFields } from "../../../Textfeild";
const ariaLabel = { "aria-label": "description" };


function AddEditUser({
  open,
  onClose,
  setOpenPassConfirmDialog,
}) {
  let { id } = useParams();
  const { currentDetails } = useSelector(({ IMPORTANTCONTACT }) => IMPORTANTCONTACT);
  const [title, setTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [helperText, setHelperText] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (id) { dispatch(getCurrTenantDetail(id)) }
  }, [id]);

  useEffect(() => {
    if (currentDetails) {
      setTitle(!!currentDetails ? currentDetails.name : null);
      setPhoneNumber(!!currentDetails ? currentDetails.phone_number : null);
      setEmailId(!!currentDetails ? currentDetails.email : null);
    }
  }, [currentDetails]);

  useEffect(() => {
    return () => {
      setTitle('');
      setEmailId('');
      setPhoneNumber('');
    }
  }, [])


  const handleSaveBtn = () => {
    if (!title || title.match(emptySpace)) {
      setHelperText(true)
      setTitleError(requiredMessage);
    } else if (!title.match(stringCheck)) {
      setHelperText(true)
      setTitleError(lettersOnlyMessage);
    }
    if (!phoneNumber || phoneNumber.match(emptySpace)) {
      setHelperText(true)
      setPhoneNumberError(requiredMessage);
    } else if (!phoneNumber.match(phoneNumberValidation)) {
      setHelperText(true)
      setPhoneNumberError(phoneOnlyNumericals);
    }
    if (!emailId || emailId.match(emptySpace)) {
      setHelperText(true)
      setEmailIdError(requiredMessage);
    } else if (!emailId.match(email)) {
      setHelperText(true)
      setEmailIdError(emailNotValid);
    }

    if (
      title &&
      title.match(stringCheck) &&
      !title.match(emptySpace) &&
      phoneNumber &&
      phoneNumber.match(phoneNumberValidation) &&
      !phoneNumber.match(emptySpace) &&
      emailId &&
      emailId.match(email) &&
      !emailId.match(emptySpace)
    ) {
      onUserSave();
    }
  };
  const onUserSave = () => {
    const important_contact_id = !!currentDetails ? currentDetails._id : null;
    const user = {
      name: title,
      phone_number: phoneNumber,
      email: emailId,
    };
    const userEdit = {
      name: title,
      phone_number: phoneNumber,
      email: emailId,
    };

    if (currentDetails) {
      dispatch(
        UpdateImportantContacts(
          important_contact_id,
          userEdit,
          { setAddDialog: () => setOpenPassConfirmDialog(true) },
          onClose,
          getImportantContactList));
    }
    else {
      dispatch(addImportantContacts(user, onClose,
        { setAddDialog: () => setOpenPassConfirmDialog(true) },));
      // getImportantContactList
    }
  };

  const handleCancelDelete = (user) => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Dialog open={open} onClose={onClose} style={{ maxWidth: '550px', margin: '0 auto' }}>
      <i className="icon-close icon" onClick={onClose}></i>
      <DialogTitle >
        {currentDetails ? "Edit contact" : "New contact"}
      </DialogTitle>
      <DialogContent>
        <TextFields
          margin="dense"
          label="Contact name"
          type="text"
          fullWidth
          variant="standard"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
            setTitleError("");
            setHelperText(false)
          }}
          helperText={titleError}
          id="title"
          style={{ marginBottom: '15px' }}
          helperProps={titleError ? helperText : false}
          errorProps={titleError ? true : false}
        />
        <TextFields
          margin="dense"
          id="phone"
          label="Phone number"
          type="text"
          fullWidth
          variant="standard"
          value={phoneNumber}
          onChange={(event) => {
            setPhoneNumber(event.target.value);
            setPhoneNumberError("");
            setHelperText(false)
          }}
          helperText={phoneNumberError}
          style={{ marginBottom: '18px' }}
          helperProps={phoneNumberError ? helperText : false}
          errorProps={phoneNumberError ? true : false} />
        <TextFields
          margin="dense"
          id="email"
          label="Email"
          type="text"
          fullWidth
          variant="standard"
          value={emailId}
          onChange={(event) => {
            setEmailId(event.target.value);
            setEmailIdError("");
            setHelperText(false)
          }}
          helperText={emailIdError}
          style={{ marginBottom: '15px' }}
          helperProps={emailIdError ? helperText : false}
          errorProps={emailIdError ? true : false}
        />
      </DialogContent>
      <Buttons onClick={handleSaveBtn} style={{ width: "50%", marginLeft: "105px", marginBottom: "50px", padding: "16px", marginTop: "40px" }} buttoncheck={true}> {currentDetails ? 'Save' : 'Add'}</Buttons>
      <AddConfirmDialog
        open={openConfirmDialog}
        content={`All changes saved successfully. `}
        btnLabels={{ ok: "Ok", }}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Dialog>
  );
}
export default AddEditUser;
