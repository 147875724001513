// import React from 'react'
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Container, Input, listItemIconClasses } from "@mui/material";
import { Form, useNavigate } from "react-router-dom";
import { maxHeight } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { SEC_SUBDIV, GET_SUBDIVISION_LIST } from '../../@jumbo/constant/ActionTypes';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";

import TableSortLabel from "@mui/material/TableSortLabel";
import Search from "@mui/icons-material/Search";

import CloseIcon from "@mui/icons-material/Close";
import UserTableToolbar from "./UserTableToolbar";

import useStyles from "./index.style";
import UserTableHead from "./UserTableHead";
import { useDebounce } from "utils/commonHelper";
import UserListRow from "./UserListRow";
import Pagination from "../Pagination/Pagintions";
import {
  getTenantList,
  setTenantList,
  getCurrTenantDetail,
  deleteUser,
  getAllTenantsListForSEC,
} from "redux/actions/tenant";
import ConfirmDltDialog from "../ConfirmDltDialog";
import Grid from "@mui/material/Grid";
import {
  Button,
  Container0,
  Container1,
  Button1,
  Button2,
  Container2,
  Container3,
} from "./index.style";
import { SET_TENANT } from "@jumbo/constant/ActionTypes";
import AddEditUser from "./AddEditUser";
import AddConfirmDialog from "../AddConfirmDialog";
import ConnectTennantDialog from "../ConnectTennantDialog";
import { getSubdivisionList, connectDisconnectSub } from '../../redux/actions/SubDivision';

const perPages = [10, 20, 50, 100];
export default function Tenants() {
  // const classes = useStyles();
  const { users } = useSelector(({ TENANT }) => TENANT);
  const { allUsers } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
  const userProfile = useSelector(store => store?.USERPROFILE?.currentuser?.detail);
  // const { usersedit } = useSelector(({ TENANT }) => TENANT.currentuser);
  const [page, setPage] = React.useState(0);
  // const [users, setusers] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedUser, setSelectedUser] = useState({ first_name: "" });
  // const [sortBy, setSortBy] = useState("name");
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("desc");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [orderBy, setOrderBy] = React.useState("first_name");
  const [openConfirmDltDialog, setopenConfirmDltDialog] = useState(false)
  const [currentData, setCurrentData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchValue, setSearchValue] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [activePerPage, setActivePerPage] = useState(10);
  const [editData, setEditData] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const [openTennant, setOpenTenant] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState("");
  const onCloseTennantDialog = () => setOpenTenant(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let user_type = 3;

  useEffect(() => {
    if (userProfile && userProfile?.user_type === 2) {
      dispatch(getAllTenantsListForSEC(orderBy,
        order,
        searchValue,
        activePage,
        activePerPage))
    } else if (userProfile && userProfile?.user_type !== 2) {
      dispatch(
        getTenantList(
          user_type,
          orderBy,
          order,
          searchValue,
          activePage,
          activePerPage
        )
      );
    }
  }, [orderBy, order, activePage, activePerPage, dispatch, userProfile]);

  useEffect(() => {
    // dispatch(getSubdivisionList());
    localStorage.removeItem("sec_subdiv_id");
    dispatch({ type: SEC_SUBDIV, payload: "" })
  }, []);

  const onHandleClickMore = async (id) => {
    dispatch({ type: GET_SUBDIVISION_LIST, payload: [] })
    setSelectedTenantId(id)
    await dispatch(getSubdivisionList(id));
    setOpenTenant(true);
  }

  const handleEdit = (id) => {
    dispatch({ type: SET_TENANT, payload: [] });
    dispatch(getCurrTenantDetail(id));
    navigate(`/app/Tenants/EditTenant/${id}`);
  };

  const addTenant = () => {
    navigate(`/app/Tenants/AddTenant/`);
    dispatch({ type: SET_TENANT, payload: null });
  };

  const handleUserDelete = (user) => {
    setSelectedUser(user);
    setopenConfirmDltDialog(true);

  };
  const handleCancelDelete = () => {

    setopenConfirmDltDialog(false);
  };
  const handleConfirmDelete = () => {
    setopenConfirmDltDialog(false);
    dispatch(deleteUser(selectedUser._id, { setAddDialog: () => setOpenConfirmDialog(true) },
      () => {
        if (userProfile && userProfile?.user_type === 2) {
          return dispatch(getAllTenantsListForSEC(orderBy,
            order,
            searchValue,
            activePage,
            activePerPage))
        } else if (userProfile && userProfile?.user_type !== 2) {
          return dispatch(
            getTenantList(
              user_type,
              orderBy,
              order,
              searchValue,
              activePage,
              activePerPage
            )
          );
        }
      }))
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const onSearch = (searchValue) => {
    setSearchValue(searchValue);
  };
  const submitSearch = (e) => {
    e.preventDefault();
    setSearchValue(searchValue);
    // setActivePage(activePage);
    // setActivePerPage(activePerPage);
    if (userProfile && userProfile?.user_type === 2) {
      return dispatch(getAllTenantsListForSEC(orderBy,
        order,
        searchValue,
        activePage,
        activePerPage))
    } else if (userProfile && userProfile?.user_type !== 2) {
      return dispatch(
        getTenantList(
          user_type,
          orderBy,
          order,
          searchValue,
          activePage,
          activePerPage
        )
      );
    }
  };
  const clearSearch = (e) => {
    e.preventDefault();
    setSearchValue("");
    if (userProfile && userProfile?.user_type === 2) {
      return dispatch(getAllTenantsListForSEC(orderBy,
        order,
        searchValue,
        activePage,
        activePerPage))
    } else if (userProfile && userProfile?.user_type !== 2) {
      return dispatch(
        getTenantList(user_type, orderBy, order, "", activePage, activePerPage)
      );
    }
  };

  // const changePerPage = (activePerPage) => {
  //   setActivePage(activePerPage);
  //   dispatch(
  //     getAllUsersList(orderBy, order, searchValue, activePage, activePerPage)
  //   );
  // };

  const changePerPage = (activePerPage) => {
    // e.preventDefault();
    setActivePage(1);
    setActivePerPage(activePerPage);
    if (userProfile && userProfile?.user_type === 2) {
      return dispatch(getAllTenantsListForSEC(orderBy,
        order,
        searchValue,
        activePage,
        activePerPage))
    } else if (userProfile && userProfile?.user_type !== 2) {
      return dispatch(
        getTenantList(
          user_type,
          orderBy,
          order,
          searchValue,
          activePage,
          activePerPage
        )
      );
    }
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setActivePerPage(activePerPage);
    // dispatch(
    //   getTenantList(
    //     user_type,
    //     orderBy,
    //     order,
    //     searchValue,
    //     activePage,
    //     activePerPage
    //   )
    // );
  };
  const handleCancelConfirm = () => {
    setOpenConfirmDialog(false)
  }
  const handleConfirm = () => {
    setOpenConfirmDialog(false)
  }

  const onHadleConnectDisconnect = (data) => {
    const riderect = () => navigate("/app/ConnectedSubDivisions");
    dispatch(connectDisconnectSub({ ...data, tenant_id: selectedTenantId }, onCloseTennantDialog, riderect));
  }

  return (
    <>
      <div className="mainDiv1">
        <UserTableToolbar
          onSearch={onSearch}
          searchValue={searchValue}
          submitSearch={submitSearch}
          clearSearch={clearSearch}
          changePerPage={changePerPage}
          activePerPage={activePerPage}
          addTenant={addTenant}
          userProfile={userProfile}
        />

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            aria-label="sticky enhanced table"
            className="custome-tbl"
          >
            <UserTableHead
              orderBy={orderBy}
              order={order}
              handleRequestSort={handleRequestSort}
            />

            <TableBody>
              {!!users.userData ? (
                users.userData
                  // .slice(
                  //   0 * activePerPage,
                  //   page * activePerPage + activePerPage
                  // )
                  .map((row, index) => (
                    <UserListRow
                      key={index}
                      row={row}
                      handleUserDelete={handleUserDelete}
                      handleEdit={handleEdit}
                      userProfile={userProfile}
                      onHandleClickMore={onHandleClickMore}
                    />
                  ))
              ) : (
                <TableRow style={{ width: "40%" }}>
                  <TableCell
                    colSpan={10}
                    rowSpan={10}
                    style={{ textAlign: "center" }}
                  >
                    No Record found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <ConfirmDltDialog
            open={openConfirmDltDialog}
            content={`Are you sure you want to delete ${!!selectedUser ? selectedUser.first_name : ''} ${!!selectedUser ? selectedUser.last_name : ''}?  `}
            content1={`All information about this user will be permanently deleted. `}
            btnLabels={{
              no: "No",
              Yes: "Yes",
            }}
            onClose={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        </TableContainer>
        <Pagination
          activePage={activePage}
          totalItems={!!users ? users.total : 1}
          itemsCountPerPage={activePerPage}
          handlePageChange={handlePageChange}
        />
        <AddConfirmDialog
          open={openConfirmDialog}
          // title={`Delete Subdivision`}
          content={`Tenant was deleted successfully! `}
          btnLabels={{
            ok: "Ok",
          }}
          onClose={handleCancelConfirm}
          onConfirm={handleConfirm}
        />
        <ConnectTennantDialog
          open={openTennant}
          onClose={onCloseTennantDialog}
          allUsers={allUsers}
          onHandleConnect={onHadleConnectDisconnect}
        />
      </div>
    </>
  );
}
