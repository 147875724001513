import { TextField } from "@mui/material";
import styled from "styled-components";

export const CustomTextareaContainer = styled.div`
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    -webkit-box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    -moz-box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    padding: 15px;
`

export const TextareaBox = styled(TextField)({
    fontFamily: 'SFTextLight',
    fontSize: '12px',
})