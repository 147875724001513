import { GET_TENANTLIST ,ADD_TENANT,EDIT_SUBDIVISION_USER,SET_TENANT} from "../../@jumbo/constant/ActionTypes";
  
  const INIT_STATE = {
    users: [],
    edit:null
   
  };
  
  export default (state = INIT_STATE, action) => {
    
    switch (action.type) {
      case GET_TENANTLIST: {
        return {
          ...state,
          users: action.payload,
        };
      }
      case SET_TENANT: {
        
        return {
          ...state,
          edit: action.payload,
        };
      }
      case ADD_TENANT: {
        return {
          ...state,
          users: [action.payload, ...state.users],
        };
      }
     
      
      case EDIT_SUBDIVISION_USER: {
        return {
          ...state,
          allUsers: state.allUsers.map(allUsers => (allUsers.id === action.payload._id ? action.payload : allUsers)),
        };
      
     }
      default:
        return state;
    }
  };
  