import React from "react";

export default function Loader({ style }) {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 9999,
            ...style
        }}>
            <img src={process.env.PUBLIC_URL + "/images/ccLogLoader.svg"} width='100' />
        </div>
    );
}

