import { GET_ALERT_DETAIL, GET_ALERT_LIST, GET_ALERT_LIST_UPDATE, GET_IN_HOUSE_LIST_UPDATE, RESOLVE_ALERT, RESET_RESOLVE_ALERT, GET_IN_HOUSE_LIST_RESOLVE, GET_ALERT_LIST_RESOLVE, UPDATE_GPS, RESET_UPDATE_GPS, ADD_ALERT_COMMENT, RESET_ALERT_LIST, RESET_IN_HOUSE_LIST, GET_IN_HOUSE_LIST, GET_IN_HOUSE_LOC_LIST, ADD_IN_HOUSE_LOC, EDIT_IN_HOUSE_LOC, DEL_IN_HOUSE_LOC, RESET_DEL_IN_HOUSE_LOC, IS_IN_HOUSE_EDIT, GET_IN_HOUSE_LOC_DETAIL, RESET_ADD_IN_HOUSE_LOC, RESET_EDIT_IN_HOUSE_LOC } from "../../@jumbo/constant/ActionTypes"
// import { GET_ALERT_DETAIL, GET_ALERT_LIST,GET_ALERT_LIST_UPDATE, RESOLVE_ALERT, RESET_RESOLVE_ALERT, UPDATE_GPS, RESET_UPDATE_GPS, 
//     ADD_ALERT_COMMENT, RESET_ALERT_LIST, RESET_IN_HOUSE_LIST, GET_IN_HOUSE_LIST, GET_IN_HOUSE_LOC_LIST, ADD_IN_HOUSE_LOC, EDIT_IN_HOUSE_LOC,
//      DEL_IN_HOUSE_LOC, RESET_DEL_IN_HOUSE_LOC, IS_IN_HOUSE_EDIT, GET_IN_HOUSE_LOC_DETAIL, RESET_ADD_IN_HOUSE_LOC, RESET_EDIT_IN_HOUSE_LOC,GET_ALERT_LIST_RESOLVE } from "../../@jumbo/constant/ActionTypes"
import { ALERT_STATUS } from '../../utils/alertHelper'

const INITIAL_STATE = {
    alertList: null,
    // alertList: {},
    alertDetail: null,
    newListRec: 0,
    progressListRec: 0,
    inHouseNewListRec: 0,
    inHouseProgressListRec: 0,
    alertResolve: 0,
    updateGPS: 0,
    comment: null,
    inHouseList: null,
    inHouseLocList: null,
    addedInHouseLoc: null,
    updatedInHouseLoc: null,
    removedInHouseLoc: null,
    isHouseLocEdit: false,
    inHouseLocEditData: null,
    inHouseLocDetail: null,
}
let log1
let log2
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALERT_LIST:
            return {
                ...state,
                alertList: action.payload,
                newListRec: action.alertStatus === ALERT_STATUS.RAISED ? action.payload.total_no_of_records : state.newListRec,
                progressListRec: action.alertStatus === ALERT_STATUS.IN_PROGRESS ? action.payload.total_no_of_records : state.progressListRec
            }
        case GET_ALERT_LIST_UPDATE:
            // console.log('testing',state.alertList.userAlerts.filter((id)=> id._id));
            // console.log('testing',2,state.alertList.userAlerts);
            // console.log('testing',1,[
            //     ...state.alertList.userAlerts,
            //     action.payload.socketAlertData
            // ]);
            // let log = [
            //     ...state.alertList.userAlerts,
            //     action.payload.socketAlertData
            // ]

            if (state.alertList === null) {
                state.alertList = {
                    totalInProgressAlerts: 0,
                    totalNewAlerts: 0,
                    total_no_of_records: 0,
                    userAlerts: []

                }
            }
            return {
                ...state,
                ...state.alertList,
                newListRec: action.payload.raisedAlertCount,
                alertList: {
                    ...state.alertList,
                    totalNewAlerts: action.payload.raisedAlertCount,
                    totalInProgressAlerts: action.payload.inProgressAlertCount,
                    userAlerts: [
                        ...state.alertList.userAlerts,
                        action.payload.socketAlertData
                    ]
                }
            }

        case GET_ALERT_LIST_RESOLVE:
            let alertList = [];
            if (state?.alertList?.userAlerts && state.alertList.userAlerts?.length > 0) {
                alertList = state.alertList.userAlerts.filter((id) => id._id !== action.payload.socketAlertData)
            }
            return {
                ...state,
                newListRec: action.payload.raisedAlertCount,
                alertList: {
                    ...state.alertList,
                    totalNewAlerts: action.payload.raisedAlertCount,
                    totalInProgressAlerts: action?.payload?.inProgressAlertCount,
                    userAlerts: alertList
                }
            }

        case GET_IN_HOUSE_LIST:
            return {
                ...state,
                inHouseList: action.payload,
                inHouseNewListRec: action.alertStatus === ALERT_STATUS.RAISED ? action.payload.total_no_of_records : state.inHouseNewListRec,
                inHouseProgressListRec: action.alertStatus === ALERT_STATUS.IN_PROGRESS ? action.payload.total_no_of_records : state.inHouseProgressListRec
            }
        case GET_IN_HOUSE_LIST_UPDATE:
            console.log('testing', state, action.payload);
            if (state.inHouseList === null) {
                state.inHouseList = {
                    totalInProgressAlerts: 0,
                    totalNewAlerts: 0,
                    total_no_of_records: 0,
                    userAlerts: []

                }
            }
            return {
                ...state,
                ...state.inHouseList,
                inHouseNewListRec: action.payload.raisedAlertCount,
                inHouseList: {
                    ...state.inHouseList,
                    totalNewAlerts: action.payload.raisedAlertCount,
                    userAlerts: [
                        ...state.inHouseList.userAlerts,
                        action.payload.socketAlertData
                    ]
                }
            }

        case GET_IN_HOUSE_LIST_RESOLVE:
            console.log('testing', state.inHouseList.userAlerts.filter((id) => id._id));
            if (state.inHouseList.userAlerts) {
                log2 = state.inHouseList.userAlerts.filter((id) => id._id !== action.payload.socketAlertData)
            }


            return {
                ...state,

                ...state.inHouseList,
                inHouseNewListRec: action.payload.raisedAlertCount,
                inHouseList: {
                    ...state.inHouseList,
                    totalInProgressAlerts: action.payload.inProgressAlertCount,
                    totalNewAlerts: action.payload.raisedAlertCount,
                    userAlerts: log2
                }
            }

        case RESET_ALERT_LIST:
            return {
                ...state,
                alertList: null
            }

        case RESET_IN_HOUSE_LIST:
            return {
                ...state,
                inHouseList: null,
                inHouseNewListRec: 0,
                inHouseProgressListRec: 0
            }

        case GET_ALERT_DETAIL:
            return {
                ...state,
                alertDetail: action.payload,
            }

        case RESOLVE_ALERT:
            return {
                ...state,
                alertResolve: action.payload,
            }

        case RESET_RESOLVE_ALERT:
            return {
                ...state,
                alertResolve: 0,
            }

        case UPDATE_GPS:
            return {
                ...state,
                updateGPS: action.payload,
            }

        case RESET_UPDATE_GPS:
            return {
                ...state,
                updateGPS: 0,
            }

        case ADD_ALERT_COMMENT:
            return {
                ...state,
                comment: action.payload,
            }

        case GET_IN_HOUSE_LOC_LIST:
            return {
                ...state,
                inHouseLocList: action.payload,
            }

        case ADD_IN_HOUSE_LOC:
            return {
                ...state,
                addedInHouseLoc: action.payload,
            }

        case RESET_ADD_IN_HOUSE_LOC:
            return {
                ...state,
                addedInHouseLoc: null,
            }

        case EDIT_IN_HOUSE_LOC:
            return {
                ...state,
                updatedInHouseLoc: action.payload,
            }

        case RESET_EDIT_IN_HOUSE_LOC:
            return {
                ...state,
                updatedInHouseLoc: null,
            }

        case DEL_IN_HOUSE_LOC:
            const list = state.inHouseLocList && [...state.inHouseLocList]
            const idx = list.findIndex(x => x._id === action.loc_id)
            list.splice(idx, 1)
            return {
                ...state,
                removedInHouseLoc: action.payload,
                inHouseLocList: [...list]
            }

        case RESET_DEL_IN_HOUSE_LOC:
            return {
                ...state,
                removedInHouseLoc: null,
            }

        case IS_IN_HOUSE_EDIT:
            return {
                ...state,
                isHouseLocEdit: action.payload,
                inHouseLocEditData: action.editData
            }

        case GET_IN_HOUSE_LOC_DETAIL:
            return {
                ...state,
                inHouseLocDetail: action.payload
            }

        default:
            return state;
    }
}