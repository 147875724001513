import React, { useEffect, useState, useRef } from "react";
import { Button } from "@mui/material";
import {
  NameTravelIDBox,
  CustomTextarea,
  AddButtonContainer,
  AddButton,
  printHeaderStyle,
  printFooterStyle,
  headerInnerEleStyle,
  CustomErrorWrapper,
  CustomError,
  CustomTextareaContainer,
  testModeStyle
} from "../../style";
import {
  addTravelComment,
  resetTravelComment,
} from "redux/actions/travel";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { LogPrint } from "./LogPrint";
import * as moment from "moment";
// import { TRAVEL_STATUS } from "../../data";
import { ALERT_STATUS } from "utils/alertHelper";
import { requiredMessage } from "@jumbo/constant/ErrorMessages";
import COLORS from "utils/colors";
import { Buttons } from "../../../Button";
import {
  addAlertComment,
  resetAlertComment,
} from "redux/actions/CrisisLogAlert";

export default function CrisisLog({ id, navigate, printHeadRef }) {
  const dispatch = useDispatch();
  // const [comment, setComment] = useState("");
  // const [errors, setErrors] = useState({});
  const printRef = useRef(null);
  const [buttonProps, setButtonProps] = useState("addbutton");
  const { alertDetails, addAlertComment } = useSelector(
    ({ CRISISLOGALERT }) => CRISISLOGALERT
  );
  const [userLogDetail, setUserLogDetail] = useState(
    !!alertDetails ? alertDetails.alert : null
  );


  const printContent = () => {
    const tableStat = printRef.current.cloneNode(true);
    const headerStat = printHeadRef.current.cloneNode(true);
    const headerStatChild = headerStat.children;
    headerStatChild[0].style.cssText = `font-size: 16px;`;
    headerStatChild[1].style.cssText = `margin: 20px 0 20px 0; width: 100%;`;
    headerStatChild[2].style.cssText = `font-size: 16px;`;
    const subChild = headerStatChild[1].children;
    subChild[0].style.cssText = `font-size: 15px;`;
    const PrintElem = document.createElement("div");
    PrintElem.style.cssText = `padding: 40px 30px; width: 75%;`;

    const header = document.createElement("div");
    header.style.cssText = printHeaderStyle;
    const headerInnerEle = document.createElement("div");
    headerInnerEle.style.cssText = headerInnerEleStyle;
    const timeEle = document.createElement("div");
    timeEle.innerHTML = `${moment().format("M/DD/YY HH:MM A")}`;
    const titleEle = document.createElement("div");
    titleEle.innerHTML = `Crisis communitcation`;

    const testMode = document.createElement("div");
    testMode.style.cssText = testModeStyle;
    testMode.innerHTML = alertDetails.alert.test_mode ? "Test Mode" : '';

    headerInnerEle.appendChild(timeEle);
    headerInnerEle.appendChild(titleEle);
    header.appendChild(headerInnerEle);

    const footer = document.createElement("div");
    footer.style.cssText = printFooterStyle;
    footer.innerHTML = `${window.location.href}`;

    // PrintElem.appendChild(header);

    PrintElem.appendChild(testMode);
    PrintElem.appendChild(headerStat);
    PrintElem.appendChild(tableStat);
    // PrintElem.appendChild(footer);
    return PrintElem;
  };

  const handlePrint = useReactToPrint({
    content: printContent,
    pageStyle: `@media print { html, body { font-size: 10px } } `,
  });

  useEffect(() => {
    if (Object.keys(addAlertComment).length) {
      const tempLogDetail = { ...userLogDetail };
      if (Object.keys(tempLogDetail).length) {
        const tempList = [...tempLogDetail.alertLogs];
        tempList.unshift(addAlertComment);
        tempLogDetail.alertLogs = tempList;
        setUserLogDetail(tempLogDetail);
        dispatch(resetAlertComment());
      }
    }
  }, [addAlertComment]);


  useEffect(() => {
    if (alertDetails) {
      setUserLogDetail(!!alertDetails ? alertDetails.alert : null);
    }
  }, [alertDetails]);

  // const onAddComment = () => {
  //   if (!comment) {
  //     setErrors({ ...errors, comment: requiredMessage });
  //   } else {
  //     const addComment = {
  //       comment: comment,
  //     };
  //     dispatch(addAlertComment(id, addComment));
  //     setComment("");
  //   }
  // };

  // const onCommentChange = (e) => {
  //   setErrors({});
  //   setComment(e.target.value);
  // };

  // const renderError = (msg, style) => {
  //   return (
  //     <CustomErrorWrapper style={style}>
  //       <CustomError>{msg}</CustomError>
  //     </CustomErrorWrapper>
  //   );
  // };
  const renderLogBox = () => {
    return (""
      // <div style={{ marginTop: 0 }}>
      //   <CustomTextareaContainer
      //     style={{
      //       border: errors.comment ? `1px solid ${COLORS.redtext}` : "unset",
      //     }}
      //   >
      //     <CustomTextarea
      //       multiline
      //       variant={"standard"}
      //       minRows={4}
      //       placeholder="Add log entry (time will be registered automatically)..."
      //       style={{ width: "100%" }}
      //       value={comment}
      //       onChange={onCommentChange}
      //       error={errors.comment}
      //       InputProps={{
      //         disableUnderline: true,
      //       }}
      //     />
      //   </CustomTextareaContainer>
      //   {errors.comment && renderError(errors.comment, { width: "98%" })}
      //   <AddButtonContainer>
      //     <Buttons
      //       // variant="contained"
      //       style={AddButton}
      //       onClick={onAddComment}
      //       buttoncheck={buttonProps}
      //     >
      //       Add
      //     </Buttons>
      //   </AddButtonContainer>
      // </div>
    );
  };

  const renderLogTable = () => {
    return (
      <div
        style={{
          marginTop: "35px",
          // borderTop: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <LogPrint alertDetails={userLogDetail} ref={printRef} alertID={id} />
        <NameTravelIDBox style={{ width: "100%" }}>
          <Buttons
            style={{ width: "22%", fontSize: "16px", marginTop: "60px" }}
            buttoncheck={buttonProps}
            onClick={handlePrint}
          >
            Download
          </Buttons>

          {alertDetails && alertDetails.alert ? ALERT_STATUS.IN_PROGRESS === alertDetails.alert.alertStatus && <Buttons
            // variant="contained"
            style={{
              //   ...AddButton,
              //   width: "30%",
              //   fontSize: "16px",
              marginTop: "60px",

            }}
            buttoncheck={buttonProps}
            onClick={() => navigate(`/app/alert/alert-detail/${id}`)}
          >
            Open Alert Control
          </Buttons> : ''}
        </NameTravelIDBox>
      </div>
    );
  };

  return (
    <div style={{ marginTop: "30px", width: "78%" }}>
      {renderLogTable()}
    </div>
  );
}
