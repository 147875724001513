
import React from 'react';
import {
  Wrapper,
  List,
  Item,
  Text,
} from './style';
// import { text, app } from '../../../configs';
const prepages = [10, 20, 50, 100];

export default function AmountItems({ activePerPage, changePerPage, absolute, top }) {
  return (
    <Wrapper absolute={absolute} top={top}>
      <Text>Show</Text>
      <List>
        {
          prepages.map((item, idx) => (
            <Item
              style={{ listStyleType: 'none' }}
              key={idx}
              idx={item}
              activeItem={activePerPage}
              onClick={() => changePerPage(item)}
            >
              {item}
            </Item>
          ))
        }
      </List>
    </Wrapper>
  )
}
