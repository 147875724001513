
import * as React from 'react';
import { styled } from '@mui/material/styles';

import InputBase from '@mui/material/InputBase';
import { InputLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';





export const BootstrapCheckbox = styled(Checkbox)(({ theme }) => ({
  
    
  }));


  
  