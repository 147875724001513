import { ADD_ACTION_FOCUSBOARD, GET_ACTION_FOCUSBOARD, COMPLETE_ACTION_FOCUSBOARD, SET_ACTION_FOCUSBOARD_ID } from "../../@jumbo/constant/ActionTypes";
  
  const INIT_STATE = {
    allFocusBoard: [],
    // addUser:null,
    currentuser:null
  };

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_ACTION_FOCUSBOARD: {
        return {
          ...state,
          allFocusBoard: [action.payload, ...state.allUsers],
        };
      }
      case GET_ACTION_FOCUSBOARD: {
        return {
          ...state,
          allFocusBoard: action.payload,
        };
      }
      case COMPLETE_ACTION_FOCUSBOARD: {
        return {
          ...state,
          allFocusBoard: state.allFocusBoard.map(allFocusBoard => (allFocusBoard.id === action.payload._id ? action.payload : allFocusBoard)),
        };
      }
      case SET_ACTION_FOCUSBOARD_ID: {
        console.log("action.payload",action.payload)
        console.log("state",state)
        return {
          ...state,
          currentuser:action.payload
      };
    }
      default:
        return state;
    }
  };