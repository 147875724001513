// import { USER_PROFILE} from "../../@jumbo/constant/ActionTypes";
import { USER_PROFILE, EDIT_USER_PROFILE, CHANGE_PASSWORD, FORGOT_PASSWORD, SET_LOADER, GET_USER_DETAILS_UPDATE } from "../../@jumbo/constant/ActionTypes";

const INIT_STATE = {
  currentuser: null,
  loader: true,
  socektObj: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_PROFILE: {
      return {
        ...state,
        currentuser: action.payload,
      };
    }



    case GET_USER_DETAILS_UPDATE: {
      if (state.currentuser === null) {
        state.currentuser = {
          detail: {}
        }
      }
      const temp = {
        ...state,
        currentuser: {
          ...state?.currentuser,
          detail: {
            ...state?.currentuser?.detail,
            has_access_to: {
              alert_travel: action.payload.permission.alert_travel,
              in_house: action.payload.permission.in_house
            },
          }
        },
        // }
        socektObj: action.payload.permission
      }
      localStorage.setItem('profile', JSON.stringify(temp?.currentuser?.detail));
      return temp;
    }

    case SET_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case EDIT_USER_PROFILE: {
      return {
        ...state,
        currentuser: state.currentuser.map(currentuser => (currentuser.id === action.payload._id ? action.payload : currentuser)),
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        currentuser: [action.payload, ...state.currentuser],
      };
    }
    case FORGOT_PASSWORD: {
      return {
        ...state,
        currentuser: [action.payload, ...state.currentuser],
      };
    }
    default:
      return state;
  }
};
