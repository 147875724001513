import axios from 'axios';
import { authHeader } from 'services/helpers/auth-helpers';

const customAxios = axios.create({
  // baseURL: `https://node.inheritxdev.in/cc-log-qa/api/`, //YOUR_API_URL HERE
  // baseURL: `https://node.inheritxdev.in/cc-log-prod/api/`, //YOUR_API_URL HERE
  // baseURL: `https://node.inheritxdev.in/cc-log-dev/api/`, //YOUR_API_URL HERE
  // baseURL: `https://sodev.inheritxdev.in/api/`, //YOUR_API_URL HERE
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
    "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization",
    // ...authHeader(),
  },
});
export default customAxios;

