import React from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { DetailHeader, CheckInDestinationHeader, CheckInDestinationCell, NoData } from '../style'
import { checkInDestinationCols } from '../data'
import CheckInDestinationRow from "./CheckInDestinationRow";

export default function CheckInDestination({ checkInDestinationData }) {
    const renderTableHeader = () => {
        return (
            <TableHead>
                <TableRow sx={CheckInDestinationHeader}>
                    {checkInDestinationCols.map((col) => <TableCell key={col.id} style={{width: col.width && col.width}}>{col.label}</TableCell>)}
                </TableRow>
            </TableHead>
        )
    }

    const renderTableBody = () => {
        return (
            <TableBody>
                {console.log("checkInDestinationData",checkInDestinationData)}
                {checkInDestinationData && checkInDestinationData.length ? checkInDestinationData.map((data, idx) => <CheckInDestinationRow data={data} index={idx} sx={CheckInDestinationCell} />) :
                    <TableRow><TableCell colSpan={4}><NoData>{'No Record found'}</NoData></TableCell></TableRow>}
            </TableBody>
        )
    }

    const renderTable = () => {
        return (
            <Table className="custome-tbl">
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        )
    }

    return (
        <div style={{ marginTop: '35px', width: '78%' }}>
            <DetailHeader>Check In Destinations</DetailHeader>
            {renderTable()}
        </div>
    )
}