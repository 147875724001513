import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "./addEditContact.css";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getCurrTenantDetail } from "redux/actions/tenant"
// import "antd/dist/antd.css";
import {
  requiredMessage,
  lettersOnlyMessage,
  onlyUrl
} from "@jumbo/constant/ErrorMessages";
import {
  stringOnly,
  emptySpace,
  stringCheck,
  linkRegexp
} from "@jumbo/constant/ValidationRegex";
import { addusefulLinktravel, getUsefulLinkForTravel, update_useful_link_for_travel } from "redux/actions/usefulLinkforTravel";
import {
  useParams,
} from "react-router-dom";
import { TextFields } from "../../../Textfeild";
import { Buttons } from "../../../Button";
import AddConfirmDialog from "../../../AddConfirmDialog";
const ariaLabel = { "aria-label": "description" };


const useStyles = styled((theme) => ({
  dialogRoot: {
    position: "relative",
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark,
      textOverflow: "ellipsis",
      maxWidth: "350px",
      display: "block",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  dialogActionsRoot: {
    // padding: '16px 24px',
    // align:'center'
  },
}));

function AddEditLink({ open, onClose, onConfirm, setOpenPassConfirmDialog, butttonValue, setButttonValue }) {
  // alert(butttonValue,setButttonValue)
  let { id } = useParams();
  const { currentDetails } = useSelector(({ USEFULLINKTRAVEL }) => USEFULLINKTRAVEL);
  let classes = useStyles()
  let navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  const [linkError, setLinkError] = useState("");
  const [titleError, setTitleError] = useState('')
  const [helpetText, setHelperText] = useState(false)
  // const [dialogOpen, setDialogOpen] = useState(true)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);


  const dispatch = useDispatch();
  const user_type = 3;

  useEffect(() => {
    if (id) {
      dispatch(getCurrTenantDetail(id));
    }
  }, [id])

  useEffect(() => {
    if (currentDetails) {
      setTitle(!!currentDetails ? currentDetails.name : null)
      setLink(!!currentDetails ? currentDetails.link : null)
      // setEmailId(!!currentDetails ? currentDetails.email:null)
    }
  }, [currentDetails]);


  const handleSaveBtn = () => {

    if (!title || title.match(emptySpace)) {
      setHelperText(true)
      setTitleError(requiredMessage);
    } else if (!title.match(stringCheck)) {
      setHelperText(true)
      setTitleError(lettersOnlyMessage);
    }
    if (!link || link.match(emptySpace)) {
      setHelperText(true)
      setLinkError(requiredMessage);
    } else if (!link.match(linkRegexp)) {
      setHelperText(true)
      setLinkError(onlyUrl);
    }
    if (

      title && title.match(stringCheck) &&
      !title.match(emptySpace) &&
      link && link.match(linkRegexp) &&
      !link.match(emptySpace)
    ) {


      // setInhous_alert(obje);
      onUserSave();
    }
  };
  const onUserSave = () => {
    const useful_link_for_travel_id = !!currentDetails ? currentDetails._id : null;
    const user = {
      'title': title,
      'link': link
    };
    const userEdit = {
      'title': title,
      'link': link
    };
    if (currentDetails) {
      dispatch(update_useful_link_for_travel(useful_link_for_travel_id, userEdit, { setAddDialog: () => setOpenPassConfirmDialog(true) }, onClose));
      // getUsefulLinkForTravel
    } else {
      dispatch(addusefulLinktravel(user, { setAddDialog: () => setOpenPassConfirmDialog(true) }, onClose));
      // getUsefulLinkForTravel
    }
  };

  const handleCancelDelete = (user) => {

    setOpenConfirmDialog(false);
    // dispatch(onLogout(()=>navigate('/')))
    // navigate('/')
  };
  const handleConfirmDelete = () => {
    // alert(user)
    // dispatch(onLogout(()=>navigate('/')))
    setOpenConfirmDialog(false);
    // navigate('/')
  };
  return (
    // <div className='dialogBox'>

    <Dialog open={open} onClose={onClose} className={classes.dialogRoot} style={{ maxWidth: '550px', margin: '0 auto' }}>
      {/* <CloseIcon className="icon" onClick={onClose} /> */}
      <i className="icon-close icon" onClick={onClose}></i>
      <DialogTitle>  {currentDetails ? 'Edit Link' : 'New Link'}</DialogTitle>
      <DialogContent>
        <TextFields
          // autoFocus
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          variant="standard"
          value={title}
          // placeholder="Type Current Password"
          onChange={(event) => {
            setTitle(event.target.value);
            setTitleError("");
            setHelperText(false)
          }}
          helperText={titleError}
          id="title"
          style={{ marginBottom: '15px' }}
          helperProps={titleError ? helpetText : false}
          errorProps={titleError ? true : false}
        />
        <TextFields
          // autoFocus
          margin="dense"
          id="link"
          label="Link"
          type="text"
          fullWidth
          variant="standard"
          // placeholder="Type New Password"
          value={link}
          onChange={(event) => {
            setLink(event.target.value);
            setLinkError("");
            setHelperText(false)
          }}
          helperText={linkError}
          style={{ marginBottom: '15px' }}

          helperProps={linkError ? helpetText : false}
          errorProps={linkError ? true : false}
        />
      </DialogContent>

      {/* <DialogActions > */}

      <Buttons
        // className="changePasswordBtn"

        style={{
          // background: 'rgb(72, 146, 195) ',
          // textTransform: 'capitalize ',
          // padding: '15px ',
          // width:' 100% ',
          // marginTop: '40px ',
          // lineHeight: '1.2 ',
          borderRadius: '8px ',
          // boxShadow: 'rgb(98 132 152 / 33%) 0px 11px 26px -10px ',
          // fontFamily: "SF Pro Text" ,
          // fontSize: '16px ',
          // maxWidth: "192px",
          // color:'white',

          height: "49px",
          // marginBottom: "40px",
          margin: ' 20px auto 65px',


        }}
        type="submit"
        buttoncheck={true}
        // onClick={() => {
        //   handleChangePass();
        //   // onConfirm();
        // }}
        onClick={handleSaveBtn}
      >
        {currentDetails ? 'Save' : 'Add'}
        {/* Add */}
      </Buttons>

      {/* </DialogActions> */}
      {/* <ConfirmDialog
        open={openConfirmDialog}
        // title={`Delete Subdivision`}
        content={`All changes saved successfully. `}
        btnLabels={{
          ok: "ok",
        }}
        onClose={() =>handleCancelDelete(true)}
        onConfirm={handleConfirmDelete}
      /> */}
      <AddConfirmDialog
        open={openConfirmDialog}
        // title={`Delete Subdivision`}
        content={`All changes saved successfully. `}
        btnLabels={{
          ok: "Ok",

        }}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Dialog>

    // </div>
  );
}
export default AddEditLink;
