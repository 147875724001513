import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { FormControl, Input, InputAdornment, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { emptySpace } from "@jumbo/constant/ValidationRegex";
// import { emailvalidation } from "@jumbo/constant/ValidationRegex";
import { emailNotValid, requiredMessage } from "@jumbo/constant/ErrorMessages";
import { userForgotPassword } from "redux/actions/Settings";
import { useDispatch } from "react-redux";
import ConfirmDialog from "components/ConfirmDialog";
import Header from "applayout/header";
import { Buttons } from "../Button";
// import AddConfirmDialog from "../AddConfirmDialog";
import { TextFields } from "../Textfeild";
import AddForgotDialog from "../AddForgotDialog";
import { isValidEmail } from "utils/commonHelper";
import AddConfirmDialog from "../AddConfirmDialog";

export default function ForgotPassword() {

  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [helperText, setHelperText] = useState(false);
  const dispatch = useDispatch();
  let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let checkEmail = /^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/

  function emailValidation(emailId) {
    //eslint-disable-next-line
    // const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailId || emailId.match(emptySpace)) {
      setHelperText(true)
      setEmailIdError(requiredMessage);
    } else if (!isValidEmail(emailId)) {
      setHelperText(true)
      setEmailIdError(emailNotValid);
      return false;
    } else {
      setEmailIdError("");
      return true;
    }
  }


  const handleReset = () => {

    // if (!emailId || emailId.match(emptySpace)) {
    //   setEmailIdError(requiredMessage);
    // } 
    // // else if (!isValidEmail(email)) {
    // //   setEmailIdError(emailNotValid);
    // // }
    // else if(checkEmail != emailId){
    //     setEmailIdError(emailNotValid);

    // }
    let emailVal = emailValidation(emailId);

    // if (emailId &&
    //   !emailId.match(emptySpace) && checkEmail === emailId) {
    //     onUserSave();
    // }
    if (emailVal) {
      onUserSave();
    }
  }

  const onUserSave = () => {


    const user = {
      email: emailId
    };
    dispatch(userForgotPassword(user, { setAddDialog: () => setOpenConfirmDialog(true) }, () => { setEmailId('') }));


  }

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
  };
  return (
    <>
      <div className="login-container">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
            height: "85vh",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <p

            style={{
              color: "rgb(92, 99, 115)",
              // fontWeight: 'bold',
              textAlign: "center",
              fontSize: "26px",
              marginBottom: "82px",
              fontFamily: 'SFTextSemibold'
            }}
          >
            Forgot-Password
          </p>
          <FormControl>
            <Box sx={{ display: "flex", alignItems: "flex-start" }} className='loginFeild' >
              {/* <MailOutlineIcon sx={{ color: "rgb(204 188 188 / 54%)", mr: 1, my: 0.2 }} fontSize='1.5rem' /> */}
              <i class="icon-envelope" style={{ color: "rgb(174, 180, 192)", marginTop: '24px' }}></i>
              <TextFields
                // id="input-with-sx"
                label="Enter your email"
                // variant="standard"
                //  style={{width:'150%'}}
                value={emailId}
                onChange={(e) => {
                  setEmailId(e.target.value);
                  setEmailIdError("");
                  setHelperText(false)
                }}
                helperText={emailIdError}
                helperProps={emailIdError ? helperText : false}
                errorProps={emailIdError ? true : false}
              // error={emailIdError?true:false}

              />
            </Box>


            {/* <Button
          variant="contained"
          type="submit"
          onClick={handleReset}
          style={{
            marginTop: "25px",
            padding: "15px",
            opacity: "0.9",
          }}
        >
          Reset Password
        </Button> */}
            <Buttons onClick={handleReset} style={{ marginLeft: 0, marginTop: '40px', width: '100%' }} buttoncheck={true}>Reset password</Buttons>
            <br />
          </FormControl>
          <AddConfirmDialog
            open={openConfirmDialog}
            // title={`Delete Subdivision`}
            content={`Check your mail, please `}
            btnLabels={{
              ok: "Ok",
            }}
            onClose={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />

        </Box>
      </div>
    </>
  );
}
