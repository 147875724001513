import styled from "styled-components";
import { Tabs, Tab, TextField, TableRow, Button } from "@mui/material"

export const CrisisContainer = styled.div`
`

export const HeaderContainer = styled.div`
    width: 100%;
`

export const HeaderText = styled.h1`
    font-family: SFTextSemibold;
    text-align: left;
    font-size: 22px;
    width: 100%;
    color: rgb(92, 99, 115);
    margin: 0 0 10px;
`

export const EventCountText = styled.div`
    font-family: SFTextMedium;
    font-size: 15px;
    color: rgb(92, 99, 115);
    margin-top: 10px;
`

export const HeaderSubText = styled.div`
    font-family: SFTextLight;
    text-align: left;
    font-size: 15px;
    color: rgb(174, 180, 192);
    width: 100%;
    line-height: 1.3;
    margin-top: 5px;
`

export const CustomTab = styled(Tab)({
    textTransform: 'none !important',
    minWidth: '40px !important',
    display: '-webkit-inline-box !important',
    padding: '15px 0px !important',
    marginRight: '100px !important',
    textAlign: 'left !important',
    fontFamily: 'SF Pro Text !important',
    fontSize: '15px !important',
    fontWeight: '500',
    /*'&.Mui-selected': {
        color: 'rgb(225, 108, 108) !important'
    },*/
    '&.Mui-selected': {
        color: 'rgb(78, 145, 192) !important',
        fontFamily: 'SF Pro Text !important',

    },
});

export const CustomTabs = styled(Tabs)({
    borderBottom: '1px solid rgb(229 230 233) !important',
    '& .MuiTabs-indicator': {
        backgroundColor: ' rgb(78, 145, 192) !important',
        height: '1px !important'
    },
    '& .MuiTab-root':{
        color:'rgb(144, 153, 170)',
        fontFamily: 'SF Pro Text !important',
        
    },
});

export const AmountItemsContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 52%;
    margin-bottom: 15px;
`

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
`

export const Bullet = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 2px;
`

export const DetailHeader = styled.div`
    font-family: SFTextSemibold;
    text-align: left;
    font-size: 22px;
    width: 100%;
    color: rgb(92, 99, 115);
`

export const CustomTextareaContainer = styled.div`
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    -webkit-box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    -moz-box-shadow: rgb(0 0 0 / 0.2) 0px 0px 5px 0px;
    padding: 15px;
`

export const CustomTextarea = styled(TextField)({
    // fontFamily: 'SFTextLight',
    // fontSize: '12px',
    '& .MuiInput-input': {
        fontSize: '12px',
        fontFamily: 'SFTextLight',
        color:'rgb(0,0,0)'
    }
})

export const AddButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 15px 0px;
`

export const AddButton = {

    minWidth: '100px',
    backgroundColor: 'rgb(72, 146, 195)',
    padding: '9px 13px',
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: "SF Pro Text",
}

export const crisisLogTable = {
    fontSize: '15px',
    fontFamily: 'SFTextMedium'
}

export const CrisisTravelRow = styled(TableRow)({
    backgroundColor: 'rgb(247, 249, 252)',
    border: '1px solid rgb(197, 219, 235)',
    borderRadius: '5px'
})

export const CheckInDestinationHeader = {
    '&:last-child th': { paddingRight: '0 !important' },
    '&:first-child th': { paddingLeft: '0 !important' }
}

export const CheckInDestinationCell = {
    'td': { paddingRight: '0 !important' },
    'td': { paddingLeft: '0 !important' }
}

export const UserNameContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 22px;
    color: rgb(92, 99, 115);
    font-family: 'SF Pro Text'
`

export const TravelIdContainer = styled.div`
    padding: 10px 50px 10px 10px;
    border: 0.5px solid rgb(168, 201, 224);
    border-radius: 8px;
    background-color: rgb(247, 249, 252);
    font-family: 'SF Pro Text';
    color: rgb(92, 99, 115);
    font-size: 13px;
    
`

export const NameTravelIDBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 78%;
    margin: 35px 0 40px 0;
`

export const printHeaderStyle = `
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100%;
    padding: 10px 0;
    font-size: 12px;
    color: #000;
    page-break-after: always;`

export const printFooterStyle = `
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    padding: 10px 0;
    color: #000;
    page-break-after: always;`

export const headerInnerEleStyle = `
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    padding-bottom: 40px;
`
export const testModeStyle=`
margin-bottom: 30px;

color: rgb(92, 99, 115);
    font-family: SFTextLight;
`
export const NoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CustomErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
`

export const CustomError = styled.div`
    font-size: 14px;
    color: #D32F2F;
`