import React, { useState, useEffect } from "react";
import { Buttons } from "../Button";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Add as AddIcon } from '@mui/icons-material'
import { Grid, Box } from '@mui/material'
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, updateUserProfile } from "redux/actions/Settings";
import { requiredMessage, lettersOnlyMessage, phoneOnlyNumericals, emailNotValid } from "@jumbo/constant/ErrorMessages";
import { emptySpace, stringCheck } from "@jumbo/constant/ValidationRegex";
import { setUsefulLinkForTravel } from "redux/actions/usefulLinkforTravel";
import UsefulLinkForTravel from "./UsefulLinkForTravel";
import ImportantContact from "./importantContact";
import LocalPoliceNumber from "./LocalPoliceNumber";
import AddConfirmDialog from "../AddConfirmDialog";
import ChangePassword from "./ChangePassword";
import { CustomTabs, CustomTab } from "./style";
import { TextFields } from "../Textfeild";
import { ButtonCancel } from "../cancelButton";
import { SET_LOCAL_POLICE_NUMBER, USER_PROFILE, SEC_SUBDIV } from "@jumbo/constant/ActionTypes";
import { resetImportList } from "redux/actions/importantContact";
import { getImportantContactList, setCurrentImportContact } from "redux/actions/importantContact";
import { USER_TYPE } from "utils/commonHelper";
import { AntSwitch } from "../../components/antSwitch/AntSwitch";
// import {getImportantContactList} from 'redux/actions/importantContact'

const TabPanel = ({ index, children, tabIndex }) => {
    return (
        <div
            role="tabpanel"
            hidden={tabIndex !== index}
            id={`tabpanel-${index}`} style={{ marginBottom: "5px" }}>
            {tabIndex === index && children}

        </div>
    )
}

export default function Settings() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const search = useLocation().search;
    const Profile = JSON.parse(localStorage.getItem("profile"));
    const subdiv_user = Profile.user_type === USER_TYPE.SUBDIVISION_USER
    const control_user = Profile.user_type === USER_TYPE.EXTERNAL_CONTROLLER
    const tabId = new URLSearchParams(search).get('tab');
    const [tabIndex, setTabIndex] = React.useState(0);
    const [butttonValue, setButttonValue] = useState('Add contact')
    const [open, setOpen] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openPassConfirmDialog, setOpenPassConfirmDialog] = useState(false);
    const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailId, setEmailId] = useState("");
    const [numberOflicences, setNumberOflicences] = useState("");
    const [subdivisionName, setSubdivisionName] = useState("");
    const [groupCode, setGroupCode] = useState("");
    const [address, setAddress] = useState("");
    const [disableButton, setDisableButton] = useState(false);
    const [disable, setDisable] = useState(true);
    const [fnameError, setFnameError] = useState("");
    const [lnameError, setLnameError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [emailIdError, setEmailIdError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [companyLogo, setCompanyLogo] = useState('')
    const [companyLogoError, setCompanyLogoError] = useState('')
    const [helpetText, setHelperText] = useState(false)
    const [oneTimeApiCall, setOneTimeApiCall] = useState(false)
    const [smsTurnOnOff, setSmsTurnOnOff] = useState(true)
    const [pushNotification, setPushNotification] = useState(true)
    const [disableSmsButton, setDisableSmsButton] = useState(true)
    const { currentuser } = useSelector(({ USERPROFILE }) => USERPROFILE);
    let email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let number = /^([0-9])*$/;
    let phone = /^(\+)([0-9]{9,15})$/;

    const buttonText = ["Add contact", "Add number", "Add link"]
    // const Profile = JSON.parse(localStorage.getItem("profile"));

    const checkUser = !!Profile ? Profile.user_type : null;

    useEffect(() => {
        // dispatch({ type: USER_PROFILE, payload: [] });
        dispatch(getUserProfile())
        localStorage.removeItem("sec_subdiv_id");
        dispatch({ type: SEC_SUBDIV, payload: "" });
    }, []);

    useEffect(() => {
        if (tabId !== undefined && tabId !== null) {
            setTabIndex(Number(tabId))
            setButttonValue(buttonText[Number(tabId)])
        }
    }, [tabId])

    useEffect(() => {
        if (Profile.user_type !== 3 && Profile.user_type !== 1 && Profile.user_type !== 5 && Profile.user_type !== 2) {
            dispatch(getImportantContactList())
        }
    }, [tabIndex])


    useEffect(() => {
        if (currentuser?.detail && currentuser?.detail?.user_type === 4) {
            setFname(currentuser?.detail?.first_name);
            setLname(currentuser.detail.last_name);
            setPhoneNumber(currentuser.detail.mobile);
            setGroupCode(currentuser.detail.group_code);
            setSubdivisionName(currentuser.detail.firm_name);
            setNumberOflicences(currentuser.detail.number_of_licenses);
            setEmailId(currentuser.detail.email);
            setAddress(currentuser.detail.address);
            setSmsTurnOnOff(currentuser.detail.sms_switch)
            setPushNotification(currentuser.detail.notification_switch)
            // setCompanyLogo
        }
        else if (currentuser?.detail) {
            setFname(currentuser.detail.first_name);
            setLname(currentuser.detail.last_name);
            setPhoneNumber(currentuser.detail.mobile);
            setGroupCode(currentuser.detail.group_code);
            setSubdivisionName(currentuser.detail.firm_name);
            setNumberOflicences(currentuser.detail.number_of_licenses);
            setEmailId(currentuser.detail.email);
            setAddress(currentuser.detail.address);
        }
    }, [currentuser?.detail]);

    const handleSave = () => {
        if (phoneNumber && currentuser?.detail?.user_type === 3) {
            console.log("check 1")
            if (!fname || fname.match(emptySpace)) {
                setHelperText(true)
                setFnameError(requiredMessage);
            } else if (!fname.match(stringCheck)) {
                setHelperText(true)
                setFnameError(lettersOnlyMessage);
            }
            if (!lname || lname.match(emptySpace)) {
                setHelperText(true)
                setLnameError(requiredMessage);
            } else if (!lname.match(stringCheck)) {
                setHelperText(true)
                setLnameError(lettersOnlyMessage);
            }
            if (!phoneNumber) {
                setHelperText(true)
                setPhoneNumberError(requiredMessage);
            } else if (!phoneNumber.match(phone)) {
                setHelperText(true)
                setPhoneNumberError(phoneOnlyNumericals);
            }
            if (!emailId || emailId.match(emptySpace)) {
                setHelperText(true)
                setEmailIdError(requiredMessage);
            } else if (!emailId.match(email)) {
                setHelperText(true)
                setEmailIdError(emailNotValid);
            }

            if (currentuser?.detail?.user_type === 3 &&
                fname &&
                fname.match(stringCheck) &&
                !fname.match(emptySpace) &&
                lname &&
                lname.match(stringCheck) &&
                !lname.match(emptySpace) &&
                phoneNumber && phoneNumber.match(phone) && !phoneNumber.match(emptySpace) &&
                emailId &&
                emailId.match(email) &&
                !emailId.match(emptySpace)) {
                onUserSave();
            }
        }
        else if (!phoneNumber && currentuser?.detail?.user_type === 3) {
            console.log("check 2")
            if (!fname || fname.match(emptySpace)) {
                setHelperText(true)
                setFnameError(requiredMessage);
            } else if (!fname.match(stringCheck)) {
                setHelperText(true)
                setFnameError(lettersOnlyMessage);
            }
            if (!lname || lname.match(emptySpace)) {
                setHelperText(true)
                setLnameError(requiredMessage);
            } else if (!lname.match(stringCheck)) {
                setHelperText(true)
                setLnameError(lettersOnlyMessage);
            }
            if (!emailId || emailId.match(emptySpace)) {
                setHelperText(true)
                setEmailIdError(requiredMessage);
            } else if (!emailId.match(email)) {
                setHelperText(true)
                setEmailIdError(emailNotValid);
            }

            if (currentuser?.detail?.user_type === 3 && !phoneNumber &&
                fname &&
                fname.match(stringCheck) &&
                !fname.match(emptySpace) &&
                lname &&
                lname.match(stringCheck) &&
                !lname.match(emptySpace) &&
                emailId &&
                emailId.match(email) &&
                !emailId.match(emptySpace)) {
                onUserSave();
            }
        }
        else {
            console.log("check 3")
            if (!fname || fname.match(emptySpace)) {
                setHelperText(true)
                setFnameError(requiredMessage);
            } else if (!fname.match(stringCheck)) {
                setHelperText(true)
                setFnameError(lettersOnlyMessage);
            }
            if (!lname || lname.match(emptySpace)) {
                setHelperText(true)
                setLnameError(requiredMessage);
            } else if (!lname.match(stringCheck)) {
                setHelperText(true)
                setLnameError(lettersOnlyMessage);
            }
            if (!phoneNumber || phoneNumber.match(emptySpace)) {
                setHelperText(true)
                setPhoneNumberError(requiredMessage);
            } else if (!phoneNumber.match(phone)) {
                setHelperText(true)
                setPhoneNumberError(phoneOnlyNumericals);
            }
            if (!emailId || emailId.match(emptySpace)) {
                setHelperText(true)
                setEmailIdError(requiredMessage);
            } else if (!emailId.match(email)) {
                setHelperText(true)
                setEmailIdError(emailNotValid);
            }

            if (currentuser?.detail?.user_type !== 3 &&
                fname &&
                fname.match(stringCheck) &&
                !fname.match(emptySpace) &&
                lname &&
                lname.match(stringCheck) &&
                !lname.match(emptySpace) &&
                phoneNumber && phoneNumber.match(phone) && !phoneNumber.match(emptySpace) &&
                emailId &&
                emailId.match(email) &&
                !emailId.match(emptySpace)) {
                onUserSave();
            }
        }

        // if (currentuser?.detail?.user_type !== 3 &&
        // fname &&
        // fname.match(stringCheck) &&
        // !fname.match(emptySpace) &&
        // lname &&
        // lname.match(stringCheck) &&
        // !lname.match(emptySpace) && 
        // phoneNumber && phoneNumber.match(phone) && !phoneNumber.match(emptySpace) &&
        // emailId &&
        // emailId.match(email) &&
        // !emailId.match(emptySpace)) 
        // {
        // onUserSave();
        // }

        // else if(currentuser?.detail?.user_type === 3 && !phoneNumber &&
        // fname &&
        // fname.match(stringCheck) &&
        // !fname.match(emptySpace) &&
        // lname &&
        // lname.match(stringCheck) &&
        // !lname.match(emptySpace) && 
        // emailId &&
        // emailId.match(email) &&
        // !emailId.match(emptySpace))
        // {
        //     onUserSave();
        // }

        // else if(currentuser?.detail?.user_type !== 3 &&
        //     fname &&
        //     fname.match(stringCheck) &&
        //     !fname.match(emptySpace) &&
        //     lname &&
        //     lname.match(stringCheck) &&
        //     !lname.match(emptySpace) && 
        //     phoneNumber && phoneNumber.match(phone) && !phoneNumber.match(emptySpace) &&
        //     emailId &&
        //     emailId.match(email) &&
        //     !emailId.match(emptySpace))
        //     {
        //         onUserSave();
        //     }

    };

    const onUserSave = () => {
        // let user
        let formData = new FormData();

        if (Profile?.user_type === 4) {
            formData.append('user_id', !!currentuser ? currentuser.detail._id : null)
            formData.append('email', emailId)
            formData.append('first_name', fname)
            formData.append('last_name', lname)
            formData.append('mobile', phoneNumber)
            formData.append('address', address)
            formData.append('sms_switch', smsTurnOnOff)
            formData.append('notification_switch', pushNotification)
            formData.append('logoImage', companyLogo);
        } else {
            formData.append('user_id', !!currentuser ? currentuser.detail._id : null)
            formData.append('email', emailId)
            formData.append('first_name', fname)
            formData.append('last_name', lname)
            formData.append('mobile', phoneNumber)
            formData.append('address', address)
            formData.append('logoImage', companyLogo);
        }

        // {
        //     Profile?.user_type === 4 ? (
        //         user = {
        //             user_id: !!currentuser ? currentuser.detail._id : null,
        //             email: emailId,
        //             first_name: fname,
        //             last_name: lname,
        //             mobile: phoneNumber,
        //             address: address,
        //             sms_switch: smsTurnOnOff,
        //             notification_switch: pushNotification
        //         } 
        //     ):
        //         user = {
        //             user_id: !!currentuser ? currentuser.detail._id : null,
        //             email: emailId,
        //             first_name: fname,
        //             last_name: lname,
        //             mobile: phoneNumber,
        //             address: address
        //         };
        // }

        dispatch(
            updateUserProfile(
                formData,
                {
                    setAddDialog: () => setOpenConfirmDialog(true),
                    setOpen: () => setOpen(false),
                    setDisable: () => setDisable(true),
                    setDisableSmsButton: () => setDisableSmsButton(true),
                    // get_user_profile: ()=> dispatch(getUserProfile())
                },
            )
        );

    };

    const handleEdit = () => {
        setOpen(true);
        setDisableButton(true);
        setDisable(false);
        setDisableSmsButton(false)
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setCompanyLogo(file);
    };

    const changePasswordHandler = () => {
        setOpenPassConfirmDialog(true);
    };

    const handleCancelDelete = () => {
        dispatch(getUserProfile())
        setOpenConfirmDialog(false);
    };
    const handleConfirmDelete = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirmChangePass = () => {
        setOpenPassConfirmDialog(false);
    };

    const handleCancelChangePass = () => {
        setOpenPassConfirmDialog(false);
    };

    const handleConfirmAddEdit = () => {
        setOpenAddEditDialog(false);
    };

    const handleCancelAddEdit = () => {
        setOpenAddEditDialog(false);
    };

    const handleEditCancel = () => {
        setFname(currentuser.detail.first_name)
        setLname(currentuser.detail.last_name)
        setPhoneNumber(currentuser.detail.mobile)
        setEmailId(currentuser.detail.email)
        setOpen(false);
        setDisable(true);
        setDisableSmsButton(true)
        // setHelperText(false)
        setFnameError('')
        setLnameError('')
        setPhoneNumberError('')
        setEmailIdError('')
        setAddressError('')
        setSmsTurnOnOff(currentuser.detail.sms_switch)
        setPushNotification(currentuser.detail.notification_switch)
        setCompanyLogo('')
    };

    const renderContacts = () => {
        return (
            <ImportantContact
                open={openAddEditDialog}
                onClose={handleCancelAddEdit}
                onConfirm={handleConfirmAddEdit}
                setOpenPassConfirmDialog={setOpenAddEditDialog}
                butttonValue={butttonValue}
                setButttonValue={setButttonValue}
            />
        )
    }

    const renderLocalPoliceNumber = () => {
        return (
            <LocalPoliceNumber
                open={openAddEditDialog}
                onClose={handleCancelAddEdit}
                onConfirm={handleConfirmAddEdit}
                setOpenPassConfirmDialog={setOpenAddEditDialog}
                butttonValue={butttonValue}
                setButttonValue={setButttonValue}
            />
        )
    }

    const renderUsefulLinks = () => {
        return (
            <UsefulLinkForTravel
                open={openAddEditDialog}
                onClose={handleCancelAddEdit}
                onConfirm={handleConfirmAddEdit}
                setOpenPassConfirmDialog={setOpenAddEditDialog}
                butttonValue={butttonValue}
                setButttonValue={setButttonValue}
            />
        )
    }

    const renderMainInformation = () => {
        return (
            <div className="mainDiv1">
                <Box
                    component="form"
                    sx={{
                        "& > :not(style)": {
                            width: "50ch",
                            width: "100%",
                            // mt:"5px"
                        },
                        flexGrow: 1,
                    }}
                    noValidate
                    autoComplete="off"
                    id='boxid'>
                    <Grid container spacing={2} ml={0} className="formGrid" style={{ marginTop: "35px" }}>
                        <Grid item xs={12} mb={2} className="gridText">
                            <TextFields
                                id="standard-basic"
                                label="First Name"
                                variant="standard"
                                disabled={disable}
                                // disabled={disableButton}
                                value={fname}
                                onChange={(e) => {
                                    setFname(e.target.value.slice(0, 16));
                                    setFnameError("");
                                    setHelperText(false)
                                }}
                                helperText={fnameError}
                                helperProps={fnameError ? helpetText : false}
                                errorProps={fnameError ? true : false}
                            />
                        </Grid>

                        <Grid item xs={12} mb={2} className="gridText">
                            <TextFields
                                id="standard-basic"
                                label="Last Name"
                                variant="standard"
                                disabled={disable}
                                value={lname}
                                onChange={(e) => {
                                    setLname(e.target.value.slice(0, 16));
                                    setLnameError("");
                                    setHelperText(false)
                                }}
                                helperText={lnameError}
                                helperProps={lnameError ? helpetText : false}
                                errorProps={lnameError ? true : false}

                            />
                        </Grid>
                        <Grid item xs={12} mb={2} className="gridText">
                            <TextFields
                                id="standard-basic"
                                label="Phone Number"
                                variant="standard"
                                disabled={disable}
                                value={phoneNumber}
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                    setPhoneNumberError("");
                                    setHelperText(false)
                                }}
                                helperText={phoneNumberError}
                                helperProps={phoneNumberError ? helpetText : false}
                                errorProps={phoneNumberError ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2} className="gridText">
                            <TextFields
                                id="standard-basic"
                                label="Email"
                                disabled={disable}
                                variant="standard"
                                value={emailId}
                                onChange={(e) => {
                                    setEmailId(e.target.value);
                                    setEmailIdError("");
                                    setHelperText(false)
                                }}
                                helperText={emailIdError}
                                helperProps={emailIdError ? helpetText : false}
                                errorProps={emailIdError ? true : false}
                            />
                        </Grid>
                    </Grid>
                    {Profile.user_type === 1 ? (
                        <Grid container ml={0} className="formGrid" mt={0}>
                            <Grid item xs={12} mb={2} className="gridText">
                                <TextFields
                                    id="standard-basic"
                                    label="Address"
                                    variant="standard"
                                    disabled={disable}
                                    htmlFor="component-disabled"
                                    value={address}
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                        setAddressError("");
                                        setHelperText(false)
                                    }}
                                    helperText={addressError}
                                    helperProps={addressError ? helpetText : false}
                                    errorProps={addressError ? true : false}

                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Grid container ml={0} className="formGrid" >
                                <Grid item xs={12} mb={2} className="gridText">
                                    <TextFields
                                        disabled
                                        id="standard-basic"
                                        label="Group code"
                                        variant="standard"
                                        value={groupCode}
                                    />
                                </Grid>
                                {Profile?.user_type !== 5 ?
                                    <Grid item xs={12} mb={2} className="gridText">
                                        <TextFields
                                            disabled
                                            id="standard-basic"
                                            label="Number of licenses"
                                            variant="standard"
                                            value={`${!!numberOflicences ? numberOflicences.used : 0} / ${!!numberOflicences ? numberOflicences.assigned : 0}`}
                                        />
                                    </Grid>
                                    : null}

                                {(Profile?.user_type !== 5 && Profile?.user_type !== 7) ?
                                    <Grid item xs={12} mb={2} className="gridText">
                                        <TextFields
                                            disabled
                                            id="standard-basic"
                                            label="Firm Name"
                                            variant="standard"
                                            value={subdivisionName}
                                        />
                                    </Grid>
                                    : null}

                                {open && Profile?.user_type === 3 ?
                                    <Grid item xs={12} mb={2} className="gridText">
                                        <Button variant="contained" component="label">
                                            <div style={{ marginRight: '10px' }}><CloudUploadIcon /></div>
                                            <p className="imageButton">{companyLogo?.size ? companyLogo?.name : 'Company LOGO'}</p>
                                            <input
                                                type="file"
                                                hidden
                                                onChange={handleImageUpload}
                                                accept="image/png, image/jpeg"
                                            />
                                        </Button>{' '}
                                        {currentuser?.detail && companyLogo ? <img id="logo" src={URL.createObjectURL(companyLogo)} style={{ marginTop: '15px', maxHeight: "170px" }} width="150px" /> : ''}
                                    </Grid> : null}

                                {Profile?.user_type === 4 ?
                                    <Grid container spacing={2} ml={0} mt={2} className="formGrid">

                                        <Grid
                                            item
                                            xs={6}
                                            mb={2}
                                            style={{ position: "relative" }}
                                            className="gridText"
                                        >

                                            <TextFields
                                                id="standard-basic"
                                                variant="standard"
                                                value="SMS"
                                                textProps={true}
                                                disabled
                                            />

                                            <AntSwitch
                                                value={smsTurnOnOff}
                                                onChange={() => {
                                                    setSmsTurnOnOff(!smsTurnOnOff);
                                                }}
                                                checked={smsTurnOnOff}
                                                checkTrue={disable}
                                                inputProps={{ "aria-label": "ant design" }}
                                                disabled={disable}
                                            />
                                        </Grid>
                                        <Grid item xs={6} mb={2} style={{ position: "relative", display: "flex" }} className="gridText">
                                            <TextFields
                                                id="standard-basic"
                                                variant="standard"
                                                value="Push Notifications"
                                                textProps={true}
                                                disabled
                                            />

                                            <AntSwitch
                                                value={pushNotification}
                                                onChange={() => {
                                                    setPushNotification(!pushNotification);
                                                }}
                                                checked={pushNotification}
                                                checkTrue={disable}
                                                inputProps={{ "aria-label": "ant design" }}
                                                disabled={disable}
                                            />
                                        </Grid>
                                    </Grid> : null}

                            </Grid>
                        </>
                    )}

                    {/* <u color="blue" marginTop='-30' marginLeft='10px'> */}
                    <div style={{ marginBottom: "0px" }}>
                        <a
                            onClick={changePasswordHandler}
                            style={{
                                cursor: "pointer",
                                // maxWidth: "44%",
                                display: "inline-block",
                                textDecoration: "underline",
                                color: " rgb(72, 146, 195)",
                                fontSize: "16px",
                                // marginLeft: "10px",
                                fontFamily: "SF Pro Text"
                                // width:'15%'
                            }}
                        >
                            Change Password
                        </a>
                    </div>

                    {open ? (
                        <div className="setting-btn">
                            <>
                                <ButtonCancel
                                    onClick={handleEditCancel}
                                    style={{
                                        marginTop: "38px",
                                    }}
                                >
                                    {" "}
                                    Cancel
                                </ButtonCancel>

                                <Buttons
                                    onClick={handleSave}
                                    buttoncheck={true}
                                    style={{
                                        marginTop: "38px",
                                    }}
                                >
                                    {" "}
                                    Save
                                </Buttons>
                            </>{" "}
                        </div>
                    ) : (
                        <Buttons
                            onClick={handleEdit}
                            style={{
                                width: "204px",
                                marginTop: "38px",
                                // padding: "17px",
                                marginLeft: 0
                            }}
                            buttoncheck={true}
                        >
                            Edit
                        </Buttons>
                    )}
                </Box>
            </div>

        )
    }

    const allTabList = [{ title: 'Main Information', element: renderMainInformation() }]

    const subdivTabs = [{ title: 'Important contact', element: renderContacts() },
    { title: 'Local police number', element: renderLocalPoliceNumber() },
    { title: 'Useful links for Travel', element: renderUsefulLinks() }]

    const controluserTabs = [{ title: 'Important contact', element: renderContacts() }]


    const tabList = subdiv_user ? [...subdivTabs, ...allTabList]
        : allTabList ? control_user || checkUser == USER_TYPE.END_USER
            ? [...allTabList] : allTabList : allTabList
    console.log("check tab", ...subdivTabs)
    const addContact = () => {
        if (butttonValue === 'Add number') {
            navigate('/app/Settings/AddNumber')
            dispatch({ type: SET_LOCAL_POLICE_NUMBER, payload: null });
        } else if (butttonValue === 'Add contact') {
            dispatch(setCurrentImportContact(null))
            setOpenAddEditDialog(true);
        } else {
            dispatch(setUsefulLinkForTravel(null))
            setOpenAddEditDialog(true);
        }
    }

    const onTabChanged = (event, newValue) => {
        if (tabId !== null && tabId !== undefined) {
            navigate('/app/Settings');
        }
        setButttonValue(buttonText[newValue])
        setTabIndex(newValue);
        newValue !== 3 && dispatch(resetImportList())
        // dispatch(getImportantContactList())
    };

    return (
        <div>
            <div className="heading-section">
                <div className="pageTitle">Settings</div>
            </div>
            {subdiv_user && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: !butttonValue && '36px', marginBottom: "10px" }}>
                {butttonValue && <Buttons onClick={addContact} style={{ width: '170px' }}><AddIcon />{butttonValue}</Buttons>}
            </div>}

            <CustomTabs value={tabIndex} onChange={onTabChanged} aria-label="" variant="scrollable" scrollButtons={false}>
                {tabList.map((tab, index) => <CustomTab key={`settings-tab-${index}`} label={tab.title} id={`tab-${index}`} />)}
            </CustomTabs>
            {tabList.map((tab, index) => <TabPanel key={`settings-tabpanel-${index}`} index={index} tabIndex={tabIndex} style={{ paddingTop: 0 }}>{tab.element}</TabPanel>)}

            {openPassConfirmDialog && <ChangePassword
                open={openPassConfirmDialog}
                onClose={handleCancelChangePass}
                onConfirm={handleConfirmChangePass}
                setOpenPassConfirmDialog={setOpenPassConfirmDialog}
            />}
            <AddConfirmDialog
                open={openConfirmDialog}
                content={`All changes saved successfully.`}
                btnLabels={{
                    ok: "OK",
                }}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}