import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { styled } from "@mui/material/styles";
import { Buttons } from '../Button';
const useStyles = styled((theme) => ({
    dialogRoot: {
        position: 'relative',
    },
    dialogTitleRoot: {
        '& .MuiTypography-h6': {
            fontSize: 16,
            color: theme?.palette?.common?.dark,
            textOverflow: 'ellipsis',
            maxWidth: '350px',
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    },
    dialogActionsRoot: {
        padding: '16px 24px',
        align: 'center'
    },
}));

const ConfirmDltDialog = ({ open, onClose, onConfirm, title, content, btnLabels, content1 }) => {
    const classes = useStyles('div');

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog-title" className={classes.dialogRoot}
        >
            <i className="icon-close icon" onClick={onClose}></i>
            {title && <h1 className='text-center text-xl'>{title}</h1>}
            <DialogContent style={{ width: '380px', textAlign: "center" }}>
                <DialogContentText style={{ maxWidth: "266px", margin: "0 auto 10px", fontSize: "16px", fontFamily: "SFTextMedium", color: "rgb(113, 117, 125)", letterSpacing: "0", lineHeight: "1.3" }}>{content}</DialogContentText>
                <DialogContentText style={{ maxWidth: "266px", margin: "0 auto 30px", fontSize: "16px", fontFamily: "SFTextMedium", color: "rgb(113, 117, 125)", letterSpacing: "0", lineHeight: "1.3" }}>{content1}</DialogContentText>
                <DialogActions style={{ justifyContent: "space-around", }}>
                    {btnLabels.no && <Buttons onClick={onClose} style={{ minWidth: '133px', minHeight: '49px', fontFamily: "SF Pro Text", fontSize: "16px", letterSpacing: "0" }}> {btnLabels.no}</Buttons>}
                    <Buttons onClick={onConfirm} style={{ minWidth: '133px', minHeight: '49px', marginLeft: "0", fontFamily: "SF Pro Text", fontSize: "16px", letterSpacing: "0" }}> {btnLabels.Yes}</Buttons>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

ConfirmDltDialog.defaultProps = {
    open: false,
    title: '',
    btnLabels: {
        confirm: 'Confirm',
    },
};

export default React.memo(ConfirmDltDialog);
