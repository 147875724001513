// import { GET_EXTERNAL_USER } from "../../@jumbo/constant/ActionTypes";
import { ADD_USER, GET_ALL_USERS, GET_USER_DETAILS, GET_SUBDIVISION } from "../../@jumbo/constant/ActionTypes";
import { allUserList, getUserDetails, editSubdivision, DeleteTenant } from "../../@jumbo/constant/ApiConstant";
import axios from '../../helper/customAxios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS } from '../../@jumbo/constant/ActionTypes';
import { Form, useNavigate } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { push } from "connected-react-router";
import { refreshToken } from "./Auth";

export const browserHistory = createBrowserHistory();
// let navigate = useNavigate();

export const getExternalUser = (sortBy, sort, searchValue, activePage, limit, activePerPage) => {
  return dispatch => {
    const Token = JSON.parse(localStorage.getItem('userToken'));;
    axios.defaults.headers.common['Authorization'] = Token;
    dispatch(fetchStart());
    axios.get(allUserList, {
      params: {
        user_type: 5, page: activePage, limit: activePerPage, sortBy: `${sortBy}:${sort}`, q: searchValue,
      },
    })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, loading: false });
        if (data.status === 200) {
          // dispatch({ type: GET_ALL_USERS, payload: data.data.data });

          if (!data.data.data.userData.length) {
            dispatch({ type: GET_ALL_USERS, payload: [] });
          } else {
            dispatch({ type: GET_ALL_USERS, payload: data.data.data });
          }
        } else {
          dispatch({ type: GET_ALL_USERS, payload: [] });

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getExternalUser(sortBy, sort, searchValue, activePage, limit, activePerPage))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {

          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }

      });

  };
};

export const getsubUsers = () => {
  return dispatch => {
    const Token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = Token;
    axios.get(allUserList, {
      params: {
        user_type: 4,
        limit: 50,
        total: 50
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch({ type: GET_SUBDIVISION, payload: data.data.data });
        } else {
          dispatch({ type: GET_SUBDIVISION, payload: [] });

        }
      })
    // .catch(error => {
    //   if(error.response.status===401 ){
    //     window.location='/';
    //   } 

    // });

  };
};

export const getCurrUserDetail = (id) => {

  return dispatch => {
    dispatch(fetchStart());
    const Token = JSON.parse(localStorage.getItem('userToken'));;
    axios.defaults.headers.common['Authorization'] = Token
    axios.get(getUserDetails, {
      params: {
        user_id: id,
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch({ type: GET_USER_DETAILS, payload: data.data.data });
        } else {
          dispatch({ type: GET_USER_DETAILS, payload: [] });
        }
        dispatch({ type: FETCH_SUCCESS, loading: false });
      })
    // .catch(error => {
    //   if(error.response.status===401 ){
    //    window.location='/';
    //     // dispatch(setAuthUser(null));
    //     // history.push('/signin')
    //   } 
    //   // else {
    //   //   // dispatch(fetchError(error?.response?.data?.message));
    //   // }
    // });

  };
};

export const addExtController = (adduser, callbackFun) => {
  return dispatch => {
    const Token = JSON.parse(localStorage.getItem('userToken'));;
    axios.defaults.headers.common['Authorization'] = Token
    dispatch(fetchStart());
    axios
      .post('v1/users/add-user', adduser)
      .then(data => {

        if (data) {
          if (callbackFun) callbackFun.setAddDialog();
          dispatch({ type: FETCH_SUCCESS, loading: false });
          setTimeout(() => {
            dispatch(fetchSuccess('External controller added successfully.'));
          }, 4000);

          // dispatch(fetchSuccess('Is in Progressing....')); 

          // if (data?.data?.data?.red_snakbar) {
          //   setTimeout(() => {
          //     dispatch(fetchError(data?.data?.message));
          //   }, 2000);
          // } else {
          //   setTimeout(() => {
          //     dispatch(fetchSuccess(data?.data?.message));
          //   }, 2000);
          // }
          // dispatch({ type: ADD_USER, payload: data.data.data });
        } else {
          dispatch({ type: ADD_USER, payload: [] });
          dispatch(fetchError('There was something issue in responding server.'));
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addExtController(adduser, callbackFun))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          // if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const updateExtController = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(editSubdivision, user)
      .then(data => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS, loading: false });

          if (callbackFun) callbackFun.setAddDialog();

          setTimeout(() => {
            dispatch(fetchSuccess('External controller updated successfully .'));
          }, 4000);
        }
        else {
          dispatch(fetchError('There was something issue in responding server.'));
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
        //   if (callbackFun) callbackFun();
        // dispatch({ type: EDIT_SUBDIVISION_USER, payload: data.data.data });

      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(updateExtController(user, callbackFun))
          }))
          return;
        }
        if (error?.response?.data?.message) {
          // if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};


export const deleteExtController = (userId, callbackFun, list) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .get(DeleteTenant + userId)
      .then((data) => {
        if (data.status === 200) {
          setTimeout(() => {
            if (callbackFun) callbackFun.setAddDialog();
          }, 1000);
          dispatch({ type: FETCH_SUCCESS, loading: false });

          dispatch(list());
          setTimeout(() => {
            dispatch(fetchSuccess("Ext controller deleted Sucessfully."));
          }, 4000);
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(deleteExtController(userId, callbackFun, list))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          // dispatch(fetchError(error.response.data.message));
        }
      });
  };
};