import {
  LOCAL_POLICE_NUMBER_LIST,
  ADD_LOCAL_POLICE_NUMBER,
  UPDATE_LOCAL_POLICE_NUMBER,
  REMOVE_LOCAL_POLICE_NUMBER,
  SET_LOCAL_POLICE_NUMBER,
} from "../../@jumbo/constant/ActionTypes";
import {
  localPoliceNumberList,
  addLocalPoliceNumber,
  updateLocalPoliceNumber,
  removeLocalPoliceNumber,
  setLocalPolicedetails,
} from "../../@jumbo/constant/ApiConstant";
import axios from "../../helper/customAxios";
import { fetchError, fetchStart, fetchSuccess, fetchStartSidebar } from "./Common";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_DATA_SUCCESS,
} from "../../@jumbo/constant/ActionTypes";
import { refreshToken } from "./Auth";

// orderBy, order, searchValue, activePage, activePerPage
export const getLocalPoliceNumberList = () => {
  return (dispatch) => {
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    // axios.defaults.headers.common['Authorization'] ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2E0NDM5ZDlmYTQ1MzI5NDhjOTI4YzMiLCJpYXQiOjE2NzIyMjYzMzQsImV4cCI6MTcwMzc4MzkzNH0._o7BHMOAhlRbyLL52ofCdy0ArJ8rDUwYsgofq2VCZI8";
    dispatch(fetchStartSidebar())
    dispatch(fetchStart());
    axios
      .get(localPoliceNumberList)
      .then((data) => {
        if (data.status == 200) {
          // if (!data.data.data.userData.length) {
          //   dispatch({ type: IMPORTANT_CONTACT_LIST, payload: [] });
          // }else{
          //   dispatch({ type: IMPORTANT_CONTACT_LIST, payload: data.data.data });
          // }
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: LOCAL_POLICE_NUMBER_LIST, payload: data.data.data });
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });

          dispatch({ type: LOCAL_POLICE_NUMBER_LIST, payload: [] });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getLocalPoliceNumberList())
          }))
          return;
        }
        // if(error.response && error.response.status == 401 || error.response && error.response.status==400)
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          // dispatch(setAuthUser(null));
          // history.push('/signin')
          // window.location.replace('/');

          // broswerHistory.push('/');
          // dispatch(fetchError(error.response.data.message));
          dispatch({ type: FETCH_SUCCESS, loading: false });
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });
  };
};

// export const setLocalPoliceNumber = user => {
//   return dispatch => {
//     dispatch({ type: SET_LOCAL_POLICE_NUMBER, payload: user });
//   };
// };
export const setLocalPoliceNumber = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    axios.get(setLocalPolicedetails + id).then((data) => {
      if (data.status == 200) {
        dispatch({ type: SET_LOCAL_POLICE_NUMBER, payload: data.data.data });
      } else {
        dispatch({ type: SET_LOCAL_POLICE_NUMBER, payload: [] });
      }
      dispatch({ type: FETCH_SUCCESS, loading: false });
    });
    // .catch(error => {
    //   if(error.response.status===401 ){
    //     // dispatch(setAuthUser(null));
    //     // history.push('/signin')
    //   }
    //   else {
    //     // dispatch(fetchError(error?.response?.data?.message));
    //   }
    // });
  };
};

export const AddLocalPoliceNumber = (adduser, setOpenConfirmDialog, list) => {
  return (dispatch) => {
    const token = JSON.parse(localStorage.getItem("userToken"));
    axios.defaults.headers.common["Authorization"] = token;
    dispatch(fetchStart());
    axios
      .post(addLocalPoliceNumber, adduser)
      .then((data) => {
        if (data) {
          setTimeout(() => {
            if (setOpenConfirmDialog) {
              setOpenConfirmDialog(true);
            }
          }, 1000);
          dispatch({ type: FETCH_SUCCESS, loading: false });

          dispatch(list());
          setTimeout(() => {
            dispatch(fetchSuccess("Police number Added Sucessfully."));
          }, 3000);
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: ADD_LOCAL_POLICE_NUMBER, payload: [] });
          dispatch(
            fetchError("There was something issue in responding server.")
          );
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(AddLocalPoliceNumber(adduser, setOpenConfirmDialog, list))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {
          // dispatch(setAuthUser(null));
          // history.push('/signin');
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
          // callbackFun.onCloseDialog();
          // callbackFun.setAddButton();
        }
      });
  };
};

export const UpdateLocalPoliceNumber = (
  local_police_number_id,
  numberData,
  setOpenConfirmDialog,
  list
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .put(updateLocalPoliceNumber + local_police_number_id, numberData)
      .then((data) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          if (setOpenConfirmDialog) {
            setOpenConfirmDialog(true)
          }
          dispatch(list());
          dispatch({ type: SET_LOCAL_POLICE_NUMBER, payload: null });
          setTimeout(() => {
            dispatch(fetchSuccess('Police number updated successfully .'));
          }, 2000);
        }


        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.")
        }
        //   if (callbackFun) callbackFun();
        // dispatch({ type: EDIT_SUBDIVISION_USER, payload: data.data.data });
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(UpdateLocalPoliceNumber(local_police_number_id, numberData, setOpenConfirmDialog, list))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {

          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};

export const RemoveLocalPoliceNumber = (selectedUser, callbackFun, list) => {

  return (dispatch) => {
    dispatch(fetchStart());
    axios
      .post(removeLocalPoliceNumber, {
        localPoliceNumberId: selectedUser,
      })
      .then((data) => {
        if (data.status === 200) {
          setTimeout(() => {
            if (callbackFun) callbackFun.setAddDialog();
          }, 1000);
          dispatch({ type: FETCH_SUCCESS, loading: false });

          dispatch(list());
          setTimeout(() => {
            dispatch(fetchSuccess('Police number deleted Sucessfully.'));
          }, 4000);
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          fetchError("There was something issue in responding server.")
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(RemoveLocalPoliceNumber(selectedUser, callbackFun, list))
          }))
          return;
        }
        if (
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status == 400)
        ) {

          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};
