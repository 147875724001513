import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
// import FormErrorMessage from "components/common/alerts/FormErrorMessage";
import styled from "styled-components";
import { TextFields } from 'modules/Textfeild';
import CustomErrorMessage from "./ErrorMessage";
const Input = ({
    className,
    name,
    defaultValue,
    displayError = true,
    disabled,
    ...res
}) => {
    const { errors } = useFormikContext();
    return (
        <>
            <Field
                type="text"
                name={name}
                defaultValue={defaultValue && defaultValue}
                {...res}
                render={({ field, meta, ...rest /* { name, value, onChange, onBlur } */ }) => {
                    // console.log(meta.touched, 'pk');
                    return (
                        <TextFields errorProps={!!(errors[name] && meta.touched)} variant={res?.variant || "standard"} type={res?.type || "text"} margin={res?.margin || "dense"}  {...field} {...res} />
                    );
                }}

            />
            {displayError === true && (
                <ErrorMessage name={name} component={CustomErrorMessage} />
            )}
        </>
    );
};

export default Input;

// export const CustomTextField = styled((props) => (
//     <TextField variant={"standard"} InputProps={{ disableUnderline: true }} style={{ width: '100%', marginTop: '0', }} {...props} />
// ))
