import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Add as AddIcon } from '@mui/icons-material'
import { TravelContainer, HeaderText, HeaderContainer, TableTitle } from './style'
import { CustomTabs, CustomTab } from 'components/CustomTab/index'
import TravelUser from './TravelUser'
import TravelMap from './TravelMap'
import { getTravelList, getUserList, resetTravelList } from 'redux/actions/travel'
import { useNavigate } from "react-router";
import { TRAVEL_STATUS } from "./data";
import { Buttons } from "../Button";
import { USER_TYPE } from "utils/commonHelper";
import { FilterButton } from "components/FilterButton";
import { getDefaultPoliceNumberList } from 'redux/actions/DefaultPoliceNumber'

const TabPanel = ({ index, children, tabIndex }) => {
    return (
        <div
            role="tabpanel"
            hidden={tabIndex !== index}
            id={`tabpanel-${index}`} style={{ paddingTop: '40px' }}>
            {tabIndex === index && children}
        </div>
    )
}

export default function Travel() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
    const [tabIndex, setTabIndex] = useState(0)
    const [loader, setLoader] = useState(false)
    const [subdivUser, setSubdivUser] = useState([])
    const [filterList, setFilterList] = useState([])
    const [activePerPage, setActivePerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const status = TRAVEL_STATUS.IN_PROGRESS
    const currentUser = JSON.parse(localStorage.getItem('profile'))
    const subdiv_user = currentUser.user_type === USER_TYPE.SUBDIVISION_USER
    const tanent_user = currentUser.user_type === USER_TYPE.TENANT_USER
    const isLoader = false

    const { travelList: list, userList: allUsers, getStatus } = useSelector(({ TRAVEL }) => TRAVEL)

    const totalUser = list?.raisedTravelCount || 0 /* list && list.travel ? list.travel : list && list.total_no_of_records ? list.total_no_of_records : '0' */

    // const markerList = useMemo(() => {
    //     if (list && Object.keys(list).length > 0 && getStatus === 200 && list.UserTravelList && tabIndex==1) {
    //         return list && list.UserTravelList && list.UserTravelList.map((x) => ({
    //             lat_long: x.lastCheckinLatLong ? x.lastCheckinLatLong : [],
    //             name: `${x.userFirstname || ''} ${x.userLastname || ''}`
    //         }))
    //     } else if(getStatus === 400) return []
    // }, [list])
    // useEffect(() => {
    //     dispatch(getDefaultPoliceNumberList())
    // }, [])


    const travelmap = useMemo(() => {
        if (list) {
            return (
                <TravelMap />
            )
        }
    }, [list])



    const tabList = [
        {
            title: `Travel (${totalUser})`, element: <TravelUser navigate={navigate} loader={loader} setLoader={setLoader} activePage={activePage} setActivePage={setActivePage} activePerPage={activePerPage} setActivePerPage={setActivePerPage} tempSearchValue={searchValue} setTempSearchValue={setSearchValue} filterList={filterList} />
        },
        {
            title: 'Travel Map', element: travelmap
        }]

    useEffect(() => {
        setLoader(true)
        dispatch(resetTravelList())
        let params = { page: activePage, limit: activePerPage, search: '', status }
        if (secSubdivisionUser) params = { page: activePage, limit: activePerPage, search: '', status, subdivisions: secSubdivisionUser }
        dispatch(getTravelList(params));
        tanent_user && dispatch(getUserList({ user_type: USER_TYPE.SUBDIVISION_USER, is_pagination: false }))
    }, [])

    useEffect(() => {
        if (secSubdivisionUser) {
            setFilterList([secSubdivisionUser])
        }
    }, [secSubdivisionUser]);

    useEffect(() => {
        setTimeout(() => setLoader(false), 3000)
    }, [list, tabList])


    useEffect(() => {
        if (allUsers && allUsers.userData && allUsers.userData.length > 0) {
            const opt = allUsers.userData.map((option) => ({ label: `${option.first_name} ${option.last_name}`, id: option._id }))
            setSubdivUser(opt)
        }
    }, [allUsers])


    const onFilter = (label) => () => {
        const list = [...filterList]
        dispatch(resetTravelList())
        setSearchValue('')
        setLoader(true)
        if (label == 'all') {
            setFilterList([])
        } else {
            const idx = list.indexOf(label)
            if (idx > -1) {
                list.splice(idx, 1)
            } else {
                list.push(label)
            }
            setFilterList(list)
        }

        const subdivisions = label === 'all' ? '' : list.length === 1 ? list[0] : list.join(',')

        if (tabIndex === 0) {
            const params = { page: activePage, limit: activePerPage, search: '', subdivisions, status }
            dispatch(getTravelList(params))
        } else if (tabIndex === 1) {
            const params = { subdivisions, status }
            dispatch(getTravelList(params, isLoader))
        }
    }

    const onTabChanged = (e, newValue) => {
        setTabIndex(newValue)
        dispatch(resetTravelList())
        setFilterList([])
        if (newValue === 0) {
            setLoader(true)
            let params = { page: 1, limit: 10, search: '', status }
            if (secSubdivisionUser) params = { page: 1, limit: 10, search: '', status, subdivisions: secSubdivisionUser }
            dispatch(getTravelList(params))
        } else if (newValue === 1) {
            let params = { status }
            if (secSubdivisionUser) params = { status, subdivisions: secSubdivisionUser }
            dispatch(getTravelList(params))
        }
    }

    return (
        <TravelContainer>
            <HeaderContainer style={{ marginBottom: subdiv_user ? '37px' : '8px' }}>
                <HeaderText>Travel</HeaderText>
                {subdiv_user && <Buttons
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/app/Travel/add-travel')}
                >
                    Create travel</Buttons>}
            </HeaderContainer>
            {tanent_user && <div style={{ marginBottom: '10px' }}>
                <FilterButton variant={filterList.length ? "outlined" : "contained"} key={`subdiv-all`}
                    style={{
                        backgroundColor: filterList.length ? '' : 'rgb(72, 146, 195)',
                        color: filterList.length ? 'rgb(72, 146, 195)' : 'white'
                    }}
                    onClick={onFilter('all')}>
                    {'All'}</FilterButton>
                {subdivUser && subdivUser.map((user, index) => <FilterButton
                    style={{
                        backgroundColor: filterList.indexOf(user.id) > -1 ?
                            'rgb(72, 146, 195)' : '', color: filterList.indexOf(user.id) > -1 ? 'white' : 'rgb(72, 146, 195)'
                    }}
                    variant={filterList.indexOf(user.id) > -1 ? "contained" : "outlined"} key={`subdiv-${index}`} onClick={onFilter(user.id)}>
                    {user.label}</FilterButton>)}
            </div>}
            <p style={TableTitle}>Travel users - {totalUser}</p>
            <CustomTabs value={tabIndex} onChange={onTabChanged} aria-label="" twoColors={true} isRedFirst={true}>
                {tabList.map((tab, index) => <CustomTab key={`tab-${index}`} label={tab.title} id={`tab-${index}`} twoColors={true} isRedFirst={true} />)}
            </CustomTabs>
            {tabList.map((tab, index) => (
                <TabPanel key={`tabpanel-${index}`} index={index} tabIndex={tabIndex} id="tabpannenl" style={{ marginTop: 0 }}>
                    {tab.element}
                </TabPanel>
            ))}
        </TravelContainer>
    )
}
