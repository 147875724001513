
import * as React from 'react';
import { styled } from '@mui/material/styles';

import InputBase from '@mui/material/InputBase';
import { InputLabel } from '@mui/material';





export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  
    'label + &': {
      
      marginTop: theme.spacing(3),
      
    },
    ' & .MuiFormLabel-root.Mui-focused': {
      color: 'rgb(144, 153, 170) !important'
  },
    '& .MuiInputLabel-root':{
        color: 'rgb(144, 153, 170) !important',

    },
    '.MuiNativeSelect-root .Mui-focused':{
        color: 'rgb(144, 153, 170) !important' ,
    },

    '& .MuiInputBase-input': {
      position: 'relative',
      borderBottom: '1px solid rgba(174, 180, 192, 0.24)',
      fontWeigth:'300',
      fontSize: 15,
      padding: '10px 26px 10px 12px',
      display:'flex',
      alignItems:'center',
      fontFamily:'SF Pro Text',
      
      
      
    },
    marginLeft:'1px',
    // '& .MuiSelect-select-MuiInputBase-input.MuiSelect-select':{
      '& .MuiSelect-select':{
      padding:'0px 0px 6px !important ',
      fontFamily:'SF Pro Text !important'
    },
    '.css-194a1fa-MuiSelect-select-MuiInputBase-input:focus': {
      backgroundColor:'transparent',
    }
    
  }));


  export const CustomLabel = styled(InputLabel)(({ theme }) => ({
    color: 'rgb(144, 153, 170) !important',
    fontSize:'16px',
    // letterSpacing: '0',
    fontFamily:'SF Pro Text',
    fontWeigth:'300',
    '&.Mui-focused': {
        color: 'rgb(144, 153, 170) !important',
        fontSize:'16px',
        // letterSpacing: '0',
        fontWeigth:'300',
        fontFamily:'SF Pro Text'

    },
    marginLeft:'-13px'
}))
  