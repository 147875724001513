import { USEFUL_LINK_FOR_TRAVEL_LIST,ADD_USEFUL_LINK_FOR_TRAVEL,UPDATE_USEFUL_LINK_FOR_TRAVEL,REMOVE_USEFUL_LINK_FOR_TRAVEL,SET_USEFUL_LINK_TRAVEL} from "../../@jumbo/constant/ActionTypes";
  
  const INIT_STATE = {
    travelList: [],
    // addUser:null,
    currentuser:null,
    currentDetails:null
   
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case USEFUL_LINK_FOR_TRAVEL_LIST: {
        return {
          ...state,
          travelList: action.payload,
        };
      }
      case ADD_USEFUL_LINK_FOR_TRAVEL: {
          return {
            ...state,
            travelList: [action.payload, ...state.travelList],
        };
       }
       case  SET_USEFUL_LINK_TRAVEL: {
        return {
          ...state,
          currentDetails:action.payload
      };
    }
      case UPDATE_USEFUL_LINK_FOR_TRAVEL: {
        return {
          ...state,
          travelList: state.travelList.map(travelList => (travelList.id === action.payload._id ? action.payload : travelList)),
        };
      
     }
     case REMOVE_USEFUL_LINK_FOR_TRAVEL: {
      return {
        ...state,
        travelList: state.travelList.filter(travelList => travelList._id !== action.payload),
      };
    }
      default:
        return state;
    }
  };
  