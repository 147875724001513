import styled from "styled-components";
import COLORS from "../../utils/colors";

export const GoogleInputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const GoogleInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 36px;
    border: 1px solid rgb(229,230,233);
    border-radius: 5px;
    padding: 0 3px;
`

export const GoogleInputBox = styled.input`
    font-family: SFTextLight;
    font-size: 15px;
    outline: none;
    box-shadow: none;
    padding: 0px 10px;
    width: 100%;
    border: 0px solid rgb(229,230,233);
`

export const CursorStyle = {
    cursor: 'pointer'
}

export const SearchButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    i {
        color: ${COLORS.greytext};
    }
`;

export const Delete = styled.div`
    width: 30px;
    transform: translateY(-50%);
    cursor: pointer;
    margin-top: 30px;
    text-align: center;
    i {
        font-size: 30px;
        color: ${COLORS.greylighttext};
    }
`;