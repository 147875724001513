

import React from 'react';
import Pagination from 'react-js-pagination';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Wrapper,
  Divider,
} from './style';

const perPage = [10, 20, 50, 100];
export default function CustomPagination({
  activePage,
  handlePageChange,
  totalItems,
  itemsCountPerPage }) {
  return (
    <Wrapper>
      <Pagination
        prevPageText={
          // <ArrowBackIosIcon style={{fontSize:'20px',display:'inline-block'}}/>
          <i className="icon-arrow-left"></i>
        }
        nextPageText={
          // <ArrowForwardIosIcon style={{fontSize:'20px',display:'inline-block'}}/>
          <i className="icon-arrow-right"></i>
        }
        firstPageText={<Divider>
          {/* <ArrowBackIosIcon style={{fontSize:'20px',display:'inline-block'}}/> */}
          <i className="icon-arrow-left"></i>
        </Divider>}
        lastPageText={<Divider after>
          <i className="icon-arrow-right"></i>
          {/* <ArrowForwardIosIcon style={{fontSize:'20px',display:'inline-block'}}/> */}
        </Divider>}
        activePage={isNaN(activePage) ? 1 : +activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItems}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    </Wrapper>
  )
}
