import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { fetchError } from 'redux/actions';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

// export const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} style={{width:'100%'}}  />;
// });

export const Alerts = styled(Alert)(({ theme }) => ({
    '& .MuiSnackbar-root': {
        top: '24px',
        right: 0,
        left: 'auto',
        width: '100%',
        minWidth: '600px'

    }

    // <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}/ >


}));
