import styled from 'styled-components'
import COLORS from '../../utils/colors'

export const CountContainer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.red};
    color: ${COLORS.white};
    font-family: SFTextLight;
    font-size: 10px;
    margin-left: 10px;
`