import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';

import { styled } from "@mui/material/styles";
import { Buttons } from '../Button';
const useStyles = styled((theme) => ({
  dialogRoot: {
    // position: 'relative',
    // width:'600px',
    // minHeight:'1000px'

  },
  dialogActionsRoot: {
    padding: '16px',
    marginLeft:'20px',
    align:'center'
  },
}));

const AddForgotDialog = ({ open, onClose, onConfirm, title, content, btnLabels }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialogRoot}>  
      <DialogContent   style={{width:'380px',height:"auto",padding: '80px 24px 60px',
    textAlign: 'center'}}>
        <DialogContentText style={{fontSize:'18px',margin:'auto',width:'50%',color:'rgb(113, 117, 125)',fontSize:'16px',fontFamily: 'SFTextMedium'}}>{content}
        </DialogContentText>
     
        {/* <Button onClick={onClose} color="primary" variant="contained" style={{maxWidth:'170px',marginLeft:'68px'}}>
          {btnLabels.ok}
        </Button> */}
        <Buttons onClick={onClose} style={{width:'204px',marginTop:"45px"}}>{btnLabels.ok}</Buttons>
        
      </DialogContent>
     
    </Dialog>
  );
};

AddForgotDialog.defaultProps = {
  open: false,
  title: 'Confirm Delete',
  btnLabels: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
};

export default React.memo(AddForgotDialog);
