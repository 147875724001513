import { Buttons } from 'modules/Button';
import { TextFields } from 'modules/Textfeild';
import React, { useEffect, useState } from 'react'
import Modal from "components/common/modal";
import { TextAreas } from 'modules/TextAreas';
import { completeActionList } from 'redux/actions/focusBoard';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router';
import { Formik, Form as FormikForm } from "formik";
import Input from 'components/formComponents/Input';
import * as Yup from "yup";
import { validationMsg } from '@jumbo/constant/ErrorMessages';

const Completed = ({ openCompleteDialog, setOpenCompleteDialog, particulerRowId, type }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    console.log("type", type)
    // console.log("particulerRowId",particulerRowId)

    // useEffect(()=>{
    //     dispatch(setCurrentActionIdInFocusBoard())
    // },[openCompleteDialog])

    const schema = Yup.object().shape({
        comment: Yup.string().trim(validationMsg.focusBoard.comment).required(validationMsg.focusBoard.comment),
    });

    // const datas = {
    //     id: id,
    //     action_id: particulerRowId,
    //     comment: values?.comment,
    //     isForTravel: true
    // }

    const onSubmitHandler = (values, { resetForm }) => {
        console.log("values.comment", values)
        const datas = {
            id: id,
            action_id: particulerRowId,
            comment: values?.comment,
            isForTravel: type === 3 ? true : false
        }
        dispatch(completeActionList(datas))
        setOpenCompleteDialog(false)
    }

    return (
        <Modal fullWidth={true} style={{ maxWidth: '550px', margin: '0 auto' }} openDialog={openCompleteDialog} setOpenDialog={setOpenCompleteDialog} title="Complete Action">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    comment: '',
                }}
                onSubmit={onSubmitHandler}
                validationSchema={schema}
            >
                {({ errors, setFieldValue, values }) => {
                    return (
                        <FormikForm>
                            <Input
                                // label="Do you want to add a comment to the completion?"
                                label="Do you want to add a comment to the completion?"
                                type="text"
                                fullWidth
                                name="comment"
                                defaultValue={values.comment}
                            />
                            <div className='flex justify-center mt-4'>
                                <Buttons type={'submit'} className='p-5'>{'Complete action'}</Buttons>
                            </div>
                        </FormikForm>)
                }}
            </Formik>
            {/* <TextAreas
                margin="dense"
                label="Do you want to add a comment to the completion?"
                type="text"
                fullWidth
                variant="standard"
                
            /> */}
            <div className='flex justify-center mt-4'>
                {/* <Buttons type='submit' onClick={() => {dispatch(completeActionList(datas))}} className='p-5'> {'Complete action'}</Buttons> */}
            </div>
        </Modal>
    )
}

export default Completed