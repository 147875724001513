export const tableColumns = [
    { id: 'id', label: 'ID' },
    { id: 'userFirstname', label: 'Full Name' },
    { id: 'lastCheckinCountry', label: 'Last Country' },
    { id: 'lastCheckinCity', label: 'Last City' },
    { id: 'country', label: 'End Country' },
    { id: 'city', label: 'End City' },
    { id: 'userMobile', label: 'Phone number' },
    { id: 'userEmail', label: 'Email' },
    { id: 'actions', label: 'Actions', width: '6%' },
]

export const checkInDestinationCols = [
    { id: 'address', label: 'Country, city, street', width: '50%' },
    { id: 'log_type', label: 'Check In Type' },
    { id: 'time', label: 'Check In Time' },
    { id: 'detail', label: 'Detail' },
]



export const crisisLogCols = [
    { id: 'time', label: 'Time', width: '20%' },
    { id: 'detail', label: 'Details' },
]



export const checkInStatus = {
    1: 'Start travel',
    2: 'Comment',
    3: 'Manual check',
    4: 'Autocheck',
    5: 'Call',
    6: 'End travel'
}

export const TRAVEL_STATUS = {
    'IN_ACTIVE': 0,
    'IN_PROGRESS': 1,
    'COMPLETED': 2
}
