import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError } from '../../../redux/actions';
import { Alert } from '@mui/material';
import { Slide, Snackbar } from '@mui/material';
import { Alerts } from 'modules/SanckBar';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const ContentLoader = () => {
  const { error, open, message } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  useEffect(() => {

    setTimeout(() => {
      dispatch(fetchError(''));
    }, 3000);
  }, [dispatch, error, message]);


  return (
    <React.Fragment>

      {
        <Snackbar
          autoHideDuration={6000}
          open={Boolean(error)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}>
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar
          autoHideDuration={6000}
          open={Boolean(message)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alerts variant="filled" severity="success" sx={{ width: "100%" }}>
            {message}
          </Alerts>
        </Snackbar>
      }
    </React.Fragment>
  );
};

export default ContentLoader;
