// import React from 'react'
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Form, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UserTableHead from "./UserTableHead";
import { USER_TYPE, useDebounce } from "utils/commonHelper";
import UserListRow from "./UserListRow";
import Pagination from "../../Pagination/Pagintions";
import {
  getAlertList,
  setTenantList,
  getCurrUserDetail,
} from "redux/actions/CrisisLogAlert";
import { getInHouseList } from "redux/actions/CrisisLogInHouse";
import ReactTable from "components/common/table/ReactTable2";
import { ALERT_TYPE, REVERSED_ALERT_STATUS, REVERSED_ALERT_TYPE, timeHelper } from "utils/alertHelper";
import * as moment from 'moment';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const perPages = [10, 20, 50, 100];
export default function InHouse({
  // activePage,
  // setActivePage,
  activePerPage,
  // setActivePerPage,
  // alert_type,
  filterList,
  // handlePageChange,
}) {
  const navigate = useNavigate();
  const { InHouseUsers } = useSelector(({ CRISISLOGINHOUSE }) => CRISISLOGINHOUSE);
  const columns1 = [
    {
      id: "id",
      accessor: 'id',
      title: "Alert ID",
    },
    {
      id: "lastLogEntry",
      title: "Log entry",
      accessor: 'lastLogEntry',
      Component: ({ row }) => {
        return (
          <>
            {!row.lastLogEntry ? "-" : row.lastLogEntry?.time && moment(row.lastLogEntry.time).format('DD-MM-YYYY hh:mm')} {" "}
            {row.lastLogEntry.user}
          </>
        )
      }
    },
    {
      id: "lastLogMessage",
      title: "Message",
      accessor: 'lastLogMessage',
      Component: ({ row }) => {
        return <div dangerouslySetInnerHTML={{ __html: (row?.lastLogMessage || '-') }}></div>
      }
    },
    {
      id: "userName",
      title: "Name",
      accessor: 'userName',
      Component: ({ row }) => {
        return <>
          {!!row?.test_mode ? <div className="default-number" style={{ marginTop: '4px' }}>Test Mode</div> : null}
          {!row?.userName ? '-' : row.userName}
        </>
      }
    },
    {
      id: "createdOn",
      title: "Alert created",
      accessor: 'createdOn',
      Component: ({ row }) => !row.createdOn ? "-" : moment(row.createdOn).format('DD-MM-YYYY hh:mm')
    },
    {
      id: "alertStatus",
      title: "Alert status",
      accessor: 'alertStatus',
      Component: ({ row }) => {
        return (
          <>
            {row.alertStatus ? <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: row.alertStatus === 1 ? 'rgb(225, 108, 108)' : row.alertStatus === 2 ? 'rgb(95, 170, 176)' : 'rgb(92, 99, 115)', marginRight: '5px' }} /> : ''}
            {!row.alertStatus ? "-" : REVERSED_ALERT_STATUS[row.alertStatus]}
          </>
        );
      }
    },
    {
      id: "country",
      title: "Country",
      accessor: 'country',
    },
    {
      id: "city",
      title: "City",
      accessor: 'city',
    },
    {
      id: "offices",
      title: "Offices",
      accessor: 'offices',
    },
    {
      id: "action",
      title: "Actions",
      accessor: 'id',
      Component: ({ row }) => {
        return (
          <span className="cursor-pointer" onClick={() => {
            navigate(`/app/CrisisLog/crisis-log-inHouse-detail/${row.alertId}`)
            localStorage.setItem("inhouseTab", 2)
          }}>
            <i className="icon-eye" aria-label="toggle password visibility" />
          </span>
        )
      },
    },
  ];
  useEffect(() => {
    localStorage.removeItem('inhouseTab')
  }, [])
  const Profile = JSON.parse(localStorage.getItem("profile"));
  const [activePage, setActivePage] = useState(1);
  const [callCount, setCallCount] = useState(0);
  const dispatch = useDispatch();
  const getInHouseListData = (activePage = 1, activePerPage = 10) => {
    dispatch(getInHouseList(
      `${ALERT_TYPE.IN_HOUSE_ALERT}`,
      filterList,
      activePerPage,
      activePage
    ));
  }
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  }
  useEffect(() => {
    getInHouseListData(activePage, activePerPage);
  }, [activePage]);
  useEffect(() => {
    if (callCount > 0) {
      setActivePage(prev => typeof prev === 'number' ? '1' : 1);
    } else {
      setCallCount(prev => prev + 1);
    }
  }, [activePerPage, filterList]);

  return (
    <>
      <div className="mainDiv1">
        <ReactTable
          COLUMNS={columns1}
          DATA={InHouseUsers.alertLogs}
          fetchData={handlePageChange}
          totalCount={InHouseUsers?.total_no_of_records}
          pageSize={activePerPage}
          pageIndex={activePage}
          hiddenColumns={[USER_TYPE.TENANT_USER].includes(Profile.user_type) ? ['action'] : []}
        />
      </div>
    </>
  );
}
