import React from 'react'
import COLORS from '../../utils/colors'
import { ErrorBox } from '../ErrorBox.js'
import { CustomTextareaContainer, TextareaBox } from './style'

export const CustomTextarea = ({ error, placeholder, value, onChange, minRows, style, errorStyle }) => {
    return (
        <>
            <CustomTextareaContainer style={{ border: error ? `1px solid ${COLORS.redtext}` : 'unset' }}>
                <TextareaBox
                    InputProps={{
                        disableUnderline: true,
                    }}
                    variant={"standard"}
                    multiline
                    minRows={minRows ? minRows : 3}
                    placeholder={placeholder}
                    style={{ width: '100%', ...style }}
                    value={value}
                    onChange={onChange}
                />
            </CustomTextareaContainer>
            <ErrorBox msg={error || ''} style={{ width: '100%', padding: !error && '7.5px', marginTop: error && '3px', ...errorStyle}} />
        </>
    )
}