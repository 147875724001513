import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/tailwind/output.css';
import App from './App';
import configureStore from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import 'assets/fonts/SF-Pro-Text-Light.otf'
import 'assets/fonts/SF-Pro-Text-Semibold.otf'
import 'assets/fonts/SF-Pro-Text-Regular.otf'
import 'assets/fonts/SF-Pro-Text-Bold.otf'
import 'assets/fonts/SF-Pro-Text-Medium.otf'
import 'assets/images/log-out-default.svg'
import 'assets/images/log-out-active.svg'
// import { authHeader } from 'services/helpers';
export const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Provider store={store}>
      <HashRouter >
        <App />
      </HashRouter>
    </Provider>
  </>
);
