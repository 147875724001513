import React, { useEffect, useState, useRef } from "react";
import { Button } from "@mui/material";
import {
    NameTravelIDBox,
    CustomTextarea,
    AddButtonContainer,
    AddButton,
    printHeaderStyle,
    printFooterStyle,
    headerInnerEleStyle,
    CustomErrorWrapper,
    CustomError,
    CustomTextareaContainer,
    testModeStyle
} from "../../style";
import {
    addTravelComment,
    resetTravelComment,
} from "redux/actions/travel";
import { useDispatch, useSelector } from "react-redux";
// import { TRAVEL_STATUS } from "../../data";
import { requiredMessage } from "@jumbo/constant/ErrorMessages";
import COLORS from "utils/colors";
import { Buttons } from "modules/Button";
import { useParams } from "react-router";

const AddLogMessage = ({ addLogAPI }) => {
    const [errors, setErrors] = useState({});
    const [comment, setComment] = useState("");
    const { id } = useParams();
    const dispatch = useDispatch();
    const [buttonProps, setButtonProps] = useState("addbutton");
    const onCommentChange = (e) => {
        setErrors({});
        setComment(e.target.value);
    };
    const onAddComment = () => {
        if (!comment) {
            setErrors({ ...errors, comment: requiredMessage });
        } else {
            const addComment = {
                comment: comment,
            };
            dispatch(addLogAPI(id, addComment));
            setComment("");
        }
    };
    const renderError = (msg, style) => {
        return (
            <CustomErrorWrapper style={style}>
                <CustomError>{msg}</CustomError>
            </CustomErrorWrapper>
        );
    };
    return (
        <>
            <div style={{ marginTop: "30px", }}>
                <CustomTextareaContainer
                    style={{
                        border: errors.comment ? `1px solid ${COLORS.redtext}` : "unset",
                    }}
                >
                    <CustomTextarea
                        multiline
                        variant={"standard"}
                        minRows={4}
                        placeholder="Add log entry (time will be registered automatically)..."
                        style={{ width: "100%" }}
                        value={comment}
                        onChange={onCommentChange}
                        error={errors.comment}
                        InputProps={{
                            disableUnderline: true,
                        }}
                    />
                </CustomTextareaContainer>
                {errors.comment && renderError(errors.comment, { width: "98%" })}
                <AddButtonContainer>
                    <Buttons
                        // variant="contained"
                        style={AddButton}
                        onClick={onAddComment}
                        buttoncheck={buttonProps}
                    >
                        Add
                    </Buttons>
                </AddButtonContainer>
            </div>
        </>
    )
}

export default AddLogMessage;