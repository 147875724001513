import React, { useState, useEffect } from "react";
import { AntSwitch } from "components/antSwitch/AntSwitch";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    requiredMessage,
    onlyNumberAllow,
} from "@jumbo/constant/ErrorMessages";
import {
    emptySpace,
    numberOnly,
} from "@jumbo/constant/ValidationRegex";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { TextFields } from "../../../Textfeild";
import COLORS from "utils/colors";
import {
    SearchOutlined as SearchOutlinedIcon,
    Close,
} from "@mui/icons-material";
import {
    MapHeader,
    DefaultNumber,
    AddNumberContainer,
    AddButton,
    CustomTextarea,
    AddTravelButtonContainer,
    CustomError,
    CustomErrorWrapper,
    CursorStyle,
    CustomTextareaContainer,
    DefaultSwitchContainer,
} from "../../style";
import { Button } from "@mui/material";
import {
    AddLocalPoliceNumber,
    UpdateLocalPoliceNumber,
    getLocalPoliceNumberList,
} from "redux/actions/localPoliceNumber";
import ConfirmDialog from "components/ConfirmDialog";
import { SearchButton } from "../../../SearchInput/style";
import SearchInput from "../../../SearchInput";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import { Buttons } from "../../../Button";
import AddConfirmDialog from "../../../AddConfirmDialog";
import { setLocalPoliceNumber } from "redux/actions/localPoliceNumber";
import { GoogleAutocomplete } from "components/GoogleAutocomplete";
import GooglMap from "components/GooglMap";

export default function AddEditUser() {
    const [destination, setDestination] = useState('');
    const [discription, setDiscription] = useState("");
    const [policeNumber, setPoliceNumber] = useState("");
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [country, setCountry] = useState("");
    // const [helperText, setHelperText] = useState(false)

    const [error, setError] = useState({});

    const [checked, setChecked] = useState(false);

    const [placeId, setPlaceId] = useState("");
    const [buttonProps, setButtonProps] = useState("addbutton");
    const [helperText, setHelperText] = useState(false);
    const [policeError, setPoliceError] = useState("");
    const [destinationError, setDestinationError] = useState("");
    const [coords, setCoords] = useState({
        type: "point",
        coordinates: [],
    });

    const [latlng, setLatLng] = useState({});
    let { id } = useParams();
    const { currentDetails } = useSelector(({ LOCALPOLICENUMBER }) => LOCALPOLICENUMBER);
    console.log("currentDetails", currentDetails)

    let navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if(currentDetails){
    //     dispatch(setLocalPoliceNumber(id));
    //     }
    // }, [id]);

    const handleCancelConfirm = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirm = () => {
        // alert("start")
        setOpenConfirmDialog(false);
        setDestination("");
        setPoliceNumber("");
        setDiscription("");
        setChecked(false);
        setPlaceId("");
        setCountry("");
        setCoords({
            type: "point",
            coordinates: [],
        });
        setLatLng({})
        navigate("/app/Settings?tab=1");
    };

    useEffect(() => {
        if (currentDetails?.local_police_number) {
            setDestination(currentDetails.local_police_number.country || '');
            setPoliceNumber(currentDetails.local_police_number.police_phone || '');
            setDiscription(currentDetails.local_police_number.description || '');
            setChecked(currentDetails.local_police_number.is_default || false);
            setPlaceId(currentDetails.local_police_number.country_google_id || '');
            setLatLng({ lat: currentDetails.local_police_number.latitude || '', lng: currentDetails.local_police_number.longitude || '' });
            setCoords({
                ...coords,
                coordinates: [
                    currentDetails.local_police_number.latitude || '',
                    currentDetails.local_police_number.longitude || '',
                ],
            });
            document.getElementById("autocomplete-input").value = currentDetails.local_police_number.country || ''
        }
    }, [currentDetails]);

    const onHandleSave = () => {
        if (!policeNumber || policeNumber.match(emptySpace)) {
            setHelperText(true);
            setPoliceError(requiredMessage);
        } else if (!policeNumber.match(numberOnly)) {
            setHelperText(true);
            setPoliceError(onlyNumberAllow);
        }

        if (!destination || destination.match(emptySpace)) {
            setHelperText(true);
            setDestinationError(requiredMessage);

            // setError({ ...error, countryError: requiredMessage });
        }
        let addNumber = {
            country: country,
            country_google_id: placeId,
            police_phone: policeNumber,
            is_default: checked,
            description: discription,
            geopoint: {
                type: 'point',
                coordinates: [latlng.lat, latlng.lng]
            },
        };

        let editNumber = {
            country: country,
            country_google_id: placeId,
            police_phone: policeNumber,
            is_default: checked,
            description: discription,
            geopoint: {
                type: 'point',
                coordinates: [latlng.lat, latlng.lng]
            }
        };
        // if ( !error.countryError) {
        if (
            policeNumber &&
            !policeNumber.match(emptySpace) &&
            policeNumber.match(numberOnly) &&
            destination &&
            !destination.match(emptySpace)
        ) {
            if (currentDetails) {
                dispatch(UpdateLocalPoliceNumber(currentDetails.local_police_number._id, editNumber,
                    setOpenConfirmDialog,
                    // getLocalPoliceNumberList
                )
                );
            } else {
                dispatch(
                    AddLocalPoliceNumber(
                        addNumber,
                        setOpenConfirmDialog,
                        // getLocalPoliceNumberList
                    )
                );
                //dispatch(UpdateLocalPoliceNumber(currentDetails._id, editNumber, getLocalPoliceNumberList))
            }
        }
    };

    const removeError = (field) => {
        const err = { ...error };
        delete err[field];
        setError(err);
    };

    const onPlaceChanged = (place, latlng, placeObj) => {
        setDestination(place);
        setLatLng(latlng);
        setPlaceId(placeObj.placeId)
        setCountry(placeObj.country)
    };

    const onGoogleBoxChange = (e) => {
        removeError("countryError");

        setDestination(e.target.value);


        if (e.target.value === "") {
            setLatLng({});
        }
        setDestinationError("");
        setHelperText(false);
    };

    const onRemoveAddress = () => {
        setDestination("");
        setLatLng({});
        document.getElementById("autocomplete-input").value = "";
    };

    const renderError = (msg, style) => {
        return (
            <CustomErrorWrapper style={style}>
                <CustomError>{msg}</CustomError>
            </CustomErrorWrapper>
        );
    };


    return (
        <div className="mainDiv1">
            {/* <button onClick={()=>setOpenConfirmDialog(true)}>hello</button> */}
            {/* <AddNumberContainer> */}
            {/* <MapHeader>{id ? 'Edit location' : 'Add location'}</MapHeader> */}
            <div className="heading-section">
                <div className="pageTitle">{id ? "Edit location" : "Add location"}</div>
            </div>
            {/* <GoogleAutocomplete onChange={onGoogleBoxChange} onRemove={onRemoveAddress} error={destinationError} value={destination} /> */}
            {/* <span style={{fontSize: '12px',color: 'red',flex: 1,textAlign: 'right'
    }}>{destinationError}</span> */}

            {/* <div>
                <TextFields
                    id="standard-basic"
                    label="Police number *"
                    variant="standard"
                    value={policeNumber}
                    onChange={(e) => {
                        setPoliceNumber(e.target.value);
                        setError({ ...error, numberError: '' });
                    }}
                    style={{
                        color: error.numberError ? "red" : "",
                        marginTop: '25px'
                    }}
                    helperText={error.numberError}
                />
            </div> */}
            <Box
                component="form"
                sx={{
                    "& > :not(style)": {
                        width: "50ch",
                        mb: 0,
                        mt: 3,
                        width: "100%",
                    },
                    flexGrow: 1,
                }}
                noValidate
                autoComplete="off"
                style={{
                    color: "rgba(0, 0, 0, 0.87)",
                    // ,marginRight:'200px'
                }}
            >
                <Grid item xs={12} mb={2} className="gridText">
                    <GoogleAutocomplete
                        onChange={onGoogleBoxChange}
                        onRemove={onRemoveAddress}
                        error={destinationError}
                        value={destination}
                    />
                </Grid>
                <Grid container spacing={2} ml={0} className="formGrid">
                    <Grid item xs={12} mb={2} className="gridText settingtText">
                        <TextFields
                            id="standard-basic"
                            label="Police number *"
                            variant="standard"
                            value={policeNumber}
                            onChange={(e) => {
                                setPoliceNumber(e.target.value);
                                setHelperText(false);
                                setPoliceError("");
                            }}
                            // helperProps={helperText && policeError ? helperText : false}
                            //   value={fname}
                            //   onChange={(e) => {
                            //     setFname(e.target.value);
                            //     setFnameError("");
                            //   }}

                            helperText={policeError}
                            helperProps={policeError ? helperText : false}
                            errorProps={policeError ? true : false}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={6}
                    mb={2}
                    style={{ position: "relative" }}
                    className="gridText settingtText"
                >
                    <TextFields
                        defaultValue="Default number"
                        variant="standard"
                        //   style={{ width: "80%" }}
                        textProps={true}
                        disabled
                    // style={{webkitTextFillColor:"black"}}
                    />
                    {console.log("row default1", checked)}
                    <AntSwitch
                        onChange={() => {
                            setChecked(!checked);
                        }}
                        checked={checked}
                        disabled={currentDetails?.local_police_number?.is_default}
                        inputProps={{ "aria-label": "ant design" }}
                    />
                </Grid>

                {/* <DefaultSwitchContainer>
                <TextFields
                    defaultValue="Default number"
                    variant="standard"
                    style={{ marginTop: '25px', width: '225px' }}
                    disabled={true}
                />
                <AntSwitch
                    onChange={() => {
                        setChecked(!checked);
                    }}
                    checked={checked}
                    inputProps={{ 'aria-label': 'ant design' }}
                    customStyle={{top: '28px'}}
                />
            </DefaultSwitchContainer> */}

                {/* <MapHeader style={{ margin: '30px 0' }}>Description for police number:</MapHeader> */}
                <div className="heading-section">
                    <div className="pageTitle" style={{ fontSize: "20px" }}>
                        Description for police number:
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <Grid item xs={6} mb={2} className="gridText textArea">
                        <CustomTextareaContainer style={{ width: "100%" }}>
                            <CustomTextarea
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                multiline
                                variant={"standard"}
                                minRows={4}
                                placeholder="Entry description"
                                style={{
                                    width: "100%",
                                    fontFamily: "SFTextLight",
                                    fontSize: "12px",
                                }}
                                value={discription}
                                onChange={(e) => setDiscription(e.target.value)}
                            />
                        </CustomTextareaContainer>
                    </Grid>
                </div>
                {/* <div> */}
                {/* <MapHeader>Map: </MapHeader> */}
                <div className="heading-section">
                    <div className="pageTitle">Map:</div>
                </div>
                <GooglMap
                    width={'75%'}
                    markerList={[{ lat_long: [latlng.lat, latlng.lng] }]}
                    onPlaceChange={onPlaceChanged}
                    containerStyle={{ width: '80% !important' }}
                />
                {/* </div> */}

                <div style={AddTravelButtonContainer}>
                    <Buttons
                        // variant="contained"
                        // style={{ ...AddButton, width: '48%', borderRadius: '8px' }}
                        style={{ marginLeft: 0, width: "20%" }}
                        onClick={onHandleSave}
                        buttoncheck={buttonProps}
                    >
                        {id ? "Save" : "Add"}
                    </Buttons>
                </div>
                <AddConfirmDialog
                    open={openConfirmDialog}
                    // title={`Delete Subdivision`}
                    content={id ? `Police number saved successfully!` : `Police number added successfully! `}
                    btnLabels={{
                        ok: "Ok",
                    }}
                    onClose={handleConfirm}
                // onConfirm={handleConfirm}
                />
                {/* </AddNumberContainer> */}
            </Box>
        </div>
    );
}
