import styled from "styled-components";

export const CustomErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const CustomError = styled.div`
    font-size: 14px;
    color: rgb(225,108,108);
`