import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
// import styled from "styled-components";

export const AntSwitch = styled(Switch)(({ theme, checkTrue, customStyle }) => ({
    width: 33,
    height: 20,
    padding: 0,
    position: "absolute",
    right: 0,
    borderRadius: "20px",
    // opacity: !!checkTrue ? 1 : 0.5,
    opacity: checkTrue ? 0.5 : 1,
    top: "5px",
    ...customStyle,
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 18,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 1,
        "&.Mui-checked": {
            transform: "translateX(13px)",
            color: "#fff",

            "& + .MuiSwitch-track": {
                opacity: 1,

                backgroundColor: "rgb(72, 146, 195)",
            },
        },
        "&.Mui-checked.Mui-disabled": {
            color: "#fff",
        },
        "& .${switchClasses.switchBase}.${switchClasses.disabled} + .${switchClasses.track} ": {
            // background-color: green;
            opacity: 0.5
        }

    },
    "& .MuiSwitch-thumb": {
        boxShadow: "(72 146 195) 0px 0px 0px 20px inset",
        width: 18,
        height: 18,
        borderRadius: 8,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
  // },/
  "& .MuiSwitch-switchBase": {
    padding: 1,
    
    "&.Mui-checked": {
      transform: "translateX(13px)",
      color: "#fff",
      

      "& + .MuiSwitch-track": {
        opacity: 1,
        
        backgroundColor: "rgb(72, 146, 195)",
      },
    },
    "&.Mui-checked.Mui-disabled": {
      color: "#fff",
      // disabled
      
    },
   " &:disabled" :{
      pointerEvents: 'none',
      cursor:'pointer'
  },
    "& .${switchClasses.switchBase}.${switchClasses.disabled} + .${switchClasses.track} ":{
      // background-color: green;
      opacity: 0.5
  }
    
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "(72 146 195) 0px 0px 0px 20px inset",
    width: 18,
    height: 18,
    borderRadius: 8,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
    theme.palette.mode === "dark" ? "rgb(72, 146, 195)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },

  "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track":{
    opacity:1,
  }
   
}));
