import React, { useState } from 'react';
// import Toolbar from '@material-ui/core/Toolbar';
import { Container, Input, listItemIconClasses } from "@mui/material";
// import useStyles from './index.style';
import { styled } from "@mui/material/styles";
import AmountItems from "../../AmountItems/AmountItems";

import SearchInput from "../../SearchInput";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Container0,
  Container1,
  Button1,
  Button2,
  Container2,
  Container3,
} from "./index.style";
import Grid from '@mui/material/Grid';
import { Buttons } from '../../Button';
import { useSelector } from "react-redux";

// const useStyles = styled((theme) => ({
//     container1: {
//         width: "100%",
//         margin: "opx 0px",
//         maxWidth: "sm",
//         overflow: "hidden",
//         display: "block",
//         zIndex: "100",
//       },
//       secondContainer:{
//         display: "flex",
//         webkitBoxPack: "justify",
//         justifyContent: " space-between",
//         alignItems: " flex-start",
//         flexDirection: "row",
//         margin: "20px",
//         padding: "0px",
//       },
//       ptag:{
//         fontFamily: "SFTextSemibold",
//                     textAlign: "left",
//                     marginBottom: "35px",
//                     fontSize: "22px",
//                     width: "100%",
//                     color: "rgb(92, 99, 115)",
//       },
//       buttonclass:{
//         backgroundColor: "rgb(72, 146, 195)",
//                   margin: "0px",
//                   color: " rgb(255, 255, 255)",
//                   boxShadow: " rgb(98 132 152 / 33%) 0px 11px 26px -10px",
//                   border: "0px",
//                   cursor: "pointer",
//                   outline: "none",
//                   right: "0px",
//                   fontFamily: "SFTextLight",
//                   display: "flex",
//                   webkitBoxPack: "center",
//                   justifyContent: "center",
//                   webkitBoxAlign: "center",
//                   alignItems: "center",
//                   borderRadius: "5px",
//                   height: "36px",
//                   fontSize: "15px",
//                   padding: "0px 25px",
//                   width: "200px",
//                   // marginTop:"19px"
//       }
//   }));

const UserTableToolbar = ({onSearch,searchValue,  submitSearch,clearSearch,changePerPage,activePerPage,addTenant
  
}) => {
    // let classes = useStyles();
    const { users } = useSelector(({ USERS }) => USERS);

  return (
    <>
    {/* <Grid xs={12} sm={12} md={12}> */}
      {/* <Container0> */}
    {/* <Container1> */}
      <div className='heading-section'>
          <div className="pageTitle">List of users <span className="totalCount">({users && users.total ?users.total:'0'})</span></div>
          {/* <Container3>List of users</Container3> */}

          {/* <Button onClick={addTenant}>users && users.total_no_of_records ? users.total_no_of_records : '0'
            <AddIcon />
            Add User
          </Button> */}
          <Buttons onClick={addTenant} addButtonnProps={true} style={{minWidth:"170px"}}>
            {/* <AddIcon /> */}
            <i className="ion-ios-plus-empty btn-plus-icon"></i>
            Add user
          </Buttons>
        {/* </Container1> */}
        </div>
        {/* <Container2> */}
          <div className='serch-section'>
        <SearchInput
              onChange={onSearch}
              searchValue={searchValue}
              onSubmit={submitSearch}
              clearSearch={clearSearch}
              />

<AmountItems
           changePerPage={changePerPage}
           activePerPage={activePerPage}
          />
          </div>
        {/* </Container2> */}
        {/* </Container0> */}
        {/* </Grid> */}
    {/* <Container
        style={{
            width: "100%",
            margin: "opx 0px",
            maxWidth: "sm",
            overflow: "hidden",
            display: "block",
            zIndex: "100",
          }}
        
      >
        
        <Container
          style={{
            display: "flex",
            webkitBoxPack: "justify",
            justifyContent: " space-between",
            alignItems: " flex-start",
            flexDirection: "row",
            margin: "20px",
            padding: "0px",
          }}
        
        >
          <p
            style={{
                fontFamily: "SFTextSemibold",
                textAlign: "left",
                marginBottom: "35px",
                fontSize: "22px",
                width: "100%",
                color: "rgb(92, 99, 115)",
              }}
            
          >
            Tenants Table
          </p>

          <button
            style={{
                backgroundColor: "rgb(72, 146, 195)",
                margin: "0px",
                color: " rgb(255, 255, 255)",
                boxShadow: " rgb(98 132 152 / 33%) 0px 11px 26px -10px",
                border: "0px",
                cursor: "pointer",
                outline: "none",
                right: "0px",
                fontFamily: "SFTextLight",
                display: "flex",
                webkitBoxPack: "center",
                justifyContent: "center",
                webkitBoxAlign: "center",
                alignItems: "center",
                borderRadius: "5px",
                height: "36px",
                fontSize: "15px",
                
                marginTop:'20px',
                width: "250px",
              }}
            onClick={addSubdivisionFunction}
            
          >
            <AddIcon/>Add Tenant
          </button>
        </Container>
        </Container> */}
        {/* <Container
          style={{
            display: "flex",
            webkitBoxPack: "justify",
            justifyContent: "space-between",
            marginRight: "10px",
          }}
        >
          <form style={{ width: "50%" }}>
            <div
              style={{
                position: "relative",
                display: "flex",
                webkitBoxPack: "justify",
                justifyContent: "space-between",
                webkitBoxAlign: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <SearchInput
              onChange={onSearch}
              searchValue={searchValue}
              onSubmit={submitSearch}
              clearSearch={clearSearch}
              />

            </div>{" "}
          </form>
          <AmountItems
           changePerPage={changePerPage}
           activePerPage={activePerPage}
          />

        </Container> */}
        
    </>

  );
};

export default UserTableToolbar;
