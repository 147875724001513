import { UPDATE_IN_HOUSE_LIST, GET_INHOUSELIST, ADD_TENANT, EDIT_SUBDIVISION_USER, SET_USER, GET_INHOUSELIST_SOCKET } from "../../@jumbo/constant/ActionTypes";

const INIT_STATE = {
  InHouseUsers: [],
  edit: null

};
// console.log("testing",INIT_STATE.InHouseUsers)
let finalData
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INHOUSELIST: {
      return {
        ...state,
        InHouseUsers: action.payload,
      };
    }

    case GET_INHOUSELIST_SOCKET: {
      let alertLogs = [];
      let currentAlertLogs = state?.InHouseUsers?.alertLogs;
      if (currentAlertLogs && currentAlertLogs.length > 0) {
        alertLogs = currentAlertLogs.filter((alert) => alert.alertId !== action.payload.crisisInHouse?.alertId)
      }
      return {
        ...state,
        users: {
          ...state.users,
          total_alerts: action.payload.alertcount,
          alertLogs: [action?.payload?.crisisAlert, ...alertLogs]
        },
      }
    }

    case SET_USER: {

      return {
        ...state,
        edit: action.payload,
      };
    }
    case ADD_TENANT: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }


    case EDIT_SUBDIVISION_USER: {
      return {
        ...state,
        allUsers: state.allUsers.map(allUsers => (allUsers.id === action.payload._id ? action.payload : allUsers)),
      };

    }
    case UPDATE_IN_HOUSE_LIST: {
      return {
        ...state,
        InHouseUsers: { ...state.InHouseUsers, ...action?.payload }
      };

    }
    default:
      return state;
  }
};
