import styled from 'styled-components'
import { Button } from "@mui/material";

export const FilterButton = styled(Button)({
    borderRadius: '8px !important',
    // padding: '8px !important',
    margin: '0px 5px 0px 0px !important',
    marginTop: '10px !important',
    fontSize: '16px !important',
    fontFamily: "SF Pro Text !important",
    textTransform: 'none !important',
    "&:hover": {
        backgroundColor: "rgb(72, 146, 195) !important",
        color: 'white !important'
    },
})