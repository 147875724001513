import React, { useEffect, useState, useMemo } from 'react'
import { MapHeader, RoomsButton, AddLocationContainer, AddTravelButtonContainer, InputFieldWrapper, NoRoomsWrapper, CustomChip } from '../style'
import { Button, Grid, Box, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import GooglMap from 'components/GooglMap';
import { useDispatch, useSelector } from 'react-redux';
import { emptySpace } from '@jumbo/constant/ValidationRegex';
import { requiredMessage } from '@jumbo/constant/ErrorMessages';
import { useLocation, useNavigate } from 'react-router';
import { TextFields } from '../../Textfeild';
import { GoogleAutocomplete } from 'components/GoogleAutocomplete';
import { Buttons } from '../../Button';
import { addInhouseLoc, editInhouseLoc, getInHouseDetail, getInhouseLocList, isInHouseEdit, resetAddInHouseLoc, resetEditInHouseLoc } from 'redux/actions/alert';
import ConfirmDltDialog from '../../ConfirmDltDialog';



export default function AddEditLocation() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [errors, setErrors] = useState({})
    const [latlng, setLatLng] = useState({})
    const [destination, setDestination] = useState('')
    const [office, setOffice] = useState('');
    const [rooms, setRooms] = useState('');
    const [roomsArray, setRoomsArray] = useState([]);
    const [openAddConfirm, setOpenAddConfirm] = useState(false);
    const [confirmContent, setConfirmContent] = useState('');
    const [buttonProps, setButtonProps] = useState('addbutton');
    const loacation = useLocation();

    const loc_id = location && location.pathname && location.pathname.split('/').pop()
    const isEdit = location && location.pathname && location.pathname.includes('edit-location')
    const { addedInHouseLoc, updatedInHouseLoc, isHouseLocEdit, inHouseLocEditData, inHouseLocDetail } = useSelector(({ ALERT }) => ALERT)

    useEffect(() => {
        isEdit && dispatch(getInHouseDetail({ id: loc_id }))
        localStorage.setItem("inHousetab", 2)
    }, [])

    useEffect(() => {
        if (isEdit && inHouseLocDetail && Object.keys(inHouseLocDetail).length) {
            const { address, office, room } = inHouseLocDetail
            const arr = room ? room.map(x => x.roomName) : []
            setDestination(address.street_address || '')
            address.lat_long && address.lat_long.length && setLatLng({ lat: address.lat_long[0] || '', lng: address.lat_long[1] || '' })
            setRoomsArray(arr)
            setOffice(office)
            document.getElementById("autocomplete-input").value = address.street_address || ''
        }
    }, [inHouseLocDetail])

    useEffect(() => {
        if (addedInHouseLoc && Object.keys(addedInHouseLoc).length) {
            setConfirmContent('Location added successfully!!')
            setOpenAddConfirm(true)
            dispatch(resetAddInHouseLoc())
        }

        if (updatedInHouseLoc && Object.keys(updatedInHouseLoc).length) {
            setConfirmContent('Location updated successfully!!')
            setOpenAddConfirm(true)
            dispatch(resetEditInHouseLoc())
        }
    }, [addedInHouseLoc, updatedInHouseLoc])

    const onAddConfirm = () => {
        setOpenAddConfirm(false)
        navigate('/app/inhouse')
    }

    const onHandleAddRooms = () => {
        if (!rooms.match(emptySpace)) {
            const list = [...roomsArray]
            const idx = list.findIndex(x => x === rooms)
            if (idx === -1) {
                list.push(rooms)
                setRoomsArray(list)
            }
            setRooms('')
        }
    }

    const onHandleRemove = (index) => {
        const list = [...roomsArray]
        list.splice(index, 1)
        setRoomsArray(list)
    }

    const onHandleSave = () => {
        let errorList = {}
        if (destination.match(emptySpace) || Object.keys(latlng).length === 0) {
            errorList = { ...errorList, destination: requiredMessage }
        }

        if (office.match(emptySpace)) {
            errorList = { ...errorList, office: requiredMessage }
        }
        if (rooms.match(emptySpace) || roomsArray.length <= 0) {
            errorList = { ...errorList, rooms: requiredMessage }
        }
        if (office && destination && Object.keys(latlng).length > 0 && roomsArray.length > 0) {
            const params = {
                lat: latlng.lat,
                long: latlng.lng,
                office,
                room: roomsArray
            }
            if (isEdit) {
                dispatch(editInhouseLoc({ loc_id, ...params }), () => dispatch(getInhouseLocList()))
            } else {
                dispatch(addInhouseLoc(params), () => { dispatch(getInhouseLocList()) })
            }
        } else {
            setErrors(errorList)
        }
    }

    const removeError = (field) => {
        const err = { ...errors }
        delete err[field]
        setErrors(err)
    }

    const onPlaceChanged = (place, latlng) => {
        removeError('destination')
        setDestination(place)
        setLatLng(latlng)
    }

    const onGoogleBoxChange = (e) => {
        removeError('destination')
        setDestination(e.target.value)
        if (e.target.value === "") {
            setLatLng({})
        }
    }

    const onRemoveAddress = () => {
        setDestination('')
        setLatLng({})
        document.getElementById("autocomplete-input").value = ''
    }

    const onOfficeChanged = (e) => {
        removeError('office')
        setOffice(e.target.value);
    }

    const onRoomsChanged = (e) => {
        removeError('rooms')
        setRooms(e.target.value);
    }

    const renderRooms = () => {
        return (
            <Grid item xs={12} mb={2} className="gridText" style={{ position: 'relative' }}>
                <TextFields
                    id="standard-basic"
                    label="Rooms*"
                    variant="standard"
                    value={rooms}
                    onChange={onRoomsChanged}
                    style={{ marginLeft: '30px', width: '100%' }}
                    helperText={errors.rooms}
                    helperProps={errors.rooms}
                    errorProps={errors.rooms}
                />
                {rooms && !rooms.match(emptySpace) &&
                    <Button variant="contained"
                        style={{ ...RoomsButton, width: '50px' }}
                        onClick={onHandleAddRooms}
                        buttoncheck={buttonProps}
                    >Add</Button>}
            </Grid>
        )
    }

    const visibleTodos = useMemo(
        () => {
            return (
                <GooglMap markerList={[{ lat_long: [latlng.lat, latlng.lng] }]} onPlaceChange={onPlaceChanged} addTravel={true} />
            )
        }, [latlng]

    );

    const renderOfficeRoomsFields = () => {
        return (
            <Box
                component="form"
                sx={{
                    "& > :not(style)": {
                        width: "50ch",
                        mb: 0,
                        width: "100%",
                    },
                    flexGrow: 1
                }}
                noValidate
                autoComplete="off"
                style={{
                    color: "rgba(0, 0, 0, 0.87)",
                    ...InputFieldWrapper
                }}
            >
                <Grid container spacing={2} ml={0} className="formGrid">
                    <Grid item xs={12} mb={2} className="gridText">
                        <TextFields
                            id="standard-basic"
                            label="Office*"
                            variant="standard"
                            value={office}
                            onChange={onOfficeChanged}
                            helperText={errors.office}
                            helperProps={errors.office}
                            errorProps={errors.office}
                        />
                    </Grid>
                    {renderRooms()}
                </Grid>
            </Box>
        )
    }

    const renderRoomsChip = () => {
        return (
            <Stack direction="row" style={{ display: 'flex', flexWrap: 'wrap', width: '60%' }} spacing={1}>
                {roomsArray && roomsArray.length > 0 ? roomsArray.map((data, index) => {
                    return (<CustomChip
                        variant="solid"
                        color="primary"
                        onDelete={() => onHandleRemove(index)}
                        deleteIcon={<Close fontSize="sm" />}
                        label={data}
                        style={{ marginTop: '12px' }}
                        key={`rooms-${index}`} />)
                }) : <NoRoomsWrapper style={{ color: 'rgb(92, 99, 115)' }}>No rooms</NoRoomsWrapper>}
            </Stack>
        )
    }

    return (
        <AddLocationContainer>
            <MapHeader>{inHouseLocDetail && isEdit ? 'Edit' : 'Add'} Location</MapHeader>
            <GoogleAutocomplete
                error={errors.destination}
                onChange={onGoogleBoxChange}
                onRemove={onRemoveAddress}
                value={destination}
                inputWrapperStyle={{ width: '55%' }}
                errorStyle={{ width: '55%' }}
            />
            {renderOfficeRoomsFields()}
            {renderRoomsChip()}
            <div>
                <MapHeader>Map: </MapHeader>
                {/* <GooglMap markerList={[{ lat_long: [latlng.lat, latlng.lng] }]} onPlaceChange={onPlaceChanged} addTravel={true} />
                 */}
                {
                    visibleTodos
                }
            </div>
            <div style={AddTravelButtonContainer}>
                .                <Buttons
                    variant="contained"
                    buttonProps={{ padding: '14px 15px !important', minWidth: '165px !important' }}
                    onClick={onHandleSave}
                >{inHouseLocDetail ? 'Save' : 'Add'}</Buttons>
            </div>
            <ConfirmDltDialog
                open={openAddConfirm}
                content={confirmContent}
                btnLabels={{
                    Yes: "OK",
                }}
                onConfirm={onAddConfirm}
            />
        </AddLocationContainer>
    )
}