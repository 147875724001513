
import { IMPORTANT_CONTACT_LIST, ADD_IMPORTANT_CONTACT, UPDATE_IMPORTANT_CONTACT, REMOVE_IMPORTANT_CONTACT, SET_IMPORT_CONTACT, RESET_IMPORT_LIST } from "../../@jumbo/constant/ActionTypes";
import { importantContactList, addImportantContact, updateImportantContact, removeImportantContact } from "../../@jumbo/constant/ApiConstant";
import axios from '../../helper/customAxios';
import { fetchError, fetchStart, fetchSuccess, fetchStartSidebar } from './Common';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS } from '../../@jumbo/constant/ActionTypes';
import { Form, useNavigate } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { refreshToken } from "./Auth";
// import { fetchError, fetchStart, fetchSuccess } from './Common';



// orderBy, order, searchValue, activePage, activePerPage
export const getImportantContactList = () => {
  let broswerHistory = createBrowserHistory()
  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    dispatch(fetchStartSidebar())
    axios.get(importantContactList)
      .then(data => {
        if (data.status === 200) {
          // if (!data.data.data.userData.length) {
          //   dispatch({ type: IMPORTANT_CONTACT_LIST, payload: [] }); 
          // }else{
          //   dispatch({ type: IMPORTANT_CONTACT_LIST, payload: data.data.data });  
          // }
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: IMPORTANT_CONTACT_LIST, payload: data.data.data });
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch({ type: IMPORTANT_CONTACT_LIST, payload: [] });

        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(getImportantContactList())
          }))
          return;
        }
        if (error.response && error.response.status === 401 || error.response && error.response.status === 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }

      });

  };
};

export const resetImportList = () => {
  return dispatch => {
    dispatch({ type: RESET_IMPORT_LIST })
  }
}

export const setCurrentImportContact = user => {
  return dispatch => {
    dispatch({ type: SET_IMPORT_CONTACT, payload: user });
  };
};

export const addImportantContacts = (adduser, onClose, callbackFun, list) => {

  return dispatch => {
    const token = JSON.parse(localStorage.getItem('userToken'));
    axios.defaults.headers.common['Authorization'] = token
    dispatch(fetchStart());
    axios
      .post(addImportantContact, adduser)
      .then(data => {

        if (data) {
          // dispatch(onClose());
          dispatch({ type: FETCH_SUCCESS, loading: false });

          if (callbackFun) { callbackFun.setAddDialog(); }
          if (onClose) {
            setTimeout(() => {
              onClose();
            }, 300);
          }
          // if(list){}
          dispatch(list());
          setTimeout(() => {
            dispatch(fetchSuccess('Contact Added Sucessfully.'));
          }, 4000);
        } else {
          dispatch({ type: ADD_IMPORTANT_CONTACT, payload: [] });
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(addImportantContacts(adduser, onClose, callbackFun, list))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
      });
  };
};


export const UpdateImportantContacts = (important_contact_id, user, callbackFun, onClose, list) => {

  return dispatch => {

    dispatch(fetchStart());
    axios
      .put(updateImportantContact + important_contact_id, user)
      .then(data => {
        if (data.status == 200) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          if (callbackFun) {
            // onclose.onclose();
            callbackFun.setAddDialog();
          }
          if (onClose) {
            setTimeout(() => {
              onClose();
            }, 300);
          }

          dispatch(list())

          setTimeout(() => {
            dispatch(fetchSuccess('contact updated successfully .'));
          }, 4000);
        } else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(UpdateImportantContacts(important_contact_id, user, callbackFun, onClose, list))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });
  };
};

export const removeImportantContacts = (selectedUser, callbackFun, list) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(removeImportantContact,
        {
          'importantContactId': selectedUser
        }
      )
      .then(data => {
        if (data.status == 200) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          if (callbackFun) callbackFun.setAddDialog();
          dispatch(list())
          // list();
          // dispatch(list());

          setTimeout(() => {
            dispatch(fetchSuccess('contact deleted successfully .'));
          }, 4000);
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })

      .catch(error => {
        if (error.response && error.response.status == 444) {
          dispatch(refreshToken(() => {
            dispatch(removeImportantContacts(selectedUser, callbackFun, list))
          }))
          return;
        }
        if (error.response && error.response.status == 401 || error.response && error.response.status == 400) {
          dispatch({ type: FETCH_SUCCESS, loading: false });
          dispatch(fetchError(error.response.data.message));
        }
        else {
          dispatch({ type: FETCH_SUCCESS, loading: false });
        }
      });
  };
};