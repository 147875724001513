import * as React from "react";

import { styled } from "@mui/material/styles";
// import Button from '@material-ui/Button';
import { Button } from "@mui/material";

export const Buttons = styled(Button)(({ buttoncheck, buttonProps }) => ({
    // '& .MuiButton-label':{
    background: "rgb(72, 146, 195)",
    // textTransform: "capitalize",
    cursor:'pointer',
    textTransform: "unset",
    padding: buttoncheck ? "0 15px" : "0 15px",
    fontFamily: buttonProps ? 'SF Pro Text' : "SFTextLight",
    cursor: "pointer",
    lineHeight: "1.2",
    borderRadius: buttoncheck ? "8px" : "5px",
    fontSize: buttoncheck ? "16px" : "15px",
    color: "white",
    minWidth: buttoncheck ? "192px" : "200px",
    minHeight: buttoncheck ? "49px" : "36px",
    marginLeft: buttoncheck ? "16px" : "0px",
    "&:hover": {
        backgroundColor: "rgb(62, 138, 193)",
    },
    ...buttonProps,
}));

// export const Buttons = styled(Button)(({ theme }) => ({
//   color: "white",
//   backgroundColor: "rgb(72, 146, 195)",
//   padding: "15px",
//   width: "20%",
//   lineHeight: "1.2",
//   borderRadius: "8px ",
//   fontSize: "16px",
// //   boxShadow: 'rgb(98 132 152 / 33%) 0px 11px 26px -10px ',
//   "&:hover": {
//     backgroundColor: "rgb(62, 138, 193)",
//   },
// }));
