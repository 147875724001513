export default {
    black: '#000',
    white: '#fff',
    blue: '#4892C3',
    lightblue: '#4E91C0',
    blueborder: '#A8C9E0',
    redborder: '#F0B7B8',
    bluebackground: '#F7F9FC',
    green: '#5FAAB0',
    red: '#E16C6C',
    darkblue: '#316385',
    redbold: '#DD6161',
    grey: '#71757d',
    lightgrey: '#9099AA',
    greytip: '#AEB4C0',
    greyicon: '#AEB4C0',
    bordergrey: 'rgba(174,180,192,0.24)',
    greylighter: '#F1F1F1',
    greybackground: '#F9F9F9',
    graybackground: '#FAFAFA',
    greylighttext: '#858EA0',
    greyborder: '#E5E6E9',
    greytext: '#5C6373',
    lightred: '#FEF8F8',
    redtext: '#DD6161',
    greyhover: '#F0F0F0',
    greyminiborder: '#EEEEEE',
    greytoggle: '#A5ABB8',
    sidebarcolor: 'rgb(240, 240, 240)',
    redTabColor: 'rgb(225, 108, 108)',
    blueTabColor: '#1976d2'
};
