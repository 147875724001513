import React, { useState, useEffect } from "react";
import * as moment from "moment";
// import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { Form, useNavigate } from "react-router-dom";
import { onLogout } from "../../redux/actions/Auth";
import Clock from 'react-live-clock';


function Header() {
  const date = new Date();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  var time = date.getHours() + ":" + date.getMinutes();
  const { currentuser } = useSelector(({ USERPROFILE }) => USERPROFILE);
  const [email, setEmail] = React.useState('');
  let Auth = localStorage.getItem("userToken")
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const d = new Date();
  let day = weekday[d.getDay()];
  let emailcheck = localStorage.getItem("profile")
  var date1 = moment();
  console.log("addSub2")
  var currentDate = date1.format('DD-MM-YYYY');
  useEffect(() => {
    if (currentuser) {
      if (currentuser.detail) {
        setEmail(!!currentuser ? currentuser.detail.email : null)
      }

    }

  }, [currentuser])

  const logoutRedirect = () => {
    navigate('/login')
  }

  return (
    // <header className="header-section sticky-top ">



    <header
      style={{
        backgroundColor: Auth ? 'rgb(255, 255, 255)' : " rgb(249, 249, 249)",
        borderBottom: " 1px solid rgb(241, 241, 241)",
        padding: "15px 0px",
        minWidth: "800px",
        // position:'fixed',
        position: 'sticky'

      }}
    >
      <div
        style={{
          display: " flex",
          // -webkit-box-pack: justify;
          WebkitBoxPack: "justify",
          justifyContent: "space-between",
          margin: Auth ? "0px 50px" : " 0px 170px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100px",
            cursor: "pointer",
            font: "inherit",
            // marginLeft:Auth?0:'inherit'
          }}
        >
          {currentuser && currentuser?.detail?.logo_image ?
            <img
              // className="ms-2"
              style={{ height: "48px", maxWidth: "100%" }}
              src={currentuser?.detail?.logo_image}
            />
            :
            <img
              // className="ms-2"
              style={{ height: "48px", maxWidth: "100%" }}
              src={process.env.PUBLIC_URL + "/images/logo.svg"}
            />}
          <div
            style={{
              fontFamily: "SFTextLight",
              color: "rgb(63, 135, 187)",
              fontSize: "20px",
              lineHeight: "1.2",
              marginLeft: "10px",
              verticalAlign: "baseline",
              display: "block",
              cursor: "pointer",
            }}
          >
            Crisis Control
          </div>
          <div
            style={{
              position: "absolute",
              left: "270px",
              display: "flex",
              flexDirection: " column",
              justifyContent: " space-around",
              height: " 48px",
              font: "inherit",
              verticalAlign: "baseline",
            }}
          >
            <div
              style={{
                fontFamily: "SF Pro Text",
                color: " rgb(144, 153, 170)",
                fontSize: "14px",
                marginLeft: "10px",
                display: "block",
              }}
            >
              {/* <Moment format="dddd DD-MM-YYYY">{date}</Moment> */}
              {/* {Auth ? `${day} ${currentDate} ` : null} */}
              {
                Auth &&
                <>
                  <Clock format="dddd, DD-MM-YYYY" interval={1000} ticking={true} />
                </>
              }
            </div>
            <div
              style={{
                fontFamily: "SF Pro Text",
                color: " rgb(144, 153, 170)",
                fontSize: "14px",
                marginLeft: "10px",
                display: "block",
              }}
            >
              {Auth &&
                <>
                  {/* {`Time: ${time}`} */}
                  <span>
                    {`Time:`}
                    <Clock format="HH:mm:ss" blinking={true} interval={1000} ticking={true} />
                  </span>
                </>
              }
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "48px",
            // -webkit-box-flex: 2,
            WebkitBoxFlex: "2",
            flexGrow: 2,
            textAlign: "right",
            paddingRight: "20px",
            font: "inherit",
            verticalAlign: "baseline",
          }}
        >
          <div
            style={{
              fontFamily: "SF Pro Text",
              color: " rgb(144, 153, 170)",
              fontSize: "14px",
              marginLeft: "15px",
              fontWeight: 400

            }}
          >
            {Auth ? email : null}
          </div>
        </div>
        <div className="logout-section"
          onClick={() => dispatch(onLogout(
            logoutRedirect))}
        >
          {Auth ? <><div className="logoutIcon">
            {/* <img src={process.env.PUBLIC_URL + "/images/log-out-default.svg"}  
            style={{
              display: "flex",
              alignItems: "center",
              //width: "100px",
              cursor: "pointer",
              marginRight:"10px"
            }}
            
            /> */}

            {/* <LogoutIcon
              style={{
                display: "flex",
                alignItems: "center",
                //width: "100px",
                cursor: "pointer",
                marginRight:"8px"
              }}
              width='20'
            /> */}
            {/* /* .logoutIcon:hover { */}
            {/* /* background-image: url(/images/log-out-active.svg); */

              /* background-color: #1976d2; */
            }
          </div>

            <div
              className="logout-btn"

            >
              Log out
            </div></> : null}
        </div>
      </div>
      {/* <div className="dropdown float-end">
        <a
          href="#"
          className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle"
          id="dropdownUser1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Faenza-avatar-default-symbolic.svg/240px-Faenza-avatar-default-symbolic.svg.png"
            alt=""
            width="30"
            height="30"
            className="rounded-circle me-2"
          />
          <span>{authUser ? authUser.first_name : ""} </span>
          <img
            className="ms-2"
            width="18"
            src={process.env.PUBLIC_URL + "/img/down-arrow-color.svg"}
          />
        </a>
        <ul className="dropdown-menu  shadow" aria-labelledby="dropdownUser1">
          <li>
            <NavLink to="/home/profile" className="dropdown-item">
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/aboutus" className="dropdown-item">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/termsandconditions" className="dropdown-item">
              Terms & Conditions
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/CookiePolicy" className="dropdown-item">
             Cookie policy
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/privacypolicy" className="dropdown-item">
              Privacy Policy
            </NavLink>
          </li>
          <li>
            <NavLink to='/home' onClick={() =>window.open('https://plc-alarm.com/docs/')} className="dropdown-item">
            Documentation 
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/contactus" className="dropdown-item">
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/home/changepassword" className="dropdown-item">
              Change Password
            </NavLink>
          </li>
        
          <li>
            <a className="dropdown-item" href="#" onClick={onLogoutClick}>
              Logout
            </a>
          </li>
        </ul>
      </div> */}
    </header>

  );
}

export default React.memo(Header);
