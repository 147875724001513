import React, { useEffect, useState, useRef } from "react"
import { Button } from "@mui/material";
import { NameTravelIDBox, CustomTextarea, AddButtonContainer, testModeStyle, AddButton, printHeaderStyle, printFooterStyle, headerInnerEleStyle, CustomErrorWrapper, CustomError, CustomTextareaContainer } from '../style'
import { addTravelComment, resetTravelComment } from 'redux/actions/travel';
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from 'react-to-print';
import { LogPrint } from "./LogPrint";
import * as moment from "moment";
import { TRAVEL_STATUS } from "../data";
import { requiredMessage } from "@jumbo/constant/ErrorMessages";
import COLORS from "utils/colors";
import { Buttons } from "../../Button";

export default function CrisisLog({ travel_id, navigate, printHeadRef }) {
    const dispatch = useDispatch();
    const [comment, setComment] = useState('')
    const [errors, setErrors] = useState({})
    const printRef = useRef(null);
    const [buttonProps, setButtonProps] = useState("addbutton");
    const { travelLogDetail, addTravelComment: addedComment } = useSelector(({ TRAVEL }) => TRAVEL)
    // const [userLogDetail, setUserLogDetail] = useState(travelLogDetail.UserTravelDetails ? travelLogDetail.UserTravelDetails : {})

    const printContent = () => {
        const tableStat = printRef.current.cloneNode(true);
        const headerStat = printHeadRef.current.cloneNode(true);
        const headerStatChild = headerStat.children
        headerStatChild[0].style.cssText = `font-size: 16px;`
        headerStatChild[1].style.cssText = `margin: 20px 0 20px 0; width: 100%;`
        headerStatChild[2].style.cssText = `font-size: 16px;`
        const subChild = headerStatChild[1].children
        subChild[0].style.cssText = `font-size: 15px;`

        const PrintElem = document.createElement('div');
        PrintElem.style.cssText = `padding: 40px 30px; width: 75%;`

        const header = document.createElement('div');
        header.style.cssText = printHeaderStyle
        const headerInnerEle = document.createElement('div')
        headerInnerEle.style.cssText = headerInnerEleStyle
        const timeEle = document.createElement('div')
        timeEle.innerHTML = `${moment().format('M/DD/YY HH:MM A')}`
        const titleEle = document.createElement('div')
        titleEle.innerHTML = `Crisis communitcation`

        const testMode = document.createElement("div");
        testMode.style.cssText = testModeStyle;
        testMode.innerHTML = travelLogDetail.UserTravelDetails.test_mode ? "Test Mode" : '';

        headerInnerEle.appendChild(timeEle)
        headerInnerEle.appendChild(titleEle)
        header.appendChild(headerInnerEle)

        const footer = document.createElement('div');
        footer.style.cssText = printFooterStyle
        footer.innerHTML = `${window.location.href}`



        // PrintElem.appendChild(header);
        PrintElem.appendChild(testMode);
        PrintElem.appendChild(headerStat);
        PrintElem.appendChild(tableStat);
        // PrintElem.appendChild(footer);
        return PrintElem;
    }

    const handlePrint = useReactToPrint({
        content: printContent,
        pageStyle: `@media print { html, body { font-size: 10px } } `
    });

    // useEffect(() => {
    //     if (Object.keys(addedComment).length) {
    //         const tempLogDetail = { ...userLogDetail }
    //         if (Object.keys(tempLogDetail).length) {
    //             const tempList = [...tempLogDetail.travelLogs] // log keyname
    //             tempList.unshift(addedComment)
    //             tempLogDetail.travelLogs = tempList
    //             setUserLogDetail(tempLogDetail)
    //             dispatch(resetTravelComment())
    //         }
    //     }
    // }, [addedComment])

    // useEffect(() => {
    //     if (Object.keys(travelLogDetail).length > 0) {
    //         setUserLogDetail(travelLogDetail.UserTravelDetails ? travelLogDetail.UserTravelDetails : {})
    //     }
    // }, [travelLogDetail])

    const onAddComment = () => {
        if (!comment) {
            setErrors({ ...errors, comment: requiredMessage })
        } else {
            dispatch(addTravelComment({ travel_id, comment }))
            setComment('')
        }
    }

    const onCommentChange = (e) => {
        setErrors({})
        setComment(e.target.value)
    }

    const renderError = (msg, style) => {
        return (
            <CustomErrorWrapper style={style}>
                <CustomError>{msg}</CustomError>
            </CustomErrorWrapper>
        )
    }

    const renderLogTable = () => {
        return (
            <div style={{ marginTop: '40px', /* borderTop: '1px solid rgba(224, 224, 224, 1)' */ }}>
                <LogPrint userLogDetail={travelLogDetail?.UserTravelDetails} ref={printRef} />
                <NameTravelIDBox style={{ width: '100%' }}>
                    <Buttons
                        // variant="contained"
                        // style={{ ...AddButton, width: '20%', fontSize: '16px', marginTop: '60px' }}
                        style={{ width: "22%", fontSize: "16px", marginTop: "60px" }}
                        buttoncheck={buttonProps}
                        onClick={handlePrint}
                    >
                        Download</Buttons>
                    {TRAVEL_STATUS.IN_PROGRESS === travelLogDetail?.UserTravelDetails?.status && <Buttons
                        // variant="contained"
                        style={{ marginTop: '60px' }}
                        buttoncheck={buttonProps}
                        // onClick={() => navigate(`/app/CrisisLog/travel-detail/${travel_id}`)}
                        onClick={() => navigate(`/app/travel/travel-detail/${travel_id}`)}
                    >
                        Open Travel Control</Buttons>}
                </NameTravelIDBox>
            </div>
        )
    }

    return (
        <div style={{ marginTop: '35px', width: '78%' }}>
            {/* {renderLogBox()} */}
            {renderLogTable()}
        </div>
    )
}