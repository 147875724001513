import axios from '../../helper/customAxios';
import { SET_CHECKLIST } from '../../@jumbo/constant/ActionTypes';
import { checklistURL, addChecklistURL, completeChecklistURL, updateCheclistURL, deleteChecklistURL } from '../../@jumbo/constant/ApiConstant'
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { ALERT_TYPE, CHECKLIST_TYPE } from '../../utils/alertHelper';
import { getAlertDetail } from './CrisisLogAlert';
import { getTravelLogDetail } from './travel';
import { refreshToken } from './Auth';

// to remove undefined value from object i used JSON.parse(JSON.stringify(params))

export const getCheckList = (params/* type,id */) => {
    return dispatch => {
        dispatch(fetchStart());
        axios.get(checklistURL, { params: JSON.parse(JSON.stringify(params)) }).then((res) => {
            if (res?.data?.data) {
                dispatch({ type: SET_CHECKLIST, payload: res?.data?.data });
            }
            dispatch(fetchSuccess());
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(getCheckList(params))
                }))
                return;
            }
            if (err?.response?.data?.message) {
                dispatch(fetchError(err?.response?.data?.message));
            } else {
                dispatch(fetchError());
            }
        })
    }
}
export const addCheckList = (data, id, { resetForm }, secSubdivisionUser) => {
    let params;
    if (secSubdivisionUser) {
        params = { id, subdivision_id: secSubdivisionUser }
    } else {
        params = { id }
    }
    return dispatch => {
        dispatch(fetchStart());
        axios.post(addChecklistURL, data, { params }).then((res) => {
            if (res?.data?.data) {
                resetForm({});
                dispatch(getCheckList({ type: data?.type, id, subdivision_id: secSubdivisionUser }));
            }
            dispatch(fetchSuccess(res?.data?.message || ''));
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(addCheckList(data, id, { resetForm }, secSubdivisionUser))
                }))
                return;
            }
            if (err?.response?.data?.message) {
                dispatch(fetchError(err?.response?.data?.message));
            } else {
                dispatch(fetchError());
            }
        })
    }
}
export const completeCheckList = (actionId, data, { resetForm }, subdivisionId) => {
    return dispatch => {
        dispatch(fetchStart());
        axios.put(completeChecklistURL + actionId, JSON.parse(JSON.stringify(data))).then((res) => {
            if (res?.data?.data) {
                dispatch(getCheckList({ type: data?.type, id: data?.id, subdivision_id: subdivisionId }));
                if ([CHECKLIST_TYPE.TRAVEL.value].includes(data?.type)) {
                    dispatch(getTravelLogDetail({ travel_id: data?.id }));
                } else {
                    dispatch(getAlertDetail(data?.id));
                }
                resetForm({});
            }
            dispatch(fetchSuccess(res?.data?.message || ''));
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(completeCheckList(actionId, data, { resetForm }, subdivisionId))
                }))
                return;
            }
            if (err?.response?.data?.message) {
                dispatch(getCheckList({ type: data?.type, id: data?.id, subdivision_id: subdivisionId }));
                dispatch(fetchError(err?.response?.data?.message));
            } else {
                dispatch(fetchError());
            }
        })
    }
}
export const deleteCheckList = (actionId, id, data/* type */, subdivisionId) => {
    return dispatch => {
        dispatch(fetchStart());
        axios.put(deleteChecklistURL + actionId, data, { params: { id } }).then((res) => {
            if (res?.data?.data?.is_deleted) {
                dispatch(getCheckList({ type: data?.type, id: id, subdivision_id: subdivisionId }));
                dispatch(fetchSuccess(res?.data?.message || ''));
            } else if (res?.data?.message) {
                dispatch(fetchError(res?.data?.message || ''));
            }
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(deleteCheckList(actionId, id, data, subdivisionId))
                }))
                return;
            }
            if (err?.response?.data?.message) {
                dispatch(getCheckList({ type: data?.type, id: data?.id, subdivision_id: subdivisionId }));
                dispatch(fetchError(err?.response?.data?.message));
            } else {
                dispatch(fetchError());
            }
        })
    }
}

// updateChecklistURL
export const updateCheckList = (data, id, { resetForm }, secSubdivisionUser, action_id) => {
    return dispatch => {
        dispatch(fetchStart());
        axios.put(updateCheclistURL + action_id, data, { params: { id } }).then((res) => {
            if (res?.data?.data) {
                resetForm({});
                dispatch(getCheckList({ type: data?.type, id, subdivision_id: secSubdivisionUser }));
            }
            dispatch(fetchSuccess(res?.data?.message || ''));
        }).catch((err) => {
            if (err.response && err.response.status == 444) {
                dispatch(refreshToken(() => {
                    dispatch(updateCheckList(data, id, { resetForm }, secSubdivisionUser, action_id))
                }))
                return;
            }
            if (err?.response?.data?.message) {
                dispatch(fetchError(err?.response?.data?.message));
            } else {
                dispatch(fetchError());
            }
        })
    }
}
