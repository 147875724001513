import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Form, useNavigate } from "react-router-dom";
// import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ConfirmDltDialog from "./../ConfirmDltDialog";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useDebounce } from "utils/commonHelper";
import Pagination from "../Pagination/Pagintions";
import AmountItems from "../AmountItems/AmountItems";
import UserList from "./UserList";
import SearchInput from "../SearchInput";
import {
  getAllUsersList,
  getCurrUserDetail,
  deleteSubdivision
} from "redux/actions/SubDivision";
import {
  Button,
  Container,
  Container1,
  Button1,
  Button2,
  Container2,
  Container3,
} from "./style";
import { Buttons } from "../Button";
import { useParams } from "react-router-dom";
import SvgIcon from '@mui/material/SvgIcon';
import AddConfirmDialog from "../AddConfirmDialog";
import ReactTable from "components/common/table/ReactTable2";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { GET_USER_DETAILS } from "@jumbo/constant/ActionTypes";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const HomeIcon = (props) => {
  return (
    <div>
      <SvgIcon >
        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
      </SvgIcon>
    </div>
  )
}


const HomeIcon1 = (props) => {
  return (
    <div>
      <SvgIcon >
        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
      </SvgIcon>
    </div>
  )
}

const perPages = [10, 20, 50, 100];
// testing perpose
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 }]

export default function SubDivisions() {
  const { allUsers } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
  const userProfile = useSelector(store => store?.USERPROFILE?.currentuser?.detail);

  const [page, setPage] = React.useState(0);
  const [selectedUser, setSelectedUser] = useState({ first_name: "" });
  // const [order, setOrder] = React.useState("desc");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // const [orderBy, setOrderBy] = React.useState("first_name");
  const [openConfirmDltDialog, setopenConfirmDltDialog] = useState(false)
  const [searchValue, setSearchValue] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [activePerPage, setActivePerPage] = useState(10);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const columns = [
    {
      id: "id",
      accessor: "id",
      title: "Id",
      width: "8%",
      tag: true,
      sortable: true,
    },
    {
      id: "contract_id",
      accessor: "contract_id",
      title: "Contract ID",
      minWidth: 100,
      sortable: true,
      Component: ({ row }) => row?.contract_id || "-"
    },

    {
      id: "first_name",
      accessor: "first_name",
      title: "Full Name",
      Component: ({ row }) => { return `${row?.first_name || ''}   ${row?.last_name || ''}` }
    },
    {
      id: "mobile",
      accessor: "mobile",
      title: "Phone",
      Component: ({ row }) => row?.mobile || "-"


    },
    {
      id: "email",
      accessor: "email",
      title: "Email",
      minWidth: 170,
      Component: ({ row }) => row?.email || "-"
    },
    {
      id: "firm_name",
      accessor: "firm_name",
      title: "Subdivision Name",
      sortable: true,
      Component: ({ row }) => row?.firm_name || "-"
    },
    {
      id: "numberOfLicenses",
      accessor: "numberOfLicenses",
      title: `No. of licenses`,
      sortable: true,
      Component: ({ row }) => {
        return (
          !row.number_of_licenses ? "-" : `${!!row ? row.number_of_licenses ? row.number_of_licenses.used : null : null} / ${!!row ? row.number_of_licenses ? row.number_of_licenses.assigned : null : null}`
        );
      }
    },
    {
      id: "status",
      accessor: "status",
      title: "Status",
      sortable: true,
      Component: ({ row }) => {
        return (
          <>
            <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: row.status === 1 ? 'rgb(95, 170, 176)' : 'rgb(225, 108, 108)', marginRight: '5px' }} />
            {row.status === 1 ? `Active` : "Disabled"}
          </>
        )
      }
    },
    {
      id: 'id',
      accessor: 'id',
      title: 'Actions',
      Component: ({ row }) => {
        return (
          <>
            {userProfile?.user_type !== 2 ? (<>
              <span className="text-[#4892c3] cursor-pointer" onClick={() => handleEdit(row._id)} >
                Edit
              </span>
              <button className="text-[#e17070] ml-2 cursor-pointer" onClick={() => handleUserDelete(row)}>
                Delete
              </button>
            </>) : (
              <span className="text-[#4892c3] cursor-pointer" onClick={() => navigate("/app/SubDivisions/checklist")} >
                Go to checklist
              </span>
            )}
          </>
        );
      }
    }
  ];
  const [sortingOption, setSortingOption] = useState({ columnName: 'id', direction: 'desc' });
  useEffect(() => {
    dispatch(
      getAllUsersList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage)
    );
  }, [dispatch, activePage, activePerPage, sortingOption, searchValue]);


  const addSubdivisionFunction = () => {
    navigate(`/app/SubDivisions/AddSubdivision/`);
  };
  const handleEdit = (id) => {
    if (id) {
      dispatch({ type: GET_USER_DETAILS, payload: [] });
      dispatch(getCurrUserDetail(id));
    }
    navigate(`/app/SubDivisions/EditSubdivision/${id}`);
  };
  const handleUserDelete = (user) => {
    setSelectedUser(user);
    setopenConfirmDltDialog(true);
  };
  const handleCancelDelete = () => {
    setopenConfirmDltDialog(false);
  };
  const handleConfirmDelete = () => {
    setopenConfirmDltDialog(false);
    dispatch(deleteSubdivision(selectedUser._id, { setAddDialog: () => setOpenConfirmDialog(true) },
      // () => dispatch(getAllUsersList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage))
    ))
  };

  const changePerPage = (activePerPage) => {
    setActivePage(1);
    setActivePerPage(activePerPage);
    dispatch(
      getAllUsersList(sortingOption?.columnName, sortingOption?.direction, searchValue, 1, activePerPage)
    );
  };

  const onSearch = (searchValue) => {
    setSearchValue(searchValue);
  };
  const submitSearch = (e) => {
    e.preventDefault();
    setSearchValue(searchValue);
    // alert(searchValue);
    dispatch(
      getAllUsersList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage)
    );
  };
  const clearSearch = (e) => {
    e.preventDefault();
    setSearchValue("");
    dispatch(getAllUsersList(sortingOption?.columnName, sortingOption?.direction, "", activePage, activePerPage));
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setActivePerPage(activePerPage);
    dispatch(
      getAllUsersList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage)
    );
  };
  const handleCancelConfirm = () => {
    setOpenConfirmDialog(false)
    dispatch(getAllUsersList(sortingOption?.columnName, sortingOption?.direction, searchValue, activePage, activePerPage))
  }
  const handleConfirm = () => {
    setOpenConfirmDialog(false)
  }

  const onHandleChange = (e, newValue) => {
    console.log("newValue", newValue)
  }

  const [test, setTest] = useState(false)
  const onCloseModal = () => setTest(false)

  return (
    <>
      <div className="mainDiv1">
        <div className="heading-section">
          <div className="pageTitle">Subdivisions table <span className="totalCount">({allUsers && allUsers.total ? allUsers.total : '0'})</span></div>
          {userProfile && userProfile?.user_type !== 2 &&
            <Buttons
              onClick={addSubdivisionFunction}
              addButtonnProps={true}
            >
              <AddIcon />
              Add subdivisions
            </Buttons>}
          {userProfile && userProfile?.user_type == 2 &&
            <div className="grid gap-4">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                multiple={true}
                onChange={onHandleChange}
                className=""
                sx={{ width: 400, }}
                renderInput={(params) => <TextField {...params} label="Tenant" sx={{}} className="multiselect-tenate" />}
              />
            </div>
          }
        </div>

        <div className='serch-section'>
          <SearchInput
            onChange={onSearch}
            searchValue={searchValue}
            onSubmit={submitSearch}
            clearSearch={clearSearch}
          />

          <AmountItems
            changePerPage={changePerPage}
            activePerPage={activePerPage}
          />
        </div>
        <ReactTable
          COLUMNS={columns}
          DATA={allUsers?.userData}
          fetchData={getAllUsersList}
          totalCount={allUsers.total}
          pageSize={activePerPage}
          pageIndex={activePage}
          sortingOption={sortingOption}
          setSortingOption={setSortingOption}
          setSearchValue={setSearchValue}
        />
        <ConfirmDltDialog
          open={openConfirmDltDialog}
          content={`Are you sure you want to delete "${selectedUser.first_name} ${selectedUser.last_name}"?`}
          content1={`All information about this user will be permanently deleted`}
          btnLabels={{
            no: "No",
            Yes: "Yes",
          }}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
        <AddConfirmDialog
          open={openConfirmDialog}
          content={`Sub division was deleted successfully! `}
          btnLabels={{
            ok: "Ok",
          }}
          onClose={handleCancelConfirm}
          onConfirm={handleConfirm}
        />
      </div>
    </>
  );
}
