import React, { useEffect, useState } from "react";
import InHouse from '../CrisisLog/InHouse'
import Travel from "./Travel";
import { CustomTab, CustomTabs, CrisisContainer, HeaderText, HeaderSubText, EventCountText, HeaderContainer, AmountItemsContainer } from "./style";
import AmountItems from "../AmountItems/AmountItems";
import { useDispatch, useSelector } from "react-redux";
import { getTravelList, getUserList } from "redux/actions/travel";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "../CrisisLog/Alert";
import { getAlertList } from "redux/actions/CrisisLogAlert";
import { ALERT_STATUS, ALERT_TYPE } from "utils/alertHelper";
import { getInHouseList } from "redux/actions/CrisisLogInHouse";
import { getUsersList } from "redux/actions/users";
import { USER_TYPE } from "utils/commonHelper";
import { FilterButton } from "components/FilterButton";
import { Buttons } from 'modules/Button';

const TabPanel = ({ index, children, tabIndex }) => {
    return (
        <div
            role="tabpanel"
            hidden={tabIndex !== index}
            id={`tabpanel-${index}`} style={{ paddingTop: 0 }}>
            {tabIndex === index && children}
        </div>
    )
}

export default function CrisisLog() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
    const paramValue = searchParams.get('id');
    // console.log("Teststststststststststyst", paramValue);

    const [tabIndex, setTabIndex] = useState(
        localStorage.getItem("crisisTabIndex")
            ? parseInt(localStorage.getItem("crisisTabIndex")) : localStorage.getItem("inhouseTab") ?
                Number(localStorage.getItem("inhouseTab")) : 0

    );
    const [tableFilter, setTableFIlter] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [loader, setLoader] = useState(false);
    const [activePerPage, setActivePerPage] = useState(10);
    // const [activeTab, setActiveTab] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [filterList, setFilterList] = useState([]);
    const [subdivUser, setSubdivUser] = useState([]);

    const { currentuser, socektObj } = useSelector(({ USERPROFILE }) => USERPROFILE);
    const [alertTravel, setAlertTravel] = useState()
    const [inHouse, setInHouse] = useState()

    const currentUser1 = JSON.parse(localStorage.getItem("profile"));
    const subdiv_user = currentUser1.user_type === USER_TYPE.SUBDIVISION_USER;
    const control_user = currentUser1.user_type === USER_TYPE.EXTERNAL_CONTROLLER
    const tenant_user = currentUser1.user_type === USER_TYPE.TENANT_USER
    const end_user = currentUser1.user_type === USER_TYPE.END_USER
    const end_user_true = currentUser1.user_type === USER_TYPE.CONTROL_USER
    const { travelList: list, userList: allUsers } = useSelector(
        ({ TRAVEL }) => TRAVEL
    );
    const { users } = useSelector(({ CRISISLOGALERT }) => CRISISLOGALERT); // get alert List
    const { InHouseUsers } = useSelector(({ CRISISLOGINHOUSE }) => CRISISLOGINHOUSE);
    const alert_type = `${ALERT_TYPE.TRIGGERED_ALERT},${ALERT_TYPE.DELAYED_ALERT}`
    const Inhouse = ALERT_TYPE.IN_HOUSE_ALERT
    let inhouseIndex = localStorage.getItem("inhouseTab")
    const Profile = JSON.parse(localStorage.getItem("profile"));
    const checkUser = !!Profile ? Profile.user_type : null;
    useEffect(() => {
        setAlertTravel(currentuser && currentuser.detail && currentuser.detail.has_access_to ? currentuser.detail.has_access_to.alert_travel : true)
        setInHouse(currentuser && currentuser.detail && currentuser.detail.has_access_to ? currentuser.detail.has_access_to.in_house : true)
    }, [currentuser, socektObj])

    useEffect(() => {
        if (currentuser?.detail?.user_type == 2 && secSubdivisionUser) {
            setTableFIlter(secSubdivisionUser);
        } else {
            setTableFIlter("");
        }
    }, [currentuser, secSubdivisionUser]);


    useEffect(() => {
        if (tabIndex == 0 || tabIndex == 1) {
            localStorage.removeItem("inhouseTab")
        }
    }, [tabIndex])

    const tabList = [
        alertTravel && {
            title: `Alert (${users && users.total_alerts ? users.total_alerts : '0'})`,
            element: <Alert activePerPage={activePerPage} filterList={tableFilter} />,
            checklistURL: `/app/CrisisLog/Alert/checklist`,
            totalRecord: (users.total_alerts || 0)
        },
        alertTravel && {
            // title: `Travel (${list?.total_no_of_records || 0})`,
            title: `Travel (${list?.total_travel || 0})`,
            element: (
                <Travel
                    navigate={navigate}
                    loader={loader}
                    setLoader={setLoader}
                    setTabIndex={setTabIndex}

                    activePerPage={activePerPage}
                    filterList={tableFilter}
                /* handlePageChange={handlePageChange} */
                />
            ),
            checklistURL: `/app/CrisisLog/travel/checklist`,
            totalRecord: (list?.total_travel || 0)
        },
        inHouse && {
            title: `In House (${InHouseUsers?.total_inhouse || 0})`,
            element: <InHouse activePerPage={activePerPage} filterList={tableFilter} />,
            checklistURL: `/app/CrisisLog/inHouse/checklist`,
            totalRecord: (InHouseUsers?.total_inhouse || 0)
        },
    ].filter(Boolean);
    useEffect(() => {
        if (allUsers && allUsers.userData && allUsers.userData.length > 0) {
            const opt = allUsers.userData.map((option) => ({
                label: `${option.first_name} ${option.last_name}`,
                id: option._id,
            }));
            setSubdivUser(opt);
        }
    }, [allUsers]);

    const onFilter = (label) => () => {
        const list = [...filterList];
        if (label === "all") {

            setFilterList([]);
        } else {
            const idx = list.indexOf(label);
            if (idx > -1) {
                list.splice(idx, 1);
            } else {

                list.push(label);
            }
            setFilterList(list);
        }
        const subdivisions = label === 'all' ? '' : list.length === 1 ? list[0] : list.join(',');
        setTableFIlter(subdivisions);
        // callApi({ tabIndex, activePage, activePerPage, filterList: subdivisions })
    };

    const onTabChange = (e, newValue) => {
        setTabIndex(newValue);
        setFilterList([]);
        setActivePerPage(10);
    };

    const changePerPage = (activePerPage) => {
        setActivePage(1);
        setActivePerPage(activePerPage);
    };

    const renderCount = () => {
        if (tabIndex === 1) {
            return list && list.total_no_of_records ? list.total_no_of_records : '0'
        } else if (tabIndex === 0) {
            return users && users.total_alerts ? users.total_alerts : '0'
        } else if (tabIndex === 2) {
            return InHouseUsers && InHouseUsers.total_inhouse ? InHouseUsers.total_inhouse : '0'
        }
    }
    useEffect(() => {
        localStorage.removeItem("crisisTabIndex");
        tenant_user &&
            dispatch(
                getUserList({
                    user_type: USER_TYPE.SUBDIVISION_USER,
                    is_pagination: false,
                })
            );
    }, []);

    return (
        <CrisisContainer>
            <HeaderContainer style={{ marginBottom: subdiv_user || control_user || checkUser === USER_TYPE.EXTERNAL_CONTROLLER || checkUser === USER_TYPE.END_USER ? "50px" : "8px" }}>
                <div className={`flex justify-between`}>
                    <div>
                        <HeaderText> Crisis Log </HeaderText>
                        <HeaderSubText> Latest alert always on top </HeaderSubText>
                        <EventCountText>
                            {" "}All events - {tabList[tabIndex]?.totalRecord}{" "}
                        </EventCountText>

                    </div>
                    {([USER_TYPE.SUBDIVISION_USER, USER_TYPE.SEC_CONTROLLER].includes(Profile.user_type) && tabList?.length > 0) &&
                        <div className="flex justify-end mt-6">
                            <Link to={tabList[tabIndex]?.checklistURL}>
                                <Buttons type='submit' > {'Go to checklist'}</Buttons>
                            </Link>
                        </div>}
                </div>
            </HeaderContainer>

            {tenant_user && (
                <div style={{ marginBottom: "10px" }}>
                    <FilterButton
                        variant={filterList.length ? "outlined" : "contained"}
                        key={`subdiv-all`}
                        onClick={onFilter("all")}
                        style={{
                            backgroundColor: filterList.length ? '' : 'rgb(72, 146, 195)',
                            color: filterList.length ? 'rgb(72, 146, 195)' : 'white'
                        }}
                        value='All'
                    >
                        {"All"}
                    </FilterButton>
                    {subdivUser &&
                        subdivUser.map((user, index) => (
                            <FilterButton
                                variant={
                                    filterList.indexOf(user.id) > -1 ? "contained" : "outlined"
                                }
                                key={`subdiv-${index}`}
                                onClick={onFilter(user.id)}
                                style={{
                                    backgroundColor: filterList.indexOf(user.id) > -1 ?
                                        'rgb(72, 146, 195)' : '', color: filterList.indexOf(user.id) > -1 ? 'white' : 'rgb(72, 146, 195)'
                                }}
                            >
                                {user.label}
                            </FilterButton>
                        ))}
                </div>
            )}

            <CustomTabs value={tabIndex} onChange={onTabChange} aria-label="">
                {tabList.map((tab, index) => (
                    <CustomTab
                        key={`tab-${index}`}
                        label={tab.title}
                        id={`tab-${index}`}
                    />
                ))}
                <AmountItemsContainer style={{ width: tabList.length === 1 ? "86%" : "75%" }}>
                    <AmountItems
                        changePerPage={changePerPage}
                        activePerPage={activePerPage}
                    />
                </AmountItemsContainer>
            </CustomTabs>
            {
                tabList.map((tab, index) => (
                    tabIndex === index && <TabPanel key={`tabpanel-${index}`} index={index} tabIndex={tabIndex} id="tabpannenl" style={{ marginTop: 0 }}>
                        {tab.element}
                    </TabPanel>
                ))
            }
        </CrisisContainer >
    );
}
