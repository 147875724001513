import { GET_DEFAULT_POLICENUMBER } from "../../@jumbo/constant/ActionTypes";
  
  const INIT_STATE = {
    policenumber: null,
    
   
  };
  
  export default (state = INIT_STATE, action) => {
    
    switch (action.type) {
      case GET_DEFAULT_POLICENUMBER: {
        return {
          ...state,
          policenumber: action.payload,
        };
      }      
      default:
        return state;
    }
  };
  