import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { TableCell } from "./style";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const useStyles = styled((theme) => ({
  titleRoot: {
    padding: "19px 10px 19px 19px",
    display: "table-cell",
    color: "rgb(92, 99, 115)",
  },
}));

export default function UserList({ row, handleUserDelete, handleEdit }) {
  let classes = useStyles();
  const labelId = `enhanced-table-checkbox-${row._id}`;

  return (

   
    <TableRow
      key={row._id}
    >
      <TableCell
        
        id={labelId}
        key={row.id}
        align="left"
        className={classes.titleRoot}
        
      >
        {!row.id ? "-" : row.id}
      </TableCell>

      <TableCell
        
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
        
      >
        {`${row.first_name}   ${row.last_name}`}
        {/* </Typography> */}
      </TableCell>
     

      <TableCell
        
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
        
      >
        {!row.mobile ? "-" : row.mobile}
      </TableCell>

      <TableCell
        
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
        
      >
        {!row.email ? "-" : row.email}
        {/* </Typography> */}
      </TableCell>

      

      <TableCell
        
        id={labelId}
        scope="row"
        padding="none"
        key={row.id}
        align="left"
        
      >
        {row.status === 1 ?<FiberManualRecordIcon  style={{fontSize: "0.5rem", color:'rgb(95, 170, 176)',marginRight:'5px'}}/> : ''}
        {row.status === 2 ?<FiberManualRecordIcon  style={{fontSize: "0.5rem", color:'rgb(225, 108, 108)',marginRight:'5px'}}/> : ''}
        {row.status === 1 ? `Active` : "Disabled"}
        {/* </Typography> */}
      </TableCell>

      <TableCell align="center">
        <button
          style={{
            marginRight: "40px",
            backgroundColor: "transparent",
            cursor: "pointer",
            border: "0px",
            outline: "0px",
            boxShadow: "none",
            color: " rgb(95, 170, 176)",
            fontSize: "12px",
            padding: "0px",
          }}
          onClick={() => handleEdit(row._id)}
        >
          Edit
        </button>
        <button
          style={{
            // marginRight: "40px",
            backgroundColor: "transparent",
            cursor: "pointer",
            border: "0px",
            outline: "0px",
            boxShadow: "none",
            color: " rgb(225, 108, 108)",
            fontSize: "12px",
            padding: "0px",
          }}
          onClick={() => handleUserDelete(row)}
        >
          Delete
        </button>
      </TableCell>
    </TableRow>
  );
}
