import React, { useState, useEffect, useRef } from "react";
import { Buttons } from "../Button";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Add as AddIcon } from '@mui/icons-material'
import { Grid, Box, Autocomplete, TextField } from '@mui/material'
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { USER_TYPE } from "utils/commonHelper";
import CardMedia from '@mui/material/CardMedia';
import TenantIcon from './tenant-icon.png'
import UsersIcon from './users-icon.png'
import AlertIcon from './alert-icon.png'
import TravelIcon from './travel-icon.png'
import { getSubdivisionList } from '../../redux/actions/SubDivision';
import { getAllTenantsListForDashboard } from '../../redux/actions/tenant';
import { getStatiSticsCount } from '../../redux/actions/users';
// import GridContainer from '.MuiGrid-container';
import { } from "./index.style";
import './style.css'

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 }]

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allUsers } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
  const { users } = useSelector(({ TENANT }) => TENANT);
  const { counts } = useSelector(({ USERS }) => USERS);

  const search = useLocation().search;
  const Profile = JSON.parse(localStorage.getItem("profile"));
  const subdiv_user = Profile.user_type === USER_TYPE.SUBDIVISION_USER
  const control_user = Profile.user_type === USER_TYPE.EXTERNAL_CONTROLLER
  const tabId = new URLSearchParams(search).get('tab');
  const [tabIndex, setTabIndex] = React.useState(0);
  const [butttonValue, setButttonValue] = useState('Add contact')
  const [open, setOpen] = useState(false);

  const [tenantList, setTenantList] = useState([]);
  const [subDivList, setSubDivList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("");
  const [selectedSubDiv, setSelectedSubDiv] = useState("");
  const [subDivValue, setSubDivValue] = useState([]);

  const handleResetValue = () => {
    setSubDivValue([]);
    setSelectedSubDiv("")
  };

  const onHandleChangeTenant = (e, newValue) => {
    if (selectedSubDiv) {
      handleResetValue()
    }
    let selectedT = newValue.map((t) => {
      return t.value
    })
    let comaStringT = selectedT.toString();
    setSelectedTenant(comaStringT);
    dispatch(getSubdivisionList(comaStringT));
  }

  const onHandleChangeSubDiv = (e, newValue) => {
    setSubDivValue([...newValue]);
    let selectedSub = newValue.map((t) => {
      return t.value
    })
    let comaStringSub = selectedSub.toString();
    setSelectedSubDiv(comaStringSub);
  }

  useEffect(() => {
    if (!!selectedTenant || !!selectedSubDiv) {
      dispatch(getStatiSticsCount(selectedTenant, selectedSubDiv));
    } else {
      dispatch(getStatiSticsCount());
    }
  }, [selectedTenant, selectedSubDiv])

  useEffect(() => {
    ; (async () => {
      dispatch(getSubdivisionList());
      dispatch(getAllTenantsListForDashboard());
    })();
  }, [])

  useEffect(() => {
    if (allUsers && allUsers?.length > 0) {
      const subDiv = allUsers.map((sub) => {
        return { label: `${sub?.first_name}${" "}${sub?.last_name}`, value: sub?._id }
      })
      setSubDivList(subDiv);
    } else {
      setSubDivList([]);
    }

    if (users && users?.userData?.length > 0) {
      const tenant = users?.userData.map((ten) => {
        return { label: `${ten?.first_name}${" "}${ten?.last_name}`, value: ten?._id }
      })
      setTenantList(tenant);
    } else {
      setTenantList([]);
    }
  }, [allUsers, users]);

  return (
    <>
      <div className="heading-section">
        <div className="pageTitle">Dashboard</div>
      </div>

      <div class="grid grid-cols-2 gap-2 mb-5 w-[600px]" >
        <Autocomplete
          disablePortal
          id="fixed-tags-demo"
          options={tenantList}
          multiple={true}
          onChange={onHandleChangeTenant}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} className="multiselect-tenate" label="Tenant" />}
        />

        <Autocomplete
          disablePortal
          value={subDivValue}
          id="combo-box-demo"
          options={subDivList}
          multiple={true}
          onChange={onHandleChangeSubDiv}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} className="multiselect-tenate" label="Subdivision" />}
        />
      </div>

      <div class="grid grid-cols-4 gap-4">
        <Card sx={{ width: "100%", maxHeight: 150, backgroundColor: 'rgb(240, 240, 240)', display: 'flex', alignItems: "center", border: 1.6, borderColor: '#5eade0', borderRadius: 2.5, padding: "10px" }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography variant="h3" component="div">{counts?.total_users ? counts?.total_users : 0}</Typography>
            </CardContent>
            <CardActions sx={{ padding: 0 }}>
              <Button color="inherit" style={{ fontSize: '17px', padding: 0 }} size="large">Total Users</Button>
            </CardActions>
          </Box>
          <CardMedia
            component="img"
            sx={{ width: 50, maxHeight: 50, marginLeft: "auto" }}
            image={UsersIcon}
            alt="tenant-icon"
          />
        </Card>

        <Card sx={{ width: "100%", maxHeight: 150, backgroundColor: 'rgb(240, 240, 240)', display: 'flex', alignItems: "center", border: 1.6, borderColor: '#5eade0', borderRadius: 2.5, padding: "10px" }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography variant="h3" component="div">{counts?.total_alerts ? counts?.total_alerts : 0}</Typography>
            </CardContent>
            <CardActions sx={{ padding: 0 }}>
              <Button color="inherit" style={{ fontSize: '17px', padding: 0 }} size="large">Total Alerts</Button>
            </CardActions>
          </Box>
          <CardMedia
            component="img"
            sx={{ width: 50, maxHeight: 50, marginLeft: "auto" }}
            image={AlertIcon}
            alt="tenant-icon"
          />
        </Card>

        <Card sx={{ width: "100%", maxHeight: 150, backgroundColor: 'rgb(240, 240, 240)', display: 'flex', alignItems: "center", border: 1.6, borderColor: '#5eade0', borderRadius: 2.5, padding: "10px" }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography variant="h3" component="div">{counts?.total_travels ? counts?.total_travels : 0}</Typography>
            </CardContent>
            <CardActions sx={{ padding: 0 }}>
              <Button color="inherit" style={{ fontSize: '17px', padding: 0 }} size="large">Total Travel</Button>
            </CardActions>
          </Box>
          <CardMedia
            component="img"
            sx={{ width: 50, maxHeight: 50, marginLeft: "auto" }}
            image={TravelIcon}
            alt="tenant-icon"
          />
        </Card>

        <Card sx={{ width: "100%", maxHeight: 150, backgroundColor: 'rgb(240, 240, 240)', display: 'flex', alignItems: "center", border: 1.6, borderColor: '#5eade0', borderRadius: 2.5, padding: "10px" }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography variant="h3" component="div">{counts?.total_inhouse ? counts?.total_inhouse : 0}</Typography>
            </CardContent>
            <CardActions sx={{ padding: 0 }}>
              <Button color="inherit" style={{ fontSize: '17px', padding: 0 }} size="large">Total InHouse</Button>
            </CardActions>
          </Box>
          <CardMedia
            component="img"
            sx={{ width: 50, maxHeight: 50, marginLeft: "auto" }}
            image={TenantIcon}
            alt="tenant-icon"
          />
        </Card>
      </div >
    </>
  )
}