import React from 'react'

export default function PageNotFound() {

  return (
    <>
    <div style={{textAlign: '-webkit-center', 
    marginTop: '15%', fontSize: '120px', fontFamily: 'serif', 
    color: 'rgb(92, 99, 115)', textAlign: 'center'}}>Oops! 404</div>
    <div style={{textAlign: '-webkit-center', marginTop: '2%', fontSize: '38px', fontFamily: 'serif', color: 'rgb(92, 99, 115)', textAlign: 'center'}}>Page not found</div>
    </>
  )
}

