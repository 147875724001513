import React, { useEffect, useState } from 'react'
import AddLogMessage from "../logActivity/logMessage"
import FocusBoard from "../logActivity/focusBoard";
import CheckList from "../logActivity/checkList";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ALERT_TYPE, CHECKLIST_TYPE } from 'utils/alertHelper';
import { getCheckList } from 'redux/actions/checklist';

const Index = ({ addLogAPI, alertType, isCompleted, setActiveTab, activeTab }) => {
    const { secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
    const { currentuser } = useSelector(({ USERPROFILE }) => USERPROFILE);
    const dispatch = useDispatch();
    const { id } = useParams();
    const checklistData = useSelector(store => store.CHECKLIST);
    //called checklist API
    useEffect(() => {
        dispatch(getCheckList({ type: alertType.value, id, subdivision_id: secSubdivisionUser }));
    }, []);
    return (
        <>
            <div id="tab_list" className="flex text-[#838894] font-bold text-lg">
                <span className={`cursor-pointer ${activeTab === 0 && 'underline text-slate-800'}`} onClick={() => setActiveTab(0)}>Log</span>
                <span className={`ml-5 cursor-pointer ${activeTab === 1 && 'underline text-slate-800'}`} onClick={() => setActiveTab(1)}>Focus Board</span>
                <span className={`ml-5 cursor-pointer ${activeTab === 2 && 'underline text-slate-800'}`} onClick={() => setActiveTab(2)}>Check List ({checklistData?.inCompleteCount || 0})</span>
            </div>
            {/* <DetailHeader>Add log message</DetailHeader> */}
            <div id="tab_views" style={{ marginTop: "30px", width: "78%" }}>
                {activeTab === 0 && <AddLogMessage addLogAPI={addLogAPI} />}
                {activeTab === 1 && <FocusBoard alertType={alertType} isCompleted={isCompleted} />}
                {activeTab === 2 && <CheckList alertType={alertType} isCompleted={isCompleted} />}
            </div>
        </>
    )
}

export default Index