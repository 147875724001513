import * as React from "react";

import { styled } from "@mui/material/styles";
// import Button from '@material-ui/Button';
import { Button } from "@mui/material";

export const ButtonCancel = styled(Button)({
    border: '1px solid rgb(72, 146, 195)',
    borderRadius:' 8px',
    color:'rgb(72, 146, 195)',
    maxWidth: '192px',
    height: '49px',
    marginTop: '0',
    width: '100%',
    // fontWeight: 500,
    fontSize: "16px",
    textTransform: "capitalize",
    cursor: "pointer",
    fontFamily: "SF Pro Text",
  "&:hover": {
    backgroundColor: "white",
    
  },
});


