// import React from 'react'
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UserTableToolbar from "./UserTableToolbar";
import { getUsersList, getCurrUserDetail, deleteUsers } from "redux/actions/users";
import ConfirmDltDialog from "../ConfirmDltDialog";

import { SET_USER } from "@jumbo/constant/ActionTypes";
import AddConfirmDialog from "../AddConfirmDialog";
import ReactTable from "components/common/table/ReactTable2";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const perPages = [10, 20, 50, 100]
export default function Users() {
  // const classes = useStyles();
  const { users } = useSelector(({ USERS }) => USERS);



  const [page, setPage] = React.useState(0);
  const [selectedUser, setSelectedUser] = useState({ first_name: "" });

  const [order, setOrder] = React.useState("desc");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [orderBy, setOrderBy] = React.useState("first_name");

  const [searchTerm, setSearchTerm] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [activePerPage, setActivePerPage] = useState(10);
  const [openConfirmDltDialog, setopenConfirmDltDialog] = useState(false)
  const [sortingOption, setSortingOption] = useState({ columnName: 'id', direction: 'desc' });

  const dispatch = useDispatch();
  let navigate = useNavigate();
  let user_type = 7;
  const pathname = window.location.pathname

  useEffect(() => {
    dispatch(
      getUsersList(
        user_type,
        sortingOption?.columnName,
        sortingOption?.direction,
        searchValue,
        activePage,
        activePerPage
      )
    );
  }, [orderBy, sortingOption, activePerPage, dispatch]);

  const handleEdit = (id) => {
    dispatch({ type: SET_USER, payload: [] });
    dispatch(getCurrUserDetail(id));
    // setAddDialog(true)
    navigate(`/app/users/EditUSer/${id}`);
  };

  const addTenant = () => {
    // setAddDialog(true)

    navigate(`/app/users/Adduser/`);
    dispatch({ type: SET_USER, payload: null });
  };

  const handleUserDelete = (user) => {
    setSelectedUser(user);
    setopenConfirmDltDialog(true);
  };
  const handleCancelDelete = () => {
    setopenConfirmDltDialog(false);
  };
  const handleConfirmDelete = () => {
    setopenConfirmDltDialog(false);
    dispatch(deleteUsers(selectedUser._id, { setAddDialog: () => setOpenConfirmDialog(true) }, () => dispatch(
      getUsersList(
        user_type,
        sortingOption?.columnName,
        sortingOption?.direction,
        searchValue,
        activePage,
        activePerPage
      )
    )))
  };

  const onSearch = (searchValue) => {
    setSearchValue(searchValue)
  }
  const submitSearch = (e) => {
    e.preventDefault();
    setSearchValue(searchValue);

    dispatch(
      getUsersList(
        user_type,
        sortingOption?.columnName,
        sortingOption?.direction,
        searchValue,
        activePage,
        activePerPage
      )
    );
  }
  const clearSearch = (e) => {
    e.preventDefault();
    setSearchValue('');
    dispatch(
      getUsersList(
        user_type,
        sortingOption?.columnName,
        sortingOption?.direction,
        '',
        activePage,
        activePerPage
      )
    );
  }
  const changePerPage = (activePerPage) => {
    setActivePage(1);
    setActivePerPage(activePerPage);

    dispatch(
      getUsersList(
        user_type,
        sortingOption?.columnName, sortingOption?.direction,
        searchValue,
        1,//active page
        activePerPage
      )
    )

  }
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    setActivePerPage(activePerPage)
  }

  const handleCancelConfirm = () => {
    setOpenConfirmDialog(false)
  }
  const handleConfirm = () => {
    setOpenConfirmDialog(false)
  }
  const columns = [
    {
      id: "id",
      accessor: "id",
      title: "ID",
      sortable: true,
    },

    {
      id: "first_name",
      accessor: "first_name",
      title: "Full Name",
      Component: ({ row }) => { return `${row?.first_name || ''}   ${row?.last_name || ''}` }
    },
    {
      id: "email",
      accessor: "email",
      title: "Email",

    },
    {
      id: "mobile",
      accessor: "mobile",
      title: "Phone",
    },
    {
      id: "control_user",
      accessor: "control_user",
      title: "Control User",
      sortable: true,
      Component: ({ row }) => {
        return (
          <>
            <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: (row?.control_user ? 'rgb(95, 170, 176)' : 'rgb(225, 108, 108)'), marginRight: '5px' }} />
            {(row?.control_user ? "Yes" : "No")}
          </>

        )
      }
    },
    {
      id: "status",
      accessor: "status",
      title: "Status",
      sortable: true,
      Component: ({ row }) => {
        return (
          <>
            {row.status === 1 &&
              <>
                <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'rgb(95, 170, 176)', marginRight: '5px' }} />
                Active
              </>
            }
            {row.status === 2 &&
              <>
                <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'rgb(225, 108, 108)', marginRight: '5px' }} />
                Inactive
              </>
            }
            {/* {row.status === 3 &&
              <>
                <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'Orange', marginRight: '5px' }} />
                Pending
              </>
            }
            {row.status === 4 &&
              <>
                <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: 'rgb(119 42 42)', marginRight: '5px' }} />
                Rejected
              </>
            } */}
          </>
        );
      }
    },
    {
      id: 'id',
      accessor: 'id',
      title: 'Actions',
      Component: ({ row }) => {
        return (
          <>
            <span className="text-[#4892c3] cursor-pointer" onClick={() => handleEdit(row._id)} >
              Edit
            </span>
            <button className="text-[#e17070] ml-2 cursor-pointer" onClick={() => handleUserDelete(row)}>
              Delete
            </button>
          </>
        );
      }
    }
  ];
  return (
    <>

      <div className="mainDiv1">
        <UserTableToolbar onSearch={onSearch}
          searchValue={searchValue}
          submitSearch={submitSearch}
          clearSearch={clearSearch} changePerPage={changePerPage} activePerPage={activePerPage} addTenant={addTenant} />

        <ReactTable
          COLUMNS={columns}
          DATA={users?.userData || []}
          fetchData={handlePageChange}
          totalCount={users?.total}
          pageSize={activePerPage}
          pageIndex={activePage}
          sortingOption={sortingOption}
          setSortingOption={setSortingOption}
          setSearchValue={setSearchValue}
        />
        {/* <TableContainer
        >
          <Table aria-labelledby="tableTitle" aria-label="sticky enhanced table" className="custome-tbl">
            <UserTableHead orderBy={orderBy} order={order} handleRequestSort={handleRequestSort} />

            <TableBody>
              {!!users.userData
                ? users.userData
                  // .slice(0 * activePerPage, page * activePerPage + activePerPage)
                  // slice(0 * activePerPage, page * activePerPage + activePerPage)
                  .map((row, index) => (
                    <UserListRow
                      key={index}
                      row={row}
                      handleUserDelete={handleUserDelete}
                      handleEdit={handleEdit}
                    />))
                :
                (
                  <TableRow style={{ width: "40%" }}>
                    <TableCell colSpan={10} rowSpan={10} style={{ textAlign: 'center' }}>
                      No Record found
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>

        </TableContainer>
        <Pagination
          activePage={activePage}
          totalItems={!!users ? users.total : 1}
          itemsCountPerPage={activePerPage}
          handlePageChange={handlePageChange}
        /> */}

        <ConfirmDltDialog
          open={openConfirmDltDialog}
          content={`Are you sure you want to delete  ${!!selectedUser ? selectedUser.first_name : ''} ${!!selectedUser ? selectedUser.last_name : ''}?`}
          content1={'All information about this user will be permanently deleted.'}
          btnLabels={{
            no: "No",
            Yes: "Yes",
          }}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
        <AddConfirmDialog
          open={openConfirmDialog}
          // title={`Delete Subdivision`}
          content={`User was deleted successfully! `}
          btnLabels={{
            ok: "Ok",
          }}
          onClose={handleCancelConfirm}
          onConfirm={handleConfirm}
        />
      </div>
      {/* </Container0> */}
      {/* </Grid> */}

    </>
  );
}
