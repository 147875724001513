import React, { useMemo } from 'react'
import GooglMap from 'components/GooglMap'
import { useSelector, useDispatch } from "react-redux";

export default function TravelMap(list) {

    const { userList: allUsers, getStatus, travelList } = useSelector(({ TRAVEL }) => TRAVEL)

    const markerList = travelList && travelList.UserTravelList && travelList.UserTravelList.map((value) => {
        if (value) {
            console.log("value map",value)
            return {
                lat_long: value.lastCheckinLatLong ? value.lastCheckinLatLong : [],
                name: `${value.lastCheckinCity || ''} ${value.lastCheckinCountry || ''}`
            }
        }
    })

    // let test = [{lat_long: [23.0412451, 72.513965], name: "test city"}, {lat_long: [29.0412451, 79.513965], name: "testting avail"}]

    // const markerList = useMemo(() => {
    //     if (list && Object.keys(list).length > 0 && getStatus === 200 && list.UserTravelList ) {
    //         return list && list.UserTravelList && list.UserTravelList.map((x) => ({
    //             lat_long: x.lastCheckinLatLong ? x.lastCheckinLatLong : [],
    //             name: `${x.userFirstname || ''} ${x.userLastname || ''}`
    //         }))
    //     } else if(getStatus === 400) return []
    // }, [list])

    return (
        <div style={{ height: '600px' }}>

            <GooglMap markerList={markerList} zoom={2} height={'550px'} showCoord={true} />
        </div>
    )
}