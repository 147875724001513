// import React from 'react'
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Container, Input, listItemIconClasses } from "@mui/material";
import { Form, NavLink, useNavigate } from "react-router-dom";
import { maxHeight } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
// import ConfirmDialog from "./ConfirmDialog";
// import TableHeader from "./TableHeader";
import TableSortLabel from "@mui/material/TableSortLabel";
import Search from "@mui/icons-material/Search";
// import { useNavigate } from "react-router-dom";
// import AppTextInput from 'applayout/formElements/AppTextInput';
// import AppTextInput from './applayout/formElements/AppTextInput'
import CloseIcon from "@mui/icons-material/Close";
import UserTableToolbar from "./UserTableToolbar";
import useStyles from "./index.style";
import UserTableHead from "./UserTableHead";
import { USER_TYPE, useDebounce } from "utils/commonHelper";
import UserListRow from "./UserListRow";
import Pagination from "../../Pagination/Pagintions";
import {
  getAlertList,
  setTenantList,
  getCurrUserDetail,
} from "redux/actions/CrisisLogAlert";
import ReactTable from "components/common/table/ReactTable2";
import { ALERT_TYPE, REVERSED_ALERT_STATUS, REVERSED_ALERT_TYPE, timeHelper } from "utils/alertHelper";
import * as moment from 'moment';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function Alert({
  // activePage,
  // setActivePage,
  activePerPage,
  // setActivePerPage,
  // alert_type,
  filterList,
  // handlePageChange,
}) {
  const columns1 = [
    {
      id: "id",
      accessor: 'id',
      title: "Alert ID",
    },

    {
      id: "first_name",
      title: "Name",
      accessor: 'userName',
      Component: ({ row }) => {
        return <>
          {!!row?.test_mode ? <div className="default-number" style={{ marginTop: '4px' }}>Test Mode</div> : null}
          {!row?.userName ? '-' : row.userName}
        </>
      }
    },
    {
      id: "email",
      title: "Response Time",
      accessor: 'response_time',
      Component: ({ row }) => {
        return timeHelper(row.response_time)
      }
    },
    {
      id: "lastLogEntry",
      title: "Last Log entry",
      accessor: 'lastLogEntry',
      Component: ({ row }) => {
        return (
          <>
            {!row.lastLogEntry ? "-" : row.lastLogEntry?.time && moment(row.lastLogEntry.time).format('DD-MM-YYYY hh:mm')} {" "}
            {row.lastLogEntry.user}
          </>
        )
      }
    },
    {
      id: "control_user",
      title: "Message",
      accessor: 'lastLogMessage',
      Component: ({ row }) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: row?.lastLogMessage || '' }}>

          </div>
        )
      }
    },
    {
      id: "createdOn",
      title: "Alert created",
      accessor: 'createdOn',
      Component: ({ row }) => !row.createdOn ? "-" : moment(row.createdOn).format('DD-MM-YYYY hh:mm')
    },
    {
      id: "alertStatus",
      title: "Alert status",
      accessor: 'alertStatus',
      Component: ({ row }) => {
        return (
          <>
            {row.alertStatus ? <FiberManualRecordIcon style={{ fontSize: "0.5rem", color: row.alertStatus === 1 ? 'rgb(225, 108, 108)' : row.alertStatus === 2 ? 'rgb(95, 170, 176)' : 'rgb(92, 99, 115)', marginRight: '5px' }} /> : ''}
            {!row.alertStatus ? "-" : REVERSED_ALERT_STATUS[row.alertStatus]}
          </>
        );
      }
    },

    {
      id: "status",
      title: "Alert type",
      accessor: 'id',
      Component: ({ row }) => {
        return (
          // text-[#73d1da]
          <span className={row?.alertType === 1 ? `text-[#ec6969]` : `text-[#5FAAB0]`}>
            {!row.alertType ? "-" : REVERSED_ALERT_TYPE[row.alertType]}
          </span>
        )
      }
    },
    {
      id: "country",
      title: "Country",
      accessor: 'country',
    },
    {
      id: "city",
      title: "City",
      accessor: 'city',
    },
    {
      id: "action",
      title: "Actions",
      accessor: 'id',
      Component: ({ row }) => {
        return (
          <NavLink to={`/app/CrisisLog/crisis-log-alert-detail/${row.alertId}`}>
            <i className="icon-eye" aria-label="toggle password visibility" />
          </NavLink>
        )
      },
    },
  ];
  const { users } = useSelector(({ CRISISLOGALERT }) => CRISISLOGALERT);
  const [callCount, setCallCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();

  const getAlertListData = (activePage = 1, activePerPage = 10) => {
    dispatch(getAlertList(
      `${ALERT_TYPE.TRIGGERED_ALERT},${ALERT_TYPE.DELAYED_ALERT}`,
      filterList,
      activePerPage,
      activePage
    ));
  }
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  }
  useEffect(() => {
    getAlertListData(activePage, activePerPage);
  }, [activePage]);
  useEffect(() => {
    if (callCount > 0) {
      setActivePage(prev => typeof prev === 'number' ? '1' : 1);
    } else {
      setCallCount(prev => prev + 1);
    }
  }, [activePerPage, filterList]);
  const Profile = JSON.parse(localStorage.getItem("profile"));
  return (
    <>
      <div className="mainDiv1">
        <ReactTable
          COLUMNS={columns1}
          DATA={users.alertLogs}
          fetchData={handlePageChange}
          totalCount={users?.total_no_of_records}
          pageSize={activePerPage}
          pageIndex={activePage}
          hiddenColumns={[USER_TYPE.TENANT_USER].includes(Profile.user_type) ? ['action'] : []}
        />
      </div>
    </>
  );
}
