import styled from "styled-components";
import COLORS from "utils/colors";

export const AlertContainer = styled.div`
`

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 37px;
`

export const HeaderText = styled.h1`
    font-family: SFTextSemibold;
    text-align: left;
    font-size: 22px;
    width: 100%;
    color: rgb(92, 99, 115);
`

export const TableTitle = {
    textAlign: 'left',
    fontFamily: 'SFTextMedium',
    fontSize: '15px',
    color: 'rgb(92, 99, 115)',
}

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: -4px !important;
`

export const DetailHeader = styled.div`
    font-family: SFTextSemibold;
    text-align: left;
    font-size: 22px;
    width: 100%;
    color: rgb(92, 99, 115);
`

export const AlertDetailContainer = styled.div`
    width: 97%;
    padding: 25px 18px;
    border: 0.5px solid rgb(240, 183, 184);
    border-radius: 8px;
    background-color: rgb(254, 248, 248);
`
export const AlertDetailContainer1 = styled.div`
    width: 97%;
    padding: 25px 18px;
    border: 0.5px solid rgb(168, 201, 224);
    border-radius: 8px;
    background-color: rgb(247, 249, 252);
`
export const AlertDetailContainer2 = styled.div`
    width: 750px;
    padding: 25px 18px;
    border: 0.5px solid rgb(168, 201, 224);
    border-radius: 8px;
    background-color: rgb(247, 249, 252);
    padding-top: 20px
`

export const UserDetailTitle = styled.div`
    font-size: 18px;
    margin-bottom: 0px;
    font-family: SFTextMedium;
    color: rgb(72, 146, 195);
`

export const UserBasicInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
`

export const BasicInfoTitle = styled.div`
    font-size: 12px;
    color: rgb(133, 142, 160);
    margin: 0px 0px 10px;
}
`

export const BasicInfoValue = styled.div`
    font-size: 15px;
    font-family: "SF Pro Text";
    line-height: 1.3;
    margin: 0px 0px 25px;
    font-weight: unset !important
`

export const BasicCardValue = styled.div`
    font-size: 32px;
    font-family: "SF Pro Text";
    line-height: 1.3;
    margin: 0px 0px 0px;
`
export const BasicInfoTitleForInhouseComment = styled.div`
    font-size: 14px;
    color: rgb(133, 142, 160);
    margin: 0px 0px 10px;
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin-top: 20px;
}
`

export const AlertInfoValue = styled.div`
    font-size: 15px;
    font-weight: unset !important;
`

export const AlertInfoContainer = styled.div`
    border-bottom: 1px solid #F1F1F1;
    padding-bottom: 5px;
`

export const AddButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 15px 0px;
`

export const AddButton = {
    width: '100px',
    backgroundColor: '1px solid rgb(72, 146, 195)',
    padding: '5px 15px 5px',
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: "SF Pro Text",
}

export const ButtonStyle = {
    width: '22%',
    borderRadius: '8px',
}

export const MapHeader = styled.div`
    font-family: SFTextSemibold;
    text-align: left;
    margin: 20px 0px;
    font-size: 22px;
    color: rgb(92, 99, 115);
`

export const CheckInComment = styled.div`
    padding: 25px 18px;
    background-color: rgb(247, 249, 252);
    border: 1px solid rgb(197, 219, 235);
    border-radius: 5px;
    width: 96%
`

export const DetailContainer = styled.div`
    width: 100%;
`

export const UserInputContainer = styled.div`
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50%;
`

export const AddTravelButtonContainer = {
    marginTop: '1%',
}

export const AddTravelButtonContainer1 = {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

export const updatePositionBtn = {
    color: 'rgb(72, 146, 195)',
    marginRight: '-7%',
    backgroundColor: 'transparent',
    // width: '38%', 
    width: '190px',
    borderRadius: '8px'

}

export const CustomError = styled.label`
    font-size: 12px;
    color: #D32F2F;
`

export const CustomPickerSpan = styled.span`
    font-family: SFTextLight;
    position: absolute;
    right: 35px;
    top: 50%;
    color: rgb(133, 142, 160);
    transform: translateY(-50%);
`

export const CustomerPickerIconContainer = styled.span`
    width: 16px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    color: rgb(133, 142, 160);
`

export const CheckedIconContainer = styled.div`
    height: 12px;
    width: 12px;
    background-color: rgb(255, 255, 255);
    border: 3px solid rgb(162, 169, 183);
    border-radius: 3px;
`

export const CheckedIconSelected = {
    height: '15px',
    width: '15px',
    top: '10px',
    position: 'absolute',
    right: '10px'
}

export const CheckboxLabel = styled.label`
    font-family: SFTextLight;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: 15px;
    line-height: 1.1;
    color: rgb(113, 117, 125)
`

export const GoogleInputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
`

export const GoogleInputBox = styled.input`
    font-family: SFTextLight;
    font-size: 15px;
    width: 90%;
    height: 36px;
    border: 1px solid rgb(229, 230, 233);
    border-radius: 5px;
    outline: none;
    box-shadow: none;
    padding: 0px 10px;
`

export const DeleteIcon = styled.span`
    width: 16px;
    position: absolute;
    top: 50%;
    left: 44.5%;
    transform: translateY(-50%);
    cursor: pointer;
`

export const AmountItemsContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 60%;
    margin-bottom: 15px;
`

export const EventCountText = styled.div`
    font-family: SFTextMedium;
    font-size: 15px;
    color: rgb(92, 99, 115);
    margin-top: 10px;
`

export const HeaderSubText = styled.div`
    font-family: SFTextLight;
    text-align: left;
    font-size: 15px;
    color: rgb(174, 180, 192);
    width: 100%;
    line-height: 1.3;
    margin-top: 10px;
`

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
`

export const Bullet = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 2px;
`
// margin: 6% 0 5%;
export const PresaveMsgContainer = styled.div`
    width: 80%;
    padding: 25px 18px;
    border: 0.5px solid rgb(168, 201, 224);
    border-radius: 8px;
    background-color: rgb(247, 249, 252);
    margin: 4% 0 5%;
`

export const PresavedMsgHeader = styled.div`
    font-size: 18px;
    margin-bottom: 16px;
    font-family: SFTextMedium;
    color: rgb(72, 146, 195);
`

export const PresavedMsg = styled.div`
    font-size: 15px;
    font-family: "SF Pro Text";
    line-height: 1.3;
    margin: 0px;
    color: rgb(133, 142, 160);
`