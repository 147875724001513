import { DialogContent, DialogTitle, Dialog } from '@mui/material';
import { Buttons } from 'modules/Button';
import React from 'react'

const Add = ({ children, openDialog, setOpenDialog, title, ...rest }) => {
    return (
        <>
            <Dialog open={openDialog} {...rest}>
                <i className="icon-close icon" onClick={() => setOpenDialog(prev => !prev)}></i>
                {title && <h1 className='text-center text-xl'>{title}</h1>}
                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Add;