import React, { useEffect, useState } from "react"
import * as moment from "moment";
import { Button, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { DetailHeader, CustomTextarea, AddButtonContainer, AddButton, crisisLogTable, CustomErrorWrapper, CustomError, CustomTextareaContainer } from '../style'
import { crisisLogCols } from '../data'
import COLORS from 'utils/colors'
import { addTravelComment, resetTravelComment } from 'redux/actions/travel';
import { useDispatch, useSelector } from "react-redux";
import { requiredMessage } from "@jumbo/constant/ErrorMessages";
import ReactTable from "components/common/table/ReactTable";
import { getAlertTravelLogsList } from '../../../redux/actions/CrisisLogAlert';
import { AmountItemsContainer } from "../style";
import AmountItems from "../../AmountItems/AmountItems"

export default function CrisisLog({ travel_id, navigate }) {
    const dispatch = useDispatch();
    const [comment, setComment] = useState('')
    const [errors, setErrors] = useState({})
    const { logList } = useSelector(({ CRISISLOGALERT }) => CRISISLOGALERT);
    const { travelLogDetail, addTravelComment: addedComment } = useSelector(({ TRAVEL }) => TRAVEL)
    const [userLogDetail, setUserLogDetail] = useState(travelLogDetail.UserTravelDetails ? travelLogDetail.UserTravelDetails : {})
    const [activePage, setActivePage] = useState(1);
    const [activePerPage, setActivePerPage] = useState(10);

    // useEffect(() => {
    //     if (Object.keys(travelLogDetail).length > 0) {
    //         setUserLogDetail(travelLogDetail.UserTravelDetails ? travelLogDetail.UserTravelDetails : {})
    //     }
    // }, [travelLogDetail])

    // useEffect(() => {
    //     if (Object.keys(addedComment).length) {
    //         const tempLogDetail = { ...userLogDetail }
    //         if (Object.keys(tempLogDetail).length) {
    //             const tempList = [...tempLogDetail.travelLogs]
    //             tempList.unshift(addedComment)
    //             tempLogDetail.travelLogs = tempList
    //             setUserLogDetail(tempLogDetail)
    //             dispatch(resetTravelComment())
    //         }
    //     }
    // }, [addedComment])
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    const changePerPage = (activePerPage) => {
        setActivePage(1);
        setActivePerPage(activePerPage);
    }

    useEffect(() => {
        if (travel_id) {
            dispatch(getAlertTravelLogsList(travel_id, activePage, activePerPage, false));
        }
    }, [travel_id, activePage, activePerPage]);

    const onAddComment = () => {
        if (!comment) {
            setErrors({ ...errors, comment: requiredMessage })
        } else {
            dispatch(addTravelComment(travel_id, { comment }));
            setComment('')
        }
    }

    const onCommentChange = (e) => {
        setErrors({})
        setComment(e.target.value)
    }

    const renderError = (msg, style) => {
        return (
            <CustomErrorWrapper style={style}>
                <CustomError>{msg}</CustomError>
            </CustomErrorWrapper>
        )
    }

    const renderTime = (data) => {
        return (
            <div style={{
                color: 'rgb(92, 99, 115)',
                fontFamily: 'SFTextLight', fontSize: '15px'
            }}>
                <div>{moment(`${new Date(data.time)}`).format('DD-MM-YYYY')}</div>
                <div>{moment(`${new Date(data.time)}`).format('hh:mm')}</div>
            </div>
        )
    }

    const renderComment = (data) => {
        return (
            <div style={{ marginTop: '10px' }}>
                <p style={{ fontFamily: 'SFTextMedium', color: 'rgb(92, 99, 115)', fontSize: '15px' }}><b>{data.header}</b></p>
                <p style={{
                    color: 'rgb(92, 99, 115)',
                    fontFamily: 'SFTextLight'
                }}>{`${data.desc}`}</p>
            </div>
        )
    }

    const renderCheckIn = (data) => {
        return (
            <div>
                <p><b>Check in</b></p>
                <p><b>Type: </b>{data.checkinType || ''}</p>
                <p><b>Location: </b>{data.location ? Object.values(data.location).toString(', ') : ''}</p>
                <p><b>Comment: </b>{data.comment || ''}</p>
            </div>
        )
    }

    const renderTravelling = (data) => {
        return (
            <div>
                <p><b>Travel Reason:</b></p>
                <p>{data.reason || ''}</p>
                <p><b>Travel comment:</b></p>
                <p>{data.comment || ''}</p>
            </div>
        )
    }

    const renderDetail = (data) => {
        return renderComment(data)
    }

    const renderCell = (data) => {
        return crisisLogCols.map((col, index) => {
            return <TableCell key={`data-${col.id}${index}`} sx={{ width: col.width && col.width, padding: '0 16px !important' }}>
                {col.id === 'time' ? renderTime(data) : renderDetail(data)}
            </TableCell>
        })
    }

    const renderLogTable = () => {
        return (
            <div style={{ marginTop: '40px', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                <Table style={crisisLogTable}>
                    <TableHead>
                        <TableRow>
                            {crisisLogCols.map((col) => <TableCell key={col.id} style={{ color: COLORS.greylighttext }}>{col.label}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userLogDetail && userLogDetail.travelLogs && userLogDetail.travelLogs.map((data, idx) => {
                            return <TableRow key={`col-${idx}`}>{renderCell(data)}</TableRow>
                        })}
                    </TableBody>
                </Table>
                <Button
                    variant="contained"
                    style={{ ...AddButton, width: '20%', fontSize: '16px', marginTop: '60px' }}
                    onClick={() => navigate(`/app/CrisisLog/travel-detail/${travel_id}`)}
                >
                    Open in log</Button>
            </div>
        )
    }
    const columns = [
        {
            id: 'time',
            title: "Time",
            accessor: 'time',
            width: '20%',
            align: 'left',
            Component: ({ row }) => {
                return (
                    <div style={{
                        color: 'rgb(92, 99, 115)',
                        fontFamily: 'SFTextLight', fontSize: '15px'
                    }}>

                        <div >{row.time ? moment(`${new Date(row.time)}`).format('DD-MM-YYYY') : '-'}</div>
                        <div>{row.time ? moment(`${new Date(row.time)}`).format('hh:mm') : '-'}</div>
                    </div>
                )
            }
        },
        {
            id: 'msg',
            accessor: 'msg',
            title: 'Details ',
            align: 'left',
            Component: ({ row }) => {
                return (
                    <div className='logDetails' dangerouslySetInnerHTML={{ __html: (row?.msg || '') }}></div>
                )
            }
        },

    ];
    return (
        <div style={{ marginTop: '35px', width: '78%' }}>
            <DetailHeader>Crisis log, Ongoing event</DetailHeader>
            <div style={{ marginTop: '20px' }}>
                <CustomTextareaContainer style={{ border: errors.comment ? `1px solid ${COLORS.redtext}` : 'unset' }}>
                    <CustomTextarea
                        multiline
                        variant={"standard"}
                        minRows={4}
                        placeholder="Add log entry (time will be registered automatically)..."
                        style={{ width: '100%' }}
                        value={comment}
                        onChange={onCommentChange}
                        error={errors.comment}
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                </CustomTextareaContainer>
                {errors.comment && renderError(errors.comment, { width: '98%' })}
                <AddButtonContainer>
                    <Button
                        variant="contained"
                        style={AddButton}
                        onClick={onAddComment}
                    >
                        Add</Button>
                </AddButtonContainer>
            </div>
            <div style={{ marginTop: '40px', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>

                <AmountItemsContainer
                    style={{ width: "100%" }}
                >
                    <AmountItems
                        changePerPage={changePerPage}
                        activePerPage={activePerPage}
                    />
                </AmountItemsContainer>
                <ReactTable
                    COLUMNS={columns}
                    DATA={logList?.logs || []}
                    hiddenColumns={[]}
                    fetchData={handlePageChange}
                    pageIndex={activePage}
                    totalCount={logList?.total_no_of_logs_overall}
                    pageSize={activePerPage}
                    type={"checklist"}
                />
                <Button
                    variant="contained"
                    style={{ ...AddButton, width: '20%', fontSize: '16px', marginTop: '60px' }}
                    onClick={() => navigate(`/app/CrisisLog/travel-detail/${travel_id}`)}
                >
                    Open in log</Button>
            </div>
            {/* {renderLogTable()} */}
        </div>
    )
}