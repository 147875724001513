import * as React from "react";
import Slider, { SliderProps } from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
// import { alpha, styled } from "@mui/material/styles";
import NotificationsNone from "@mui/icons-material/NotificationsNone";
import { blue } from "@mui/material/colors";
import { textAlign } from "@mui/system";
// import styled from "styled-components";


// export const TextFields = styled(TextField)(({ theme }) => ({
//     "& .MuiOutlinedInput-root": {
//         "& fieldset": {
//             borderColor: "rgba(0, 0, 0, 0.23)" // default
//         },
//         "&.Mui-focused fieldset": {
//             border: "2px solid red" // customized
//         }
//     },

//   background: "none",
//   // borderBottom: "1px solid rgba(174, 180, 192, 0.24)",
//   display: "flex",
//   width:'100%',

//   font: "inherit",
// //   color: "rgb(144, 153, 170)",
//   textTransform: "capitalize",
//   fontSize: '15px',
//     fontWeight:300,
//     marginTop: '0',
//     '& .MuiFormLabel-root': {
//       color: 'rgb(144, 153, 170)',

//       fontWeight: 300,
//       letterSpacing: '0.01071em',

//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//       color: "rgb(144, 153, 170)",
//     },


//     "& .MuiFormHelperText-root": {
//       color: 'red'

//     },
//     "& .MuiInputBase-input":{
//       borderBottom: "1px solid rgba(174, 180, 192, 0.24)", 
//     },
//     '& .MuiFilledInput-underline':{
//         color:'green'
//     },



// }));

// export const TextFields = styled(TextField)(({ theme }) => ({
//     background: "none",
//     display: "flex",
//     width: '100%',
//     font: "inherit",
//     textTransform: "capitalize",
//     fontSize: '15px',
//     fontWeight: 300,
//     marginTop: '0',
//     // "& .MuiOutlinedInput-root": {
//     //     "& fieldset": {
//     //         borderColor: "rgba(0, 0, 0, 0.23)" // default
//     //     },
//     //     "&.Mui-focused fieldset": {
//     //         border: "2px solid red" // customized
//     //     }
//     // },
//     '& .MuiFormLabel-root': {
//         color: 'rgb(144, 153, 170)',

//         fontWeight: 300,
//         letterSpacing: '0.01071em',

//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//         color: "rgb(144, 153, 170)",
//     },
//     "& .MuiFormHelperText-root": {
//         color: 'red'
//     },
//     "& .MuiInputBase-input": {
//         // borderBottom: "1px solid rgba(174, 180, 192, 0.24)",
//         borderBottom: "1px solid red",
//     },
//     // '& .MuiFilledInput-underline': {
//     //     color: 'green'
//     // },
//     // '& .MuiInput-underline:after': {
//     //     borderBottomColor: 'rgb(144, 153, 170)',
//     // },
// }));

export const TextFields = styled((props) => (
  // <div style={{ padding: '5px 0 !important',  width: '80%' ,position:'relative'}}>
  <TextField variant={"standard"} InputProps={{ disableUnderline: true }} style={{ width: '100%', marginTop: '0', }} {...props} />
  // </div>
))

  ({

    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",

    },

    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgb(144, 153, 170)',
    },

    "& .MuiInputBase-input": {
      borderBottom: (props) => props.helperProps || props.errorProps ? "1px solid rgb(225, 108, 108) !important" :
        "1px solid rgba(174, 180, 192, 0.24)",
      // borderBottom: "1px solid red",
      fontFamily: 'SFTextLight !important',
      fontSize: '15px',

      fontWeight: 300,
      //   color:textProps ?"blue":"black",
      color: (props) => props.textProps ? 'rgb(84,84,84) !important' : 'rgb(0,0,0) !important'
      //   width: ${(props) => props.width}; 
    },
    // borderBottom: '1px solid rgb(229, 230, 233)',
    '& .MuiFormLabel-root': {
      color: 'rgb(144, 153, 170)',
      fontWeight: 300,
      // letterSpacing: '0.01071em',
      letterSpacing: '0',
      letterSpacing: '0',
      // letterSpacing: '0.01071em',
      fontSize: '16px',
      // fontSize: '16px' ,
      marginBottom: '0px',
      fontFamily: 'SFTextLight',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'rgb(144, 153, 170)',
      fontWeight: 300,
      // fontSize:'15px',
      fontSize: '16px',
      fontFamily: 'SFTextLight !important',
      // letterSpacing: '0.01071em',
      letterSpacing: '0',
      letterSpacing: '0',
      // letterSpacing: '0.01071em',
    },
    "& .MuiFormHelperText-root": {
      color: 'rgb(225, 108, 108)',
      // position: 'absolute',
      bottom: '-22px',
      fontFamily: 'SF Pro Text',
      fontSize: '14px',
      textAlign: 'right',
      width: '100%',
      //borderBottom:"1px solid rgb(225, 108, 108) !important"
    },
    // '.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root': {
    //   transform: 'translate(0, -1.5px) scale(0.83)',
    // }/

  })
