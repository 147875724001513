import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUserProfile, logout } from './redux/actions/Settings'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from 'modules/Loader';
import ContentLoader from './@jumbo/Common/ContentLoader';
import { io } from 'socket.io-client'
import { GET_ALERT_LIST_UPDATE, GET_IN_HOUSE_LIST_UPDATE, GET_IN_HOUSE_LIST_RESOLVE, GET_ALERT_LIST_RESOLVE, END_TRAVEL_SOCKET, USER_PROFILE, FETCH_SUCCESS } from '../src/@jumbo/constant/ActionTypes'
import { GET_ALERTLIST_SOCKET, GET_INHOUSELIST_SOCKET, GET_TRAVEL_LIST_SOCKET, GET_USER_DETAILS_UPDATE } from './@jumbo/constant/ActionTypes'
import AppRoutes from "routes";
import { refresh_token, removeUsefulLinkforTravel } from "@jumbo/constant/ApiConstant";
import { ALERT_STATUS, ALERT_TYPE } from "utils/alertHelper";
import { getAlertList } from "redux/actions/alert";
import { getTravelList } from "redux/actions/travel";
import { TRAVEL_STATUS } from "modules/Travel/data";
import axios from 'helper/customAxios';
import { onLogout, refreshToken } from "redux/actions/Auth";
import { fetchError } from "redux/actions";
import { setSecSubdivisionId } from "redux/actions/SubDivision"

export default function App() {
    const { currentuser: currentUser } = useSelector(({ USERPROFILE }) => USERPROFILE);
    const { secSubdivisionUser } = useSelector(({ SUBDIVISION }) => SUBDIVISION);
    // const { previousFunction } = useSelector(({ PREVIOUSFUNCTION }) => PREVIOUSFUNCTION)
    const { loading } = useSelector(({ common }) => common);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const Profile = JSON.parse(localStorage.getItem("userToken"));
    const location = useLocation();
    const [socket, setSocket] = useState(null);
    const [sessionExpire, setSessionExpire] = useState(false)
    const [retryRequest, setRetryRequest] = useState(null);
    const [refreshingToken, setRefreshingToken] = useState(false);

    // useEffect(() => {
    //     if (localStorage.getItem('profile')) {
    //         const token = JSON.parse(localStorage.getItem("userToken"))
    //         const refreshTokenResponse = axios.post(refresh_token, { token: token });

    //         // if (refreshTokenResponse.status === 200) {
    //             console.log("refreshTokenResponse", refreshTokenResponse);
    //             localStorage.setItem('userToken', JSON.stringify(refreshTokenResponse?.data?.token))
    //             // const originalRequest = error.config;
    //             // originalRequest.headers['Authorization'] = 'Bearer ' + refreshTokenResponse?.data?.token;
    //             // return axios(originalRequest);
    //         // }
    //     }

    // }, [localStorage.getItem('profile')])
    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    }

    // console.log("previousFunction789", previousFunction);

    // useEffect(() => {
    //     if (sessionExpire) {
    //         // dispatch(refreshToken())

    //         // dispatch({ type: FETCH_SUCCESS, loading: false });
    //         // dispatch(fetchError(error.response.data.message));
    //         // dispatch(refreshToken());
    //     }
    // }, [sessionExpire])


    // useEffect(() => {

    //     // let timeDifference = 0;

    //     if (localStorage.getItem('profile')) {

    //         const profile = JSON.parse(localStorage.getItem('profile'));
    //         const currentTime = Math.ceil(new Date().getTime());
    //         const expiryDate = profile?.token_expiry_date - 5;
    //         const timeDifference = Math.max(0, expiryDate - currentTime);

    //         setInterval(() => {
    //             console.log("expire");
    //             dispatch(refreshToken())
    //         }, timeDifference)
    //     }

    // }, [localStorage.getItem('profile')])



    // useEffect(() => {
    //     if (localStorage.getItem('userToken')) {
    //         dispatch({ type: "SET_PREVIOUSAPI", payload: JSON.parse(localStorage.getItem('userToken')) })
    //         dispatch(refreshToken())
    //     }

    // }, [])

    useEffect(() => {
        if (Profile) {
            setSocket(io(process.env.REACT_APP_BASE_URL_SOCKET, { query: { userId: currentUser?.detail?._id } },));
        }
    }, [currentUser?.detail?._id]);

    useEffect(() => {
        if (!currentUser?.detail?._id && socket) {
            socket.disconnect();
            return () => {
                socket.disconnect();
            }
        }
    }, [currentUser?.detail?._id])

    useEffect(() => {
        let secSubdivId = localStorage.getItem("sec_subdiv_id");
        if (secSubdivId) {
            dispatch(setSecSubdivisionId(secSubdivId));
        }
    }, []);

    useEffect(() => {
        if (socket && !socket?.connected) {
            socket.on('connect', () => {
                console.log('socket connected')
            });

            socket.on('new-alert', data => {
                console.log(data, 'alert_test new alert');
                if ([1, 2].includes(data.socketAlertData.alert_type)) {
                    dispatch({ type: GET_ALERT_LIST_UPDATE, payload: { raisedAlertCount: data.raisedAlertCount, inProgressAlertCount: data?.inProgressAlertCount, socketAlertData: data.socketAlertData } });
                    dispatch({ type: GET_ALERTLIST_SOCKET, payload: { crisisAlert: data.crisisLog, alertcount: data.total_alerts } });
                }
                else if (data.socketAlertData.alert_type === 3) {
                    dispatch({ type: GET_IN_HOUSE_LIST_UPDATE, payload: { raisedAlertCount: data.raisedAlertCount, socketAlertData: data.socketAlertData } });
                    dispatch({ type: GET_INHOUSELIST_SOCKET, payload: { crisisInHouse: data.crisisLog, inhousecount: data.total_inhouse } });
                }
            });

            socket.on('resolve-alert', (resolve) => {
                console.log(resolve, 'alert_test resolve');
                if ([1, 2].includes(resolve.updatedData.alert_type)) {
                    // if (resolve.updatedData.alert_type == 1) {
                    dispatch({ type: GET_ALERT_LIST_RESOLVE, payload: { raisedAlertCount: resolve.raisedAlertCount, inProgressAlertCount: resolve?.inProgressAlertCount, socketAlertData: resolve.updatedData._id } });
                }
                else if (resolve.updatedData.alert_type === 3) {
                    dispatch({ type: GET_IN_HOUSE_LIST_RESOLVE, payload: { raisedAlertCount: resolve.raisedAlertCount, socketAlertData: resolve.updatedData._id } });
                }
            });

            socket.on('permissionUpdate', permission => {
                dispatch({ type: GET_USER_DETAILS_UPDATE, payload: { permission } });
            });

            socket.on('start-travel', (crisistravel) => {
                dispatch({
                    type: GET_TRAVEL_LIST_SOCKET, payload: {
                        crisisTravel: crisistravel.crisisTravelData,
                        totalTravelCount: crisistravel.totalTravelCount,
                        raisedTravelCount: crisistravel.raisedTravelCount
                    }
                });
            });

            socket.on('end-travel', (crisistravel) => {
                console.log('end-travel', crisistravel);
                dispatch({
                    type: END_TRAVEL_SOCKET, payload: {
                        crisisTravel: crisistravel,
                    }
                });
            });
        }
    }, [socket]);
    const redirectPath = () => {
        navigate('/login')
    }
    useEffect(() => {
        const Token = localStorage.getItem('userToken');
        if (Profile && Token) {
            dispatch(getUserProfile(redirectPath))
        }
    }, [currentUser?.detail?._id]);

    //called alert AND Inhouse API initially
    useEffect(() => {
        const Token = localStorage.getItem('userToken');
        if (Token && Profile) {
            if (location?.pathname?.toLowerCase() != '/app/alert' && currentUser?.detail?.has_access_to?.alert_travel) {
                dispatch(getAlertList({
                    status: ALERT_STATUS.RAISED,
                    alert_type: `${ALERT_TYPE.TRIGGERED_ALERT},${ALERT_TYPE.DELAYED_ALERT}`,
                    page: 1,
                    limit: 1
                }));
            }
            if (location?.pathname?.toLowerCase() != '/app/Travel' && currentUser?.detail?.has_access_to?.alert_travel) {
                if (currentUser?.detail?.user_type === 2) {
                    if (secSubdivisionUser) {
                        dispatch(getTravelList({ page: 1, limit: 10, search: '', status: TRAVEL_STATUS.IN_PROGRESS, subdivisions: secSubdivisionUser }));
                    }
                }
                else {
                    dispatch(getTravelList({ page: 1, limit: 10, search: '', status: TRAVEL_STATUS.IN_PROGRESS }));
                }
            }
            if (location?.pathname?.toLowerCase() != 'app/Inhouse' && currentUser?.detail?.has_access_to?.in_house) {
                if (currentUser?.detail?.user_type === 2) {
                    if (secSubdivisionUser) {
                        dispatch(getAlertList({ status: ALERT_STATUS.RAISED, alert_type: `${ALERT_TYPE.TRIGGERED_ALERT}, ${ALERT_TYPE.DELAYED_ALERT}`, page: 1, limit: 1, subdivisions: secSubdivisionUser }))
                        dispatch(getAlertList({ status: ALERT_STATUS.RAISED, alert_type: ALERT_TYPE.IN_HOUSE_ALERT, page: 1, limit: 1, subdivisions: secSubdivisionUser }))
                    }
                }
                else {
                    dispatch(getAlertList({ status: ALERT_STATUS.RAISED, alert_type: ALERT_TYPE.IN_HOUSE_ALERT, page: 1, limit: 1 }))
                }
                // dispatch(getAlertList({ status: ALERT_STATUS.RAISED, alert_type: ALERT_TYPE.IN_HOUSE_ALERT, page: 1, limit: 1 }))
            }
        }
    }, [JSON.stringify(currentUser)]);

    //axios interceptors

    const logoutHandler = useCallback(() => {
        dispatch(logout());
        return navigate("/login");
    }, [dispatch, navigate]);

    // useEffect(() => {
    //     if (sessionExpire && refreshingToken) {
    //         // Set a retryRequest to prevent multiple refresh attempts
    //         setRefreshingToken(true);
    //         const refreshTokenPromise = dispatch(refreshToken());
    //         // setRetryRequest(true);
    //         // dispatch(refreshToken())
    //         refreshTokenPromise
    //             .then((response) => {
    //                 if (response?.payload?.status === 200) {
    //                     // Token refreshed successfully, reset the retryRequest
    //                     setRefreshingToken(false);
    //                 } else {
    //                     // Token refresh failed, handle as needed
    //                     logoutHandler();
    //                 }
    //             })
    //             .catch((refreshError) => {
    //                 console.error('Token refresh error:', refreshError);
    //                 logoutHandler();
    //             });
    //     }
    // }, [sessionExpire, refreshingToken, dispatch]);

    // useEffect(() => {
    //     // Your existing axios interceptors...
    //     let value;
    //     axios.interceptors.response.use(function (response) {
    //         return response;
    //     }, async function (error) {
    //         const token = localStorage.getItem('userToken');
    //         if (error?.response?.status === 401 && token) {
    //             setTimeout(() => {
    //                 dispatch(fetchError(error?.response?.data?.message));
    //             }, 2000);
    //             logoutHandler();
    //         } else if (error.response && error.response.status === 444) {
    //             // Set the retryRequest to the original request config
    //             setRetryRequest(value);

    //             // Attempt to refresh the token
    //             try {
    //                 const response = await dispatch(refreshToken());
    //                 if (response?.payload?.status === 200) {
    //                     // Token refreshed successfully, retry the original request
    //                     await makeAPICall(retryRequest);
    //                 } else {
    //                     // Token refresh failed, handle as needed
    //                     logoutHandler();
    //                 }
    //             } catch (refreshError) {
    //                 console.error('Token refresh error:', refreshError);
    //                 logoutHandler();
    //             }
    //         } else if (error?.response?.status === 403) {
    //             navigate('/app/Unauthorized');
    //         }
    //         return Promise.reject(error);
    //     });
    // }, [dispatch, navigate]);

    // let value;
    // let savedPayload = null;
    // let savedEndpoint = null;
    // let savedParams = null;
    // let savedMethod = null;
    // let call = 0;
    try {

        axios.interceptors.request.use(function (config) {
            const token = JSON.parse(localStorage.getItem("userToken"));
            console.log("refresh123 token", config?.url, token, config);
            config.headers = { 'Authorization': 'Bearer ' + token };
            return config;
        });

        axios.interceptors.response.use(function (response) {
            return response;
        }, async function (error) {
            console.log("error234", error?.config?.headers?.Authorization);
            const originalRequest = error.config;
            const token = "Bearer " + JSON.parse(localStorage.getItem("userToken"))
            if (error?.response?.status === 401 && error?.config?.headers?.Authorization == token) {
                setTimeout(() => {
                    dispatch(fetchError(error?.response?.data?.message));
                }, 2000);
                logoutHandler();
            }
            else if (error.response && error.response.status == 444) {
                // dispatch({ type: FETCH_SUCCESS, loading: true });
                // const token = JSON.parse(localStorage.getItem("userToken"))
                // const refreshTokenResponse = await axios.post(refresh_token, { token: token }).then((res) => {
                //     return res;
                // })
                // console.log("refreshTokenResponse", refreshTokenResponse);
                // dispatch({ type: FETCH_SUCCESS, loading: false });
                // if (refreshTokenResponse.status === 200) {
                //     localStorage.setItem('userToken', JSON.stringify(refreshTokenResponse?.data?.data?.token))
                //     const originalRequest = error.config;
                //     originalRequest.headers['Authorization'] = 'Bearer ' + refreshTokenResponse?.data?.data?.token;
                //     return axios(originalRequest);
                // }
            }
            else if (error?.response?.status === 403) {
                navigate('/app/Unauthorized');
            }
            return Promise.reject(error);
        });
    }
    catch (e) {
        console.log(e);
    }

    // async function makeAPICall(config) {
    //     try {
    //         const response = await axios(config);
    //         // Handle the response from the API call here
    //         console.log('API Response:', response.data);
    //     } catch (error) {
    //         // Handle any errors that occur during the API call
    //         console.error('API Error:', error);
    //     }
    // }

    // async function makeAPICall() {

    //     if (savedPayload !== null && savedEndpoint !== null) {
    //         await axios({
    //             method: savedMethod,
    //             url: savedEndpoint,
    //             params: savedParams,
    //             data: savedPayload,
    //         })
    //             .then((response) => {
    //                 // Handle the response from the API call here
    //                 console.log('API Response:', response.data);
    //             })
    //             .catch((error) => {
    //                 // Handle any errors that occur during the API call
    //                 console.error('API Error:', error);
    //             });
    //     } else {
    //         console.error('Request details are not available.');
    //     }
    // }

    return (
        <>
            {(loading && loading.length > 0) && <Loader />}
            <ContentLoader />
            <AppRoutes />
        </>
    );
}
