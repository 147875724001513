import { Buttons } from 'modules/Button';
import { TextFields } from 'modules/Textfeild';
import React from 'react'
import Modal from "components/common/modal";
import { TextAreas } from 'modules/TextAreas';
import ConfirmDltDialog from 'modules/ConfirmDltDialog';
import { useDispatch } from 'react-redux';
import { deleteCheckList } from 'redux/actions/checklist';
import { useParams } from 'react-router';

const Delete = ({ openDeleteDialog, setOpenDeleteDialog, actionId, type, subdivisionId }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const handleConfirmDelete = () => {
        dispatch(deleteCheckList(actionId, id, { type }, subdivisionId));
        setOpenDeleteDialog(false);
    }
    return (
        <ConfirmDltDialog
            open={openDeleteDialog}
            title='Confirm Delete'
            content={`Are you sure you want to delete this Checklist?`}
            btnLabels={{
                no: "No",
                Yes: "Yes",
            }}
            onClose={() => setOpenDeleteDialog(prev => !prev)}
            onConfirm={handleConfirmDelete}
        />
    )
}

export default Delete;