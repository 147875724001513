import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import GooglMap from "components/GooglMap";
import { MapDialogStyle } from "./style";

function MapDialogBox({
    open,
    onClose,
    markerList
}) {

    const inHouseMapbox = useMemo(() => {
        if (markerList) {
            console.log(markerList, 'pk');
            return (
                <GooglMap markerList={markerList} className={`map`} height={'410px'} infoBox={"none"} />
                // <GooglMap markerList={[{ lat_long: [latlng.lat, latlng.lng] }]} onPlaceChange={onPlaceChanged} addTravel={true} />
            )
        }
    }, [markerList])

    return (
        <Dialog open={open} onClose={onClose} sx={MapDialogStyle}>
            <i className="icon-close icon" onClick={onClose}></i>
            <DialogTitle>Location</DialogTitle>
            <DialogContent>{inHouseMapbox}
                {/* <GooglMap markerList={markerList} className={`map`} height={'410px'} infoBox={"none"} /> */}
            </DialogContent>
        </Dialog>
    );
}
export default MapDialogBox;
