import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_DATA_SUCCESS, FETCH_START_SIDEBAR } from '../../@jumbo/constant/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  error: '',
  message: '',
  // loading: false,
  loading: [],
  open: false,
  sideloadig: false
  // Transition: Fade,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      // return { ...state, error: '', loading: true ,sideloadig:true};
      return { ...state, error: '', message: '', loading: [...state?.loading, true] };
    }
    case FETCH_START_SIDEBAR: {
      return { ...state, sideloadig: true };
    }
    case FETCH_SUCCESS: {
      let loadingStack = [...state.loading];
      loadingStack.pop();
      return { ...state, error: '', loading: (loadingStack || []), message: action.payload, sideloadig: false };
    }
    case FETCH_ERROR: {
      let loadingStack = [...state.loading];
      loadingStack.pop();
      return { ...state, open: false, message: '', loading: (loadingStack || []), error: action.payload };
    }
    case FETCH_DATA_SUCCESS: {
      let loadingStack = [...state.loading];
      loadingStack.pop();
      return { ...state, loading: (loadingStack || []) };
    }
    default:
      return state;
  }
};
